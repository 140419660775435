import { LicenseTypes } from '../../auth/license-types.enum';
import { CameraOrientation } from '../camera/camera-orientation.enum';
import { v4 as uuidv4 } from 'uuid';

/**
 * The model for users (licenses)
 */
export class User {
    _id: string; // user id
    type: string; // item type
    userName: string; // username
    firstName: string; // user forename
    lastName: string; // user last name
    password: string; // user password
    email: string; // user email
    phone: string; // user phone number
    licenseType: string; // license type
    expires: string; // license expire date
    dokupitSupportAccount: boolean; // true if this is an account from a dokupit team member which is used for support (has special rights)
    dokupitSupportSpoofUsername: string; // if set we are spoofing this username (used for syncing another user's items)
    mfaEnabled: boolean; // true if MFA is enabled
    userSettings: UserSettings;
    createdAt: number; // creation timestamp
    updatedAt: number; // last update timestamp
    pk: string; // primary key in dynamoDb
    sk?: string; // sort key in dynamoDb

    constructor(data?: any) {
        this._id = uuidv4();
        this.type = 'user';
        this.userName = '';
        this.firstName = '';
        this.lastName = '';
        this.password = null;
        this.email = '';
        this.phone = '';
        this.licenseType = null;
        this.expires = '';
        this.dokupitSupportAccount = false;
        this.dokupitSupportSpoofUsername = null;
        this.mfaEnabled = false;
        this.userSettings = new UserSettings();
        this.createdAt = Date.now();
        this.updatedAt = Date.now();

        // default pk = _id
        this.pk = this._id; // default pk = _id

        if (data != null) {
            if (data._id != null) {
                this._id = data._id;
            }
            if (data.id != null) {
                this._id = data.id; // fallback
            }
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.userName != null) {
                this.userName = data.userName;
            }
            if (data.firstName != null) {
                this.firstName = data.firstName;
            }
            if (data.lastName != null) {
                this.lastName = data.lastName;
            }
            if (data.password != null) {
                this.password = data.password;
            }
            if (data.email != null) {
                this.email = data.email;
            }
            if (data.phone != null) {
                this.phone = data.phone;
            }
            if (data.licenseType != null) {
                this.licenseType = data.licenseType;
            }
            if (data.expires != null) {
                this.expires = data.expires;
            }
            if (data.dokupitSupportAccount != null) {
                this.dokupitSupportAccount = data.dokupitSupportAccount;
            }
            if (data.dokupitSupportSpoofUsername != null) {
                this.dokupitSupportSpoofUsername = data.dokupitSupportSpoofUsername;
            }
            if (data.mfaEnabled != null) {
                this.mfaEnabled = data.mfaEnabled;
            }
            if (data.createdAt != null) {
                this.createdAt = data.createdAt;
            }
            if (data.updatedAt != null) {
                this.updatedAt = data.updatedAt;
            }
            if (data.pk != null) {
                this.pk = data.pk;
            }
            if (data.sk != null) {
                this.sk = data.sk;
            }

            // no matter if we have something stored or not we update the settings data based on licenseType
            this.userSettings = new UserSettings(data.userSettings, this.licenseType, this.dokupitSupportAccount);
        }
    }
}

export class UserSettings {
    savePhotosInMediaLibrary: boolean;
    enableNewCamera: boolean;
    useSqliteStorage: boolean;
    preferLimitedSync: boolean;

    constructor(data?: any, licenseType?: string, dokuPitSupportAccount = false) {
        this.savePhotosInMediaLibrary = true;
        this.useSqliteStorage = false;
        this.enableNewCamera = true;

        switch (licenseType) {
            case LicenseTypes.VIEWER:
                this.preferLimitedSync = true;
                break;
            case LicenseTypes.ADMIN:
            case LicenseTypes.MOBILE:
            default:
                this.preferLimitedSync = false;
                break;
        }

        if (dokuPitSupportAccount === true) {
            this.preferLimitedSync = true; // default to true for support accounts
        }

        if (data != null) {
            if (data.savePhotosInMediaLibrary != null) {
                this.savePhotosInMediaLibrary = !!data.savePhotosInMediaLibrary;
            }

            if (data.enableNewCamera != null) {
                this.enableNewCamera = !!data.enableNewCamera;
            }

            if (data.useSqliteStorage != null) {
                this.useSqliteStorage = !!data.useSqliteStorage;
            }

            if (data.preferLimitedSync != null) {
                this.preferLimitedSync = !!data.preferLimitedSync;
            }
        }
    }
}
