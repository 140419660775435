import { Platform } from '@ionic/angular';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
    booleanAttribute,
} from '@angular/core';
import { DialogService } from './dialog.service';

import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, OnDestroy {
    // hide or show header based on having content projected into the header slot
    @ViewChild('headerToolbar', { static: false, read: ElementRef }) set headerToolbar(val: ElementRef) {
        // setting only when possible to check and changing the boolean outside angular loop as
        // DOM changes via content projection retriggers unwanted updates when destroying other dialogs for example
        if (val != undefined) {
            setTimeout((_) => {
                this.showHeader =
                    val?.nativeElement?.childElementCount > 1 || (this.hideCloseBtn && val?.nativeElement?.childElementCount > 0);
            }, 0);
        }
    }
    @Input() dialogId: string;
    @Input({ transform: booleanAttribute }) small = false; // small dialog width
    @Input({ transform: booleanAttribute }) dismissable = true; // if dialog can be closed by clicking backdrop
    @Input({ transform: booleanAttribute }) noPadding = false;
    @Input({ transform: booleanAttribute }) noSeparator = false;
    @Input({ transform: booleanAttribute }) hideCloseBtn = false; // if close button is visible
    @Input({ transform: booleanAttribute }) fullWidth = false; // if width is always 100vw
    @Input({ transform: booleanAttribute }) fullscreenToggleable = false; // if fullscreen can be toggled by user

    @Output() openStateChanged = new EventEmitter();

    isVisible = false;
    showHeader = false;

    @Input({ transform: booleanAttribute }) set fullscreen(val: boolean) {
        // "fix" ui inconsistence
        this._fullscreen = val;
        this.updateStatusBarStyle();
    }
    get fullscreen() {
        return this._fullscreen;
    }

    private _fullscreen = false;
    private element: any;
    private originStyle: Style;

    constructor(
        private el: ElementRef,
        private dialogSrv: DialogService,
        private plt: Platform
    ) {
        this.element = el.nativeElement;

        // Get current style of status bar for correctly swapping it later on if needed
        StatusBar.getInfo()
            .then((statusInfo) => {
                this.originStyle = statusInfo.style;
            })
            .catch((styleGetErr) => {
                if (styleGetErr?.message != '"StatusBar" plugin is not implemented on web') {
                    console.warn('Could not get style of statusbar', styleGetErr);
                }
            });
    }

    ngOnInit(): void {
        if (!this.dialogId) {
            console.error('dialog must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.getElementsByTagName('ion-app')[0].appendChild(this.element);

        // close dialog on background click
        this.element.addEventListener('mousedown', (e: any) => {
            if (this.dismissable && e.target.classList.contains('dp-dialog-open')) {
                this.close();
            }
        });

        // add self (this dialog instance) to the dialog service so it's accessible from controllers
        this.dialogSrv.register(this);
    }

    ngOnDestroy(): void {
        this.dialogSrv.unregister(this);
        this.element.remove();
    }

    open() {
        this.isVisible = true;
        this.element.classList.add('dp-dialog-open');
        this.updateStatusBarStyle();
        this.openStateChanged.emit(this.isVisible);
    }

    close() {
        this.element.classList.remove('dp-dialog-open');
        this.isVisible = false;

        this.updateStatusBarStyle();
        this.openStateChanged.emit(this.isVisible);
    }

    toggleFullscreen() {
        this.fullscreen = !this.fullscreen; // negate
    }

    private updateStatusBarStyle() {
        if (this.isVisible && this.plt.is('ios') && this.fullscreen) {
            StatusBar.setStyle({
                style: Style.Light,
            });
        } else if (this.originStyle) {
            StatusBar.setStyle({
                style: this.originStyle,
            });
        }
    }
}
