import { LoggerService } from "./../logger/logger.service";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class DialogService {
	private _minZIndex = 40000; // Ionic shows modals around 20000
	private dialogs: any[] = [];

	constructor(private logger: LoggerService) {}

	/**
	 * Registers given dialog, so that it can be opened and closed lateron. Automatically called from the dialog component when initialized
	 * @param dialog The model to register
	 */
	register(dialog: any) {
		let currentHighestZIndex = 0;
		if (dialog.el.nativeElement) {
			for (let d of this.dialogs) {
				if (d.el.nativeElement && parseInt(d.el.nativeElement.style.zIndex) > currentHighestZIndex) {
					currentHighestZIndex = parseInt(d.el.nativeElement.style.zIndex);
				}
			}

			// Set for upcoming dialog
			if (currentHighestZIndex === 0) {
				currentHighestZIndex = this._minZIndex;
			} else {
				currentHighestZIndex = currentHighestZIndex + 1;
			}

			dialog.el.nativeElement.style.zIndex = currentHighestZIndex;
		} else {
			this.logger.warn(
				"[DIALOG SRV] Can't find zIndex due to missing native Element. Dialog may not be visible!",
				dialog
			);
		}
		this.dialogs.push(dialog);
	}

	/**
	 * Unregisters given dialog, so that it is destroyed. Automatically called from the dialog component when destroyed
	 * @param dialog The modal to register
	 */
	unregister(dialog: any) {
		this.dialogs = this.dialogs.filter((x) => x !== dialog);
	}

	/**
	 * Opens dialog with given id
	 * @param dialogId The id of the dialog to open
	 */
	open(dialogId: string) {
		let foundDialogs = this.dialogs.filter((x) => x.dialogId === dialogId);
		const dialog = foundDialogs[foundDialogs.length - 1];
		dialog.open();
	}

	/**
	 * Closes dialog with given id
	 * @param dialogId The id of the dialog to open
	 */
	close(dialogId: string) {
		let foundDialogs = this.dialogs.filter((x) => x.dialogId === dialogId);
		const dialog = foundDialogs[foundDialogs.length - 1];
		dialog.close();
	}
}
