<ion-content id="camera-preview-container">
    <ng-container *ngIf="showEditor">
        <dp-image-editor [src]="editorSrc" (cancelled)="handleEditorCancel()" (process)="handleEditorProcess($event)"></dp-image-editor>
    </ng-container>
    <ion-grid slot="fixed" class="dp-g-fab bottom stretch ion-justify-content-center" [ngClass]="extraClass" *ngIf="!showEditor">
        <ion-row>
            <ion-col>
                <ion-button class="dp-g-fab-btn hidden" shape="round" size="small" color="light">
                    <fa-icon [icon]="['far', 'images']" slot="icon-only"></fa-icon>
                </ion-button>
                <ion-button class="dp-g-fab-btn" shape="round" size="small" color="light" (click)="cancelCamera()">
                    <fa-icon [icon]="['fal', 'times']" slot="icon-only"></fa-icon>
                </ion-button>
                <ion-button
                    [disabled]="processing"
                    class="dp-g-fab-btn space-around"
                    shape="round"
                    color="success"
                    size="xlarge"
                    (click)="takePicture()"
                >
                    <fa-icon [icon]="['fal', 'camera']" slot="icon-only" *ngIf="!processing"></fa-icon>
                    <ion-spinner class="ion-no-padding" slot="icon-only" *ngIf="processing"></ion-spinner>
                </ion-button>
                <ion-button class="dp-g-fab-btn" shape="round" size="small" (click)="toggleTorch()" [ngSwitch]="flashState">
                    <fa-icon *ngSwitchCase="'auto'" [icon]="['fal', 'bolt-auto']" slot="icon-only"></fa-icon>
                    <fa-icon *ngSwitchCase="'on'" [icon]="['fal', 'bolt']" slot="icon-only"></fa-icon>
                    <fa-icon *ngSwitchDefault [icon]="['fal', 'bolt-slash']" slot="icon-only"></fa-icon>
                </ion-button>
                <ion-button class="dp-g-fab-btn" shape="round" size="small" (click)="toggleImageEditor()" [ngSwitch]="useEditor">
                    <fa-icon *ngSwitchCase="true" [icon]="['far', 'wand-magic-sparkles']" slot="icon-only"></fa-icon>
                    <fa-layers *ngSwitchDefault slot="icon-only">
                        <fa-icon [icon]="['fal', 'wand-magic']" transform="left-2"></fa-icon>
                        <fa-icon [icon]="['fal', 'slash']" transform="left-2 top-1"></fa-icon>
                    </fa-layers>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
