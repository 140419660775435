import { UtilsService } from "./../utils/utils.service";
import { ApplicationRef, Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import tippy, { roundArrow } from "tippy.js";
@Directive({
	selector: "[dpTooltip]",
})
export class TooltipDirective implements OnInit {
	@Input() tooltipContent: string | TemplateRef<any>;
	@Input() tooltipTrigger: "click" | "mouseenter focus" | "click mouseenter focus" | "manual" = "mouseenter focus";
	@Input() tooltipTheme: "light" | "dark" = "dark";
	@Input() tooltipPosition: "top" | "left" | "right" | "bottom" = "top";

	constructor(private el: ElementRef, private appRef: ApplicationRef, private utils: UtilsService) {}

	ngOnInit() {
		if (!this.tooltipContent) {
			return;
		}

		// handle content of tooltip
		let content;
		if (typeof this.tooltipContent === "string") {
			content = this.tooltipContent;
		} else {
			// create a view from the given template ref
			let viewRef = this.tooltipContent.createEmbeddedView(null);
			this.appRef.attachView(viewRef);
			// loop over all html elements in created view and add them to a new popup element
			let popupElement = document.createElement("div");
			for (let node of viewRef.rootNodes) {
				popupElement.append(node);
			}
			content = popupElement;
		}

		if ((this.utils.isPhone || this.utils.isTablet) && !this.tooltipTrigger.includes("click")) {
			this.tooltipTrigger = "manual";
		}

		// TODO DETACH AND DESTROY CREATED VIEW AND popupElement WHEN THE TOOLTIP IS DESTROYED - POSSIBLE MEMORY LEAK

		// init tippy
		tippy(this.el.nativeElement, {
			allowHTML: true,
			appendTo: document.body,
			animation: "perspective-extreme",
			theme: this.tooltipTheme,
			trigger: this.tooltipTrigger,
			interactive: true,
			arrow: roundArrow,
			content: content,
			placement: this.tooltipPosition,
		});
	}
}
