import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'AlphabeticSortPipe' })
export class AlphabeticSortPipe implements PipeTransform {
    transform(value: string[], ascending = true): string[] {
        // Use a copy of your array if you want to keep the original untouched
        return [...value].sort((a, b) => {
            a = a.toLowerCase(); // or a.toLocaleLowerCase()
            b = b.toLowerCase(); // or b.toLocaleLowerCase()
            return ascending ? a.localeCompare(b) : b.localeCompare(a);
        });
    }
}
