import { v4 as uuidv4 } from 'uuid';

/**
 * The base model for items which are stored in dynamoDB.
 * This class contains all base attributes a item needs and is extended
 * through various models which inherit this BaseItem class
 */
export class BaseItem {
    _id: string; // item id in db
    _rev?: string; // item revision in db
    type: string; // item type
    createdAt: number; // creation timestamp
    createdBy: string; // user name of the creator
    creatorCompanyId: string; // company id of the creator
    updatedAt: number; // last update timestamp
    updatedBy; // user name of the person who did the last update
    version: number; // version number
    synced?: boolean; // sync status (local only)
    syncedAt?: number; // sync timestamp
    pk: string; // primary key in dynamoDb
    sk?: string; // sort key in dynamoDb
    relation?: string; // relation - used for sync
    deletedAt?: number; // deletion timestamp
    deletionInfo?: string; // info about deletion (if the item was deleted by user, deleted because related project was deleted, etc.)
    ttl?: number; // ttl - used in dynamodb if the item will be deleted permanently from dynamodb
    liS3Path?: string; // path to item on s3 (if this item is a very large item which cannot be stored on dynamodb)
    sortOrder?: number | string;

    constructor(data?: any, removePropertiesForNesting = false) {
        this._id = uuidv4();
        this.type = '';
        this.createdAt = Date.now();
        this.createdBy = null;
        this.creatorCompanyId = null;
        this.updatedAt = Date.now();
        this.updatedBy = null;
        this.version = -1;
        this.sortOrder = -1;

        // default pk = _id
        this.pk = this._id; // default pk = _id

        if (data != null) {
            if (data._id != null) {
                this._id = data._id;
            }
            if (data._rev != null) {
                this._rev = data._rev;
            }
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.createdAt != null) {
                this.createdAt = data.createdAt;
            }
            if (data.createdBy != null) {
                this.createdBy = data.createdBy;
            }
            if (data.creatorCompanyId != null) {
                this.creatorCompanyId = data.creatorCompanyId;
            }
            if (data.updatedAt != null) {
                this.updatedAt = data.updatedAt;
            }
            if (data.updatedBy != null) {
                this.updatedBy = data.updatedBy;
            }
            if (data.version != null) {
                this.version = data.version;
            }
            if (data.synced != null) {
                this.synced = data.synced;
            }
            if (data.syncedAt != null) {
                this.syncedAt = data.syncedAt;
            }
            if (data.pk != null) {
                this.pk = data.pk;
            }
            if (data.sk != null) {
                this.sk = data.sk;
            }
            if (data.relation != null) {
                this.relation = data.relation;
            }
            if (data.deletedAt != null) {
                this.deletedAt = data.deletedAt;
            }
            if (data.deletionInfo != null) {
                this.deletionInfo = data.deletionInfo;
            }
            if (data.ttl != null) {
                this.ttl = data.ttl;
            }
            if (data.liS3Path != null) {
                this.liS3Path = data.liS3Path;
            }
            if (data.sortOrder != null) {
                this.sortOrder = data.sortOrder;
            }
        }

        if (removePropertiesForNesting) {
            this.removePropertiesForNesting();
        }
    }

    /**
     * Removes properties which are not needed when the item gets nested inside another item
     */
    removePropertiesForNesting() {
        delete this.createdAt;
        delete this.createdBy;
        delete this.creatorCompanyId;
        delete this._rev;
        delete this.updatedAt;
        delete this.updatedBy;
        delete this.synced;
        delete this.syncedAt;
        delete this.pk;
        delete this.sk;
        delete this.relation;
    }

    /**
     * Returns true if the item has a revision number
     */
    hasRev() {
        return this._rev != null;
    }
}
