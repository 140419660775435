<ion-modal animated="true" class="dp-calculator-keyboard-modal" [initialBreakpoint]="0.4" [breakpoints]="[0, 0.4]">
    <ng-template>
        <div class="calculator-wrap" [ngStyle]="{ height: utils.fullWindowHeight * 0.4 + 'px' }">
            <div class="calculator-input-wrap">
                <div class="calculator-input">
                    <ion-item lines="none">
                        <ion-textarea
                            [label]="fieldName"
                            labelPlacement="stacked"
                            autosize
                            rows="1"
                            [value]="initialValue"
                            inputmode="none"
                            placeholder="Rechnung eingeben"
                            #calculatorPreviewInput
                        >
                        </ion-textarea>
                    </ion-item>
                </div>
                <div class="calculator-result ion-text-right" *ngIf="calculatorCurrentResult != null">
                    <span *ngIf="calculatorCurrentResult === false; else calculatorShowResult" class="color-danger">
                        <fa-icon icon="exclamation-circle"></fa-icon>
                    </span>
                    <ng-template #calculatorShowResult>
                        <strong> = {{ calculatorCurrentResult }} </strong>
                    </ng-template>
                </div>
            </div>

            <div class="calculator-buttons-wrap">
                <div class="calculator-row calculator-row-1">
                    <div
                        class="calculator-btn ion-activatable btn-extra"
                        (pointerdown)="onCalculatorButtonPress('BACKSPACE', $event)"
                        (mousedown)="onCalculatorButtonPress('BACKSPACE', $event)"
                    >
                        <fa-icon [icon]="['fal', 'arrow-left']"></fa-icon><ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable btn-extra"
                        (pointerdown)="onCalculatorButtonPress('(', $event)"
                        (mousedown)="onCalculatorButtonPress('(', $event)"
                    >
                        <fa-icon [icon]="['fal', 'bracket-round']"></fa-icon><ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable btn-extra"
                        (pointerdown)="onCalculatorButtonPress(')', $event)"
                        (mousedown)="onCalculatorButtonPress(')', $event)"
                    >
                        <fa-icon [icon]="['fal', 'bracket-round-right']"></fa-icon><ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable btn-operand"
                        (pointerdown)="onCalculatorButtonPress('/', $event)"
                        (mousedown)="onCalculatorButtonPress('/', $event)"
                    >
                        <fa-icon [icon]="['fal', 'divide']"></fa-icon><ion-ripple-effect></ion-ripple-effect>
                    </div>
                </div>
                <div class="calculator-row calculator-row-2">
                    <div
                        class="calculator-btn ion-activatable"
                        (pointerdown)="onCalculatorButtonPress('7', $event)"
                        (mousedown)="onCalculatorButtonPress('7', $event)"
                    >
                        7<ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable"
                        (pointerdown)="onCalculatorButtonPress('8', $event)"
                        (mousedown)="onCalculatorButtonPress('8', $event)"
                    >
                        8<ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable"
                        (pointerdown)="onCalculatorButtonPress('9', $event)"
                        (mousedown)="onCalculatorButtonPress('9', $event)"
                    >
                        9<ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable btn-operand"
                        (pointerdown)="onCalculatorButtonPress('*', $event)"
                        (mousedown)="onCalculatorButtonPress('*', $event)"
                    >
                        <fa-icon [icon]="['fal', 'xmark']"></fa-icon><ion-ripple-effect></ion-ripple-effect>
                    </div>
                </div>
                <div class="calculator-row calculator-row-3">
                    <div
                        class="calculator-btn ion-activatable"
                        (pointerdown)="onCalculatorButtonPress('4', $event)"
                        (mousedown)="onCalculatorButtonPress('4', $event)"
                    >
                        4<ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable"
                        (pointerdown)="onCalculatorButtonPress('5', $event)"
                        (mousedown)="onCalculatorButtonPress('5', $event)"
                    >
                        5<ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable"
                        (pointerdown)="onCalculatorButtonPress('6', $event)"
                        (mousedown)="onCalculatorButtonPress('6', $event)"
                    >
                        6<ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable btn-operand"
                        (pointerdown)="onCalculatorButtonPress('-', $event)"
                        (mousedown)="onCalculatorButtonPress('-', $event)"
                    >
                        <fa-icon [icon]="['fal', 'minus']"></fa-icon><ion-ripple-effect></ion-ripple-effect>
                    </div>
                </div>
                <div class="calculator-row calculator-row-4">
                    <div
                        class="calculator-btn ion-activatable"
                        (touchdown)="onCalculatorButtonPress('1', $event)"
                        (mousedown)="onCalculatorButtonPress('1', $event)"
                    >
                        1<ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable"
                        (pointerdown)="onCalculatorButtonPress('2', $event)"
                        (mousedown)="onCalculatorButtonPress('2', $event)"
                    >
                        2<ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable"
                        (pointerdown)="onCalculatorButtonPress('3', $event)"
                        (mousedown)="onCalculatorButtonPress('3', $event)"
                    >
                        3<ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable btn-operand"
                        (pointerdown)="onCalculatorButtonPress('+', $event)"
                        (mousedown)="onCalculatorButtonPress('+', $event)"
                    >
                        <fa-icon [icon]="['fal', 'plus']"></fa-icon><ion-ripple-effect></ion-ripple-effect>
                    </div>
                </div>
                <div class="calculator-row calculator-row-5">
                    <div
                        class="calculator-btn ion-activatable span-2"
                        (pointerdown)="onCalculatorButtonPress('0', $event)"
                        (mousedown)="onCalculatorButtonPress('0', $event)"
                    >
                        0<span class="spacer">0</span><ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable"
                        (pointerdown)="onCalculatorButtonPress('.', $event)"
                        (mousedown)="onCalculatorButtonPress('.', $event)"
                    >
                        <fa-icon [icon]="['fal', 'comma']"></fa-icon><ion-ripple-effect></ion-ripple-effect>
                    </div>
                    <div
                        class="calculator-btn ion-activatable btn-operand"
                        (pointerdown)="onCalculatorButtonPress('ENTER', $event)"
                        (mousedown)="onCalculatorButtonPress('ENTER', $event)"
                    >
                        OK<ion-ripple-effect></ion-ripple-effect>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ion-modal>
