<ion-header [class.mobile]="utils.mobileViewActive">
    <ng-container *ngIf="!disable">
        <ion-toolbar id="dp-header" *ngIf="loggedIn == true">
            <ion-buttons slot="start" class="dp-header-start">
                <!-- <ion-back-button text="" icon="chevron-back-outline"></ion-back-button> -->
                <ion-button class="dp-header-logo" fill="clear" routerLink="/" routerDirection="root">
                    <img src="../../../assets/img/dokupit-logo.svg" alt="DokuPit Logo" />
                </ion-button>
                <div class="dp-header-big">
                    <ion-button
                        class="dp-nav-item"
                        routerLink="/"
                        routerLinkActive="nav-item-active"
                        routerDirection="root"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Projektübersicht</ion-button
                    >
                    <ion-button
                        *ngIf="settingsSrv.currentUserRights.canEditGlobalServiceGroups"
                        class="dp-nav-item dp-nav-item-service-groups"
                        routerLink="/service-groups"
                        routerDirection="root"
                        routerLinkActive="nav-item-active"
                        >Vorlagen</ion-button
                    >
                    <ion-button
                        *ngIf="settingsSrv.currentCompanyFeatures.timeTracking"
                        class="dp-nav-item dp-nav-item-time-tracking"
                        routerLink="/time-tracking"
                        routerDirection="root"
                        routerLinkActive="nav-item-active"
                        >Zeiterfassung</ion-button
                    >
                    <ion-button
                        *ngIf="settingsSrv.currentUserRights.canSeeCalendar"
                        class="dp-nav-item dp-nav-item-time-calendar"
                        routerLink="/calendar"
                        routerDirection="root"
                        routerLinkActive="nav-item-active"
                        >Kalender</ion-button
                    >
                </div>
            </ion-buttons>
            <ion-title>
                <ng-container *ngIf="!projectRouteActive; else projectNameHeading">{{ mobileTitle }}</ng-container>
                <ng-template #projectNameHeading>
                    {{ currentProject?.name }}
                    <ion-label *ngIf="currentProject && currentProject?.projectNumber">
                        <fa-icon slot="start" icon="hashtag"></fa-icon>{{ currentProject?.projectNumber }}
                    </ion-label>
                </ng-template>
            </ion-title>
            <ion-buttons slot="end" class="dp-header-end">
                <!-- SYNC START -->
                <ion-button
                    dpTooltip
                    [tooltipContent]="syncStatus"
                    tooltipTrigger="click"
                    tooltipTheme="light"
                    tooltipPosition="bottom"
                    class="dp-nav-item dp-sync-status"
                    *ngIf="connected; else offlineStateBtn"
                >
                    <fa-layers [fixedWidth]="true" slot="icon-only" *ngIf="currentSyncStatus == false && userIdleStatus == 'active'">
                        <fa-icon icon="cloud" size="lg"></fa-icon>
                        <fa-icon icon="check" size="lg" transform="shrink-6 left-1 down-1"></fa-icon>
                    </fa-layers>

                    <fa-icon
                        slot="icon-only"
                        icon="user-clock"
                        size="1x"
                        *ngIf="currentSyncStatus == false && userIdleStatus != 'active'"
                    ></fa-icon>

                    <fa-icon slot="icon-only" icon="sync" spin="true" size="lg" *ngIf="currentSyncStatus !== false"></fa-icon>
                </ion-button>

                <ng-template #offlineStateBtn>
                    <ion-button
                        dpTooltip
                        [tooltipContent]="offlineStatus"
                        tooltipTrigger="click"
                        tooltipTheme="light"
                        tooltipPosition="bottom"
                        class="dp-nav-item dp-sync-status"
                    >
                        <fa-icon slot="icon-only" icon="wifi-slash" size="1x"></fa-icon>
                    </ion-button>
                </ng-template>

                <ng-template #syncStatus>
                    <app-sync-status></app-sync-status>
                </ng-template>
                <ng-template #offlineStatus>
                    <h2>Keine Internetverbindung</h2>
                    <div>Sie arbeiten aktuell offline und der Sync wurde pausiert bis eine Verbindung wiederhergestellt werden konnte.</div>
                </ng-template>
                <!-- SYNC END -->
                <div class="dp-header-big">
                    <!-- USER INFO START -->
                    <div class="dp-header-user" dpTooltip [tooltipContent]="userDropdown" tooltipTrigger="click" tooltipTheme="light">
                        <div class="user-greeting">{{ this.greeting + ', ' }}</div>
                        <div class="user-forename">
                            {{ authInfo.userData.firstName + ' ' + authInfo.userData.lastName }}
                        </div>

                        <div class="user-dropdown dropdown">
                            <ion-button fill="clear" class="user-dropdown-trigger">
                                <fa-icon icon="chevron-down" class="color-white" slot="icon-only"></fa-icon>
                            </ion-button>

                            <ng-template #userDropdown>
                                <div class="dropdown-user-info">
                                    <div class="user-info-company">
                                        <strong>{{ authInfo.companyData.name }}</strong>
                                    </div>
                                    <div class="user-info-name">
                                        {{ authInfo.userData.firstName + ' ' + authInfo.userData.lastName }}
                                    </div>
                                    <div class="user-info-license">({{ authInfo.userData.licenseType }})</div>
                                </div>
                                <div class="dropdown-links">
                                    <ion-list class="bg-white">
                                        <ion-item class="no-padding bg-white" [routerLink]="['/updates']">
                                            <fa-icon icon="ballot" class="color-black dp-mr-s"></fa-icon>
                                            <ion-label>Updates</ion-label>
                                        </ion-item>
                                        <ion-item class="no-padding bg-white" lines="none" (click)="onLogout()" button>
                                            <fa-icon icon="sign-out" class="color-black dp-mr-s"></fa-icon>
                                            <ion-label>Ausloggen</ion-label>
                                        </ion-item>
                                    </ion-list>
                                </div>
                                <div class="dropdown-version">
                                    <ion-label> {{ versionInfo }} </ion-label>
                                    <ion-badge slot="end" style="vertical-align: sub" color="warning" *ngIf="isBetaVersion">Beta</ion-badge>
                                    <ion-badge slot="end" style="vertical-align: sub" color="danger" *ngIf="isDevVersion">Dev</ion-badge>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <!-- USER INFO END -->
                    <!-- TOOLS START-->
                    <ion-button
                        class="dp-nav-item shop-nav-btn"
                        [routerLink]="['/shop', 'items']"
                        *ngIf="settingsSrv.currentUserRights.canSeeShop"
                    >
                        <fa-icon slot="icon-only" icon="shopping-cart"></fa-icon>
                    </ion-button>
                    <ion-button class="dp-nav-item" [routerLink]="['/support']">
                        <fa-icon slot="icon-only" icon="life-ring"></fa-icon>
                    </ion-button>
                    <ion-button class="dp-nav-item" [routerLink]="['/settings']">
                        <fa-icon slot="icon-only" icon="cogs"></fa-icon>
                    </ion-button>
                    <!-- TOOLS END -->
                </div>

                <!-- MOBILE MENU START -->
                <div class="dp-header-mobile-menu-toggle">
                    <ion-menu-button class="menu-toggle" menu="mobile-main-menu"></ion-menu-button>
                </div>
                <!-- MOBILE MENU END -->
            </ion-buttons>
        </ion-toolbar>
    </ng-container>
</ion-header>
