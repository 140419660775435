<ng-select class="article-icon-definition" appendTo="body" placeholder="Symbol"
           [ngModel]="iconForm.get('iconName').value" [ngModelOptions]="{standalone: true}"
           (change)="onSelectIcon($event, iconForm)">
    <ng-option *ngFor="let icon of possibleIcons" [value]="icon.iconName">
        <span class="field-icon-select-inner-wrap">
            <app-field-icon-view [icon]="icon" [limitSize]="'25px'" [lazyLoad]="false" class="dp-mr-s"></app-field-icon-view>
            {{ icon.displayName }}
        </span>
    </ng-option>
</ng-select>

