import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'app-info-bar',
    templateUrl: './info-bar.component.html',
    styleUrls: ['./info-bar.component.scss'],
})
export class InfoBarComponent {
    @Input() infoType: 'info' | 'danger' | 'warning' = 'info';
    @Input() icon: IconProp = null;
    constructor() {}
}
