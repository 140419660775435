import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
    createDefaultColorOptions,
    createDefaultImageOrienter,
    createDefaultImageReader,
    createDefaultImageScrambler,
    createDefaultImageWriter,
    createDefaultShapePreprocessor,
    createMarkupEditorToolbar,
    createMarkupEditorToolStyles,
    ImageSource,
    locale_en_gb,
    markup_editor_defaults,
    markup_editor_locale_en_gb,
    plugin_annotate,
    plugin_annotate_locale_en_gb,
    plugin_redact,
    plugin_redact_locale_en_gb,
    setPlugins,
} from '@pqina/pintura';
import { PinturaEditorComponent } from '@pqina/angular-pintura';
import locale_de_DE from '@pqina/pintura/locale/de_DE';
import { LoggerService } from '../../logger/logger.service';
import { UtilsService } from '../../utils/utils.service';
import { NotificationService } from '../../notification/notification.service';

// set the enabled plugins for the image editor - without it does not load!
setPlugins(plugin_annotate, plugin_redact);

@Component({
    selector: 'dp-image-editor',
    templateUrl: './image-editor.component.html',
    styleUrls: ['./image-editor.component.scss'],
})
export class DpImageEditorComponent {
    @Input() src: ImageSource | undefined;
    @Output() loaded = new EventEmitter();
    @Output() cancelled = new EventEmitter();
    @Output() process = new EventEmitter();

    @ViewChild('editor') editor: PinturaEditorComponent<any>;

    pinturaOpts: any = {
        imageReader: createDefaultImageReader(),
        imageWriter: createDefaultImageWriter(),
        shapePreprocessor: createDefaultShapePreprocessor(),
        imageOrienter: createDefaultImageOrienter(),
        imageScrambler: createDefaultImageScrambler(),
        enableToolbar: true, // build own toolbar in future,
        enableButtonClose: true, // needs extra handling for close which is ui clutter we don't need
        enableZoomControls: false,
        enableTapToAddText: true,
        annotateTools: createMarkupEditorToolbar(['sharpie', 'arrow', 'rectangle', 'text', 'ellipse', 'eraser']),
        ...markup_editor_defaults,
        markupEditorToolStyles: createMarkupEditorToolStyles({
            sharpie: {
                strokeWidth: '1%', // corresponds to "middlesmall", value is taken from debugger
            },
            arrow: {
                strokeWidth: '1.75%',
            },
            text: {
                // Increase default font size
                fontSize: '8%',

                // Set default font color to black
                // color: createDefaultColorOptions().black,

                // Enable text background color
                backgroundColor: createDefaultColorOptions().transparent,
            },
            // rectangle: {
            //     // Set default color for rectangle background to yellow
            //     backgroundColor: createDefaultColorOptions().yellow,
            // },
        }),
        markupEditorTextInputMode: this.utils.isPhone ? 'modal' : 'inline',
        locale: {
            ...locale_en_gb,
            ...plugin_annotate_locale_en_gb,
            ...plugin_redact_locale_en_gb,
            ...markup_editor_locale_en_gb,
            ...locale_de_DE,
            cropLabel: 'Trimmen',
            annotateLabel: 'Anmerken',
            redactLabel: 'Verpixeln',
        },
        animations: this.utils.isPhone ? 'never' : 'auto',
        layoutVerticalToolbarPreference: this.utils.isPhone ? 'bottom' : 'top',
    };

    constructor(
        private logger: LoggerService,
        private notificationSrv: NotificationService,
        private utils: UtilsService
    ) {}

    handleLoad($event: any) {
        this.logger.log('[IMAGE EDITOR] loaded', $event);
        this.loaded.emit($event);
    }

    handleLoadError($event: any) {
        this.logger.error('[IMAGE EDITOR] load failed', $event);
        this.notificationSrv.error('Bild konnte nicht geladen werden!');
        this.editor.closeEditor();
    }

    handleClose($event: any) {
        this.logger.log('[IMAGE EDITOR] user closed', $event);
        this.cancelled.emit();
    }

    async handleStartProcessing() {
        // console.log(this.editor.history.length);
        this.logger.log('[IMAGE EDITOR] checking if processing is even needed');
        if (this.editor.history.length <= 1) {
            this.logger.log('[IMAGE EDITOR] processing not needed. Cancel and return event');
            this.editor.abortProcessImage();

            this.process.emit({
                changed: this.editor.history.length > 1,
                editorData: undefined,
            });
        }
    }

    async handleProcess($event: any) {
        this.logger.log('[IMAGE EDITOR] processed data', $event);
        // add extra data to the process
        // only with a history greater than 1 (initial change) we have changes to the image in some way
        this.process.emit({
            changed: this.editor.history.length > 1,
            editorData: $event,
        });
    }
}

export interface DpImageEditorProcessData {
    changed: boolean;
    editorData: any;
}
