<div
    class="current-color-display"
    [style.background-color]="color"
    ngx-colors-trigger
    [(ngModel)]="pickerVal"
    [format]="'hex'"
    (input)="onColorChanged($event)"
    acceptLabel="Akzeptieren"
    cancelLabel="Abbrechen"
>
    <span *ngIf="color == 'transparent'; else deleteColor"><ng-content></ng-content></span>
    <ng-template #deleteColor>
        <ion-button class="color-delete" fill="clear" color="dark" size="small" (click)="onDeleteColor($event)">
            <fa-icon icon="times" slot="icon-only"></fa-icon>
        </ion-button>
    </ng-template>
</div>
