import { NgSelectModule } from '@ng-select/ng-select';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DpBackdropComponent } from './dp-backdrop/dp-backdrop.component';
import { SelectDrawerComponent } from './select-drawer/select-drawer.component';
import { CodeScannerComponent } from './code-scanner/code-scanner.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { PlanAttachmentSelectComponent } from './attachment/plan-attachment-select/plan-attachment-select.component';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { DialogComponent } from './dialog/dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { AttachmentSelectComponent } from './attachment/attachment-select/attachment-select.component';
import { AttachmentViewComponent } from './attachment/attachment-view/attachment-view.component';
import { SyncStatusComponent } from './sync/sync-status/sync-status.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { SignatureComponent } from './signature/signature.component';
import { IsLoadingDirective } from './loading-indicator/is-loading-directive/is-loading.directive';
import { CodeValidatorDirective } from './code-validator/code-validator.directive';
import { NgxColorsModule } from 'ngx-colors';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { FieldIconViewComponent } from './field-icon/field-icon-view/field-icon-view.component';
import { FieldIconSelectComponent } from './field-icon/field-icon-select/field-icon-select.component';
import { AutosizeModule } from 'ngx-autosize';
import { DpCameraComponent } from './camera/dp-camera/dp-camera.component';
import { InfoBarComponent } from './info-bar/info-bar.component';
import { AlphabeticSortPipe } from './alphabetic-sort/alphabetic-sort.pipe';
import { CalculatorKeyboardComponent } from './calculator-keyboard/calculator-keyboard.component';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { DpImageEditorComponent } from './attachment/image-editor/image-editor.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        FontAwesomeModule,
        NgxColorsModule,
        ZXingScannerModule,
        FormsModule,
        ReactiveFormsModule,
        PinchZoomModule,
        NgSelectModule,
        AutosizeModule,
        AngularPinturaModule,
    ],
    declarations: [
        HeaderComponent,
        MobileMenuComponent,
        LoadingIndicatorComponent,
        DialogComponent,
        AttachmentSelectComponent,
        PlanAttachmentSelectComponent,
        AttachmentViewComponent,
        TooltipDirective,
        SyncStatusComponent,
        ColorPickerComponent,
        SignatureComponent,
        IsLoadingDirective,
        CodeValidatorDirective,
        CodeScannerComponent,
        SelectDrawerComponent,
        DpBackdropComponent,
        FieldIconViewComponent,
        FieldIconSelectComponent,
        DpCameraComponent,
        InfoBarComponent,
        AlphabeticSortPipe,
        CalculatorKeyboardComponent,
        DpImageEditorComponent,
    ],
    exports: [
        HeaderComponent,
        MobileMenuComponent,
        LoadingIndicatorComponent,
        DialogComponent,
        AttachmentSelectComponent,
        PlanAttachmentSelectComponent,
        AttachmentViewComponent,
        TooltipDirective,
        SyncStatusComponent,
        ColorPickerComponent,
        SignatureComponent,
        IsLoadingDirective,
        CodeScannerComponent,
        SelectDrawerComponent,
        DpBackdropComponent,
        FieldIconViewComponent,
        FieldIconSelectComponent,
        DpCameraComponent,
        InfoBarComponent,
        AlphabeticSortPipe,
        CalculatorKeyboardComponent,
        DpImageEditorComponent,
    ],
})
export class CoreModule {}
