import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivateFn();
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(next, state);
    }

    canActivateFn() {
        // navigation is ok if the user is already logged in
        if (this.authService.isLoggedIn) {
            return true;
        }

        // check if we have a session and conclude login or go to login if we have no session active
        return new Promise<boolean | UrlTree>((resolve) => {
            this.authService
                .startLoginWithTokens(true)
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    // could not refresh session, go to login page
                    resolve(this.router.parseUrl('/auth'));
                });
        });
    }
}
