<ng-container *ngIf="isEnabled">
    <div
        class="dp-scanner-wrap"
        [class.in-modal]="containerType == 'modal'"
        [class.inline]="containerType == 'default'"
        [class.in-dialog]="containerType == 'dialog'"
        [class.web]="!utils.isNative"
    >
        <div class="dp-scanner-inner-wrap" [class.hidden]="webScannerLoading">
            <ng-container *ngIf="!utils.isNative">
                <div class="web-scanner-preview">
                    <ng-container *ngIf="webScannerEnabled; else showWebStart">
                        <zxing-scanner
                            [formats]="supportedFormats"
                            [(device)]="desiredDevice"
                            [torch]="torchActive"
                            (torchCompatible)="onTorchCompatible($event)"
                            (camerasFound)="onWebCamerasFound($event)"
                            (camerasNotFound)="onWebCamerasNotFound($event)"
                            (scanSuccess)="onWebScanSuccess($event)"
                            (scanError)="onWebScanError($event)"
                            (scanFailure)="onWebScanFailure($event)"
                            #webScanner
                        ></zxing-scanner>
                    </ng-container>
                    <ng-template #showWebStart>
                        <ion-button fill="clear" class="dp-web-scan-start dp-adaptive-content-wrap" (click)="onInitWebScan()">
                            <fa-icon slot="start" [icon]="['fal', 'qrcode']"></fa-icon>
                            <ion-label>Kamera verwenden</ion-label>
                        </ion-button>
                    </ng-template>
                </div>
            </ng-container>

            <ion-grid class="dp-g-fab h-end fixed bottom dp-edge">
                <ion-row>
                    <ion-col *ngIf="allowCameraSwap">
                        <ion-button class="dp-g-fab-btn" shape="round" size="small" (click)="toggleCameraDevice()">
                            <fa-icon icon="retweet" slot="icon-only"></fa-icon>
                        </ion-button>
                    </ion-col>
                    <ion-col *ngIf="allowTorchActivation">
                        <ion-button class="dp-g-fab-btn" shape="round" size="small" (click)="toggleTorch()">
                            <fa-layers [fixedWidth]="true" class="torch-toggle-icon" slot="icon-only">
                                <fa-icon [icon]="['fal', 'flashlight']" transform="left-1" rotate="270"></fa-icon>
                                <fa-icon icon="slash" transform="left-1" *ngIf="torchActive"></fa-icon>
                            </fa-layers>
                        </ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-button
                            class="dp-g-fab-btn"
                            shape="round"
                            color="danger"
                            (click)="cancelScan()"
                            [size]="isSearchAvailable ? 'small' : undefined"
                        >
                            <fa-icon [icon]="['fal', 'times']" slot="icon-only"></fa-icon>
                        </ion-button>
                    </ion-col>
                    <ion-col *ngIf="isSearchAvailable">
                        <ion-button class="dp-g-fab-btn" shape="round" color="success" (click)="searchCode()">
                            <fa-icon [icon]="searchIcon" slot="icon-only"></fa-icon>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <div class="dp-scanner-overlay" [class.ios]="platform.is('ios')" *ngIf="utils.isNative || webScannerEnabled">
                <ion-icon name="scan-outline"></ion-icon>
            </div>

            <ion-footer class="dp-scanner-action-bar">
                <ion-toolbar>
                    <ion-input
                        [placeholder]="searchTextPlaceholder"
                        type="text"
                        dpCodeValidator
                        (ionInput)="codeChanged($event)"
                        [debounce]="150"
                        (keyup.enter)="searchCode()"
                        #codeSearchInput
                    ></ion-input>
                </ion-toolbar>
            </ion-footer>
        </div>

        <ng-container *ngIf="webScannerLoading">
            <div class="scanner-notice">
                <app-loading-indicator>Warte auf Scanner</app-loading-indicator>
            </div>
        </ng-container>
    </div>
</ng-container>
