import { BaseItem } from '../../../core/db/base-item.model';
import { ReportType } from './report-type.model';
import { FieldType, PossibleFieldValue } from '../../../service-groups/articles/article-details/field/field.model';
import { Company } from '../../../core/shared-models/company.model';
import { Attachment } from '../../../core/attachment/attachment.model';

export class Report extends BaseItem {
    name: string;
    reportType: ReportType;
    filters: Array<ReportFilter>;
    selectedJobIds: Array<string>;
    emailReceiversAfterGeneration: Array<{
        email: string;
        removableByNonAdmins: boolean;
    }>; // if there are entries in this list, report emails will be sent automatically after report generation has finished
    sentEmails: Array<{
        email: string;
        sentAt: number;
    }>; // shows info if emails were sent for this report (so this shows a history of all sent emails for this report)
    companyInfo: Company;
    projectId: string;
    projectName: string;
    generationStatus: ReportGenerationStatus;
    signatures: Array<Attachment>;
    executionId: string; // id of the aws state machine execution
    reportFilePath: string;
    generationNotices: Array<string>; // notices while generation

    constructor(data?: any) {
        super(data, false);

        this.type = 'report';
        this.name = '';
        this.reportType = new ReportType();
        this.filters = [];
        this.selectedJobIds = [];
        this.emailReceiversAfterGeneration = [];
        this.sentEmails = [];
        this.companyInfo = null;
        this.projectId = '';
        this.projectName = '';
        this.generationStatus = ReportGenerationStatus.Pending;
        this.signatures = [];
        this.executionId = null;
        this.reportFilePath = null;
        this.generationNotices = [];

        if (data != null) {
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.reportType != null) {
                this.reportType = new ReportType(data.reportType);
            }
            if (data.filters != null) {
                this.filters = data.filters.map((x) => new ReportFilter(x));
            }
            if (data.selectedJobIds != null) {
                this.selectedJobIds = data.selectedJobIds;
            }
            if (data.emailReceiversAfterGeneration != null) {
                this.emailReceiversAfterGeneration = data.emailReceiversAfterGeneration;
            }
            if (data.sentEmails != null) {
                this.sentEmails = data.sentEmails;
            }
            if (data.companyInfo != null) {
                this.companyInfo = new Company(data.companyInfo);
            }
            if (data.projectId != null) {
                this.projectId = data.projectId;
            }
            if (data.projectName != null) {
                this.projectName = data.projectName;
            }
            if (data.generationStatus != null) {
                this.generationStatus = data.generationStatus;
            }
            if (data.signatures != null) {
                this.signatures = data.signatures.map((x) => new Attachment(x));
            }
            if (data.executionId != null) {
                this.executionId = data.executionId;
            }
            if (data.reportFilePath != null) {
                this.reportFilePath = data.reportFilePath;
            }
            if (data.generationNotices != null) {
                this.generationNotices = data.generationNotices;
            }
        }

        // add report prefix to pk
        if (this.pk != null && !this.pk.startsWith('report#')) {
            this.pk = 'report#' + this._id;
            this.sk = 'report#' + this._id;
        }
    }
}

export class ReportFilter {
    filterType: FilterType;
    includedServiceGroups: Array<string>;
    includedCompanyUsers: Array<string>;
    includedBuildingPlans: Array<string>;
    includedCompletionStatus: 'complete-only' | 'incomplete-only';
    minDate: number; // used for date filter
    maxDate: number; // used for date filter
    extensionDateInclusion: 'include-all' | 'only-with-correct-date';
    field: {
        serviceGroupId: string;
        articleId: string;
        fieldId: string;
        fieldName: string;
        fieldType: FieldType;
        restriction: 'none' | 'only-latest' | 'date-restriction';
        relativeDateNumber: number;
        relativeDateType: 'days' | 'weeks';
        desiredFieldValue: string;
        possibleFieldValues: Array<PossibleFieldValue>;
        compare: 'equals' | 'not-equals' | 'less-than' | 'more-than' | 'filled' | 'not-filled';
        inclusion: 'include-all' | 'include-only-correct-articles';
        inclusionMultipleBehaviour: 'all' | 'only-newest';
    };
    article: {
        serviceGroupId: string;
        articleId: Array<string>;
        dateRestriction: 'none' | 'relative';
        relativeDateNumber: number;
        relativeDateType: 'days' | 'weeks';
        inclusion: 'include-all' | 'include-only-selected-articles';
        inclusionMultipleBehaviour: 'all' | 'only-newest';
    };
    jobNumber: {
        jobNumberFilterType: 'selected-jobs' | 'from-to';
        jobNumberFromTo: string;
        includedJobs: Array<string>;
    };

    constructor(data?: any) {
        this.filterType = FilterType.None;
        this.includedServiceGroups = [];
        this.includedCompanyUsers = [];
        this.includedBuildingPlans = [];
        this.includedCompletionStatus = 'complete-only';
        this.minDate = null;
        this.maxDate = null;
        this.extensionDateInclusion = 'only-with-correct-date';
        this.field = {
            serviceGroupId: null,
            articleId: null,
            fieldId: null,
            fieldName: null,
            fieldType: null,
            restriction: 'only-latest',
            relativeDateNumber: 7,
            relativeDateType: 'days',
            desiredFieldValue: null,
            possibleFieldValues: null,
            compare: 'equals',
            inclusion: 'include-all',
            inclusionMultipleBehaviour: 'only-newest',
        };
        this.article = {
            serviceGroupId: null,
            articleId: null,
            dateRestriction: 'none',
            relativeDateNumber: 7,
            relativeDateType: 'days',
            inclusion: 'include-all',
            inclusionMultipleBehaviour: 'only-newest',
        };
        this.jobNumber = {
            jobNumberFilterType: null,
            jobNumberFromTo: '',
            includedJobs: [],
        };

        if (data != null) {
            if (data.filterType != null) {
                this.filterType = data.filterType;
            }
            if (data.includedServiceGroups != null) {
                this.includedServiceGroups = data.includedServiceGroups;
            }
            if (data.includedCompanyUsers != null) {
                this.includedCompanyUsers = data.includedCompanyUsers;
            }
            if (data.includedBuildingPlans != null) {
                this.includedBuildingPlans = data.includedBuildingPlans;
            }
            if (data.includedCompletionStatus != null) {
                this.includedCompletionStatus = data.includedCompletionStatus;
            }
            if (data.minDate != null) {
                this.minDate = data.minDate;
            }
            if (data.maxDate != null) {
                this.maxDate = data.maxDate;
            }
            if (data.extensionDateInclusion != null) {
                this.extensionDateInclusion = data.extensionDateInclusion;
            }
            if (data.field != null) {
                this.field = data.field;
            }
            if (data.article != null) {
                this.article = data.article;
            }
            if (data.jobNumber != null) {
                this.jobNumber = data.jobNumber;
            }
        }
    }
}

export enum FilterType {
    None = '',
    ServiceGroup = 'service-group',
    BuildingPlan = 'building-plan',
    Date = 'date',
    Field = 'field',
    Article = 'article',
    JobCreator = 'job-creator',
    Completion = 'completion',
    JobNumber = 'job-number',
}

export enum ReportGenerationStatus {
    Pending = 'pending',
    Finished = 'finished',
    Failed = 'failed',
}
