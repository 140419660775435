import { Article } from "../../../../../service-groups/articles/article.model";
import { BaseItem } from "../../../../../core/db/base-item.model";

export class JobExtension extends BaseItem {
	relatedJob: string;
	worker: string;
	article: Article;

	constructor(data?: any) {
		super(data, false);

		this.type = "job-extension";
		this.relatedJob = null;
		this.worker = "";
		this.article = null;

		if (data != null) {
			if (data.type != null) {
				this.type = data.type;
			}
			if (data.relatedJob != null) {
				this.relatedJob = data.relatedJob;
			}
			if (data.worker != null) {
				this.worker = data.worker;
			}
			if (data.article != null) {
				this.article = new Article(data.article, true);
			}
		}

		// add job-extension prefix to pk
		if (this.pk != null && !this.pk.startsWith("job-extension#")) {
			this.pk = "job-extension#" + this._id;
			this.sk = "job-extension#" + this._id;
		}
	}
}
