import { NgModule, inject } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
    },
    {
        path: 'service-groups',
        loadChildren: () => import('./service-groups/service-groups.module').then((m) => m.ServiceGroupsPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'products',
        loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'new-project',
        loadChildren: () => import('./projects/new-project/new-project.module').then((m) => m.NewProjectPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'project',
        loadChildren: () => import('./projects/project-details/project-details.module').then((m) => m.ProjectDetailsPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'updates',
        loadChildren: () => import('./updates/updates.module').then((m) => m.UpdatesPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'shop',
        loadChildren: () => import('./shop/shop.module').then((m) => m.ShopPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'support',
        loadChildren: () => import('./support/support.module').then((m) => m.SupportPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'time-tracking',
        loadChildren: () => import('./time-tracking/time-tracking.module').then((m) => m.TimeTrackingPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'calendar',
        loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: 'code',
        loadChildren: () => import('./public/public-code-view/public-code-view.module').then((m) => m.PublicCodeViewPageModule),
    },
    {
        path: 'tab-locked',
        loadChildren: () => import('./public/tab-locked/tab-locked.module').then((m) => m.TabLockedPageModule),
    },
    {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
        canActivate: [() => inject(AuthGuard).canActivateFn()],
    },
    {
        path: '**',
        loadChildren: () => import('./page-not-found/page-not-found.module').then((m) => m.PageNotFoundPageModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })], // TODO: maybe we can change this as this would improve performance in some workflows
    exports: [RouterModule],
})
export class AppRoutingModule {}
