import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpParams, HttpRequest } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class UploadService {
	constructor(private httpClient: HttpClient) {}

	/**
	 * Uploads a file object to a given URL using PUT
	 * @param url URL where you want to upload something
	 * @param file the file object or blob which will be passed to the request
	 * @returns Observable of HttpEvents including the progress updates
	 */
	uploadFile(url: string, file: File): Observable<HttpEvent<any>> {
		let params = new HttpParams();
		const options = {
			params: params,
			reportProgress: true,
		};

		let req = new HttpRequest("PUT", url, file, options);
		return this.httpClient.request(req);
	}
}
