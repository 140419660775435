import { v4 as uuidv4 } from "uuid";

export class DpNotification {
	pk: string; // primary key in dynamoDb
	type: "job-created" | "article-added" | "article-reminder" | "project-reminder"; // type of notification (based on that type notifications are different (email content, etc.))
	name: string;
	notificationFor: string; // relation of the notification (for company or for a single user)
	createdAt: number;
	notificationDate: number;
	relatedJob: string;
	relatedJobName: string;
	relatedJobNumber: string;
	relatedJobExtension: string;
	relatedJobExtensionName: string;
	relatedProject: string;
	relatedProjectName: string;
	creatorCompanyId: string;
	createdBy: string;
	createdByDisplayName: string;
	emailSent?: boolean; // true if server has sent mail (property does not exist on notification creation)
	emailSentAt?: number; // timestamp if server has sent mail (property does not exist on notification creation)

	constructor(data?: any) {
		this.pk = "notification#" + uuidv4();
		this.type = null;
		this.name = "";
		this.notificationFor = null;
		this.createdAt = Date.now();
		this.notificationDate = Date.now();
		this.relatedJob = null;
		this.relatedJobName = null;
		this.relatedJobNumber = null;
		this.relatedJobExtension = null;
		this.relatedJobExtensionName = null;
		this.relatedProject = null;
		this.relatedProjectName = null;
		this.creatorCompanyId = null;
		this.createdBy = null;
		this.createdByDisplayName = null;

		if (data != null) {
			if (data.pk != null) {
				this.pk = data.pk;
			}
			if (data.type != null) {
				this.type = data.type;
			}
			if (data.name != null) {
				this.name = data.name;
			}
			if (data.notificationFor != null) {
				this.notificationFor = data.notificationFor;
			}
			if (data.createdAt != null) {
				this.createdAt = data.createdAt;
			}
			if (data.notificationDate != null) {
				this.notificationDate = data.notificationDate;
			}
			if (data.relatedJob != null) {
				this.relatedJob = data.relatedJob;
			}
			if (data.relatedJobName != null) {
				this.relatedJobName = data.relatedJobName;
			}
			if (data.relatedJobNumber != null) {
				this.relatedJobNumber = data.relatedJobNumber;
			}
			if (data.relatedJobExtension != null) {
				this.relatedJobExtension = data.relatedJobExtension;
			}
			if (data.relatedJobExtensionName != null) {
				this.relatedJobExtensionName = data.relatedJobExtensionName;
			}
			if (data.relatedProject != null) {
				this.relatedProject = data.relatedProject;
			}
			if (data.relatedProjectName != null) {
				this.relatedProjectName = data.relatedProjectName;
			}
			if (data.creatorCompanyId != null) {
				this.creatorCompanyId = data.creatorCompanyId;
			}
			if (data.createdBy != null) {
				this.createdBy = data.createdBy;
			}
			if (data.createdByDisplayName != null) {
				this.createdByDisplayName = data.createdByDisplayName;
			}
			if (data.emailSent != null) {
				this.emailSent = data.emailSent;
			}
			if (data.emailSentAt != null) {
				this.emailSentAt = data.emailSentAt;
			}
		}
	}
}

export interface GroupedDpNotifications {
	relatedProject: string;
	relatedProjectName: string;
	notificationGroups: Array<{
		type: string;
		name: string;
		notifications: Array<DpNotification>;
	}>;
}
