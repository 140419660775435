import { Directive } from "@angular/core";
import { Validator, ValidationErrors, UntypedFormControl, NG_VALIDATORS } from "@angular/forms";

/**
 * Custom validation for our unique codes
 * Matches against Code39 spec
 */
@Directive({
	selector: "[dpCodeValidator]", // Attribute selector
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: CodeValidatorDirective,
			multi: true,
		},
	],
})
export class CodeValidatorDirective implements Validator {
	constructor() {}

	// This method is the one required by the Validator interface
	validate(c: UntypedFormControl): ValidationErrors | null {
		// Here we call our static validator function
		// static because then it can be used in FormBuilder code as well
		return CodeValidatorDirective.validateCodeInput(c);
	}

	static validateCodeInput(control: UntypedFormControl): ValidationErrors {
		if (!control.value || control.value === "*") {
			return null;
		}

		// equivalent to pattern="[\*]?[A-Za-z0-9-%\$\.\/\+]+[\*]?"
		let codePattern = RegExp("[*]?[A-Za-z0-9-%$./+]+[*]?");
		let matches = codePattern.exec(control.value);

		if (matches && matches[0] === control.value) {
			// Pattern matches complete input and is therefore valid
			return null;
		}

		// checks failed
		control.markAsTouched();
		return { invalidCode: "Ungültiger Code!" };
	}
}
