import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AttachmentService } from '../attachment/attachment.service';
import { LoggerService } from '../logger/logger.service';
import { UtilsService } from '../utils/utils.service';
import { DialogService } from '../dialog/dialog.service';
import SignaturePad from 'signature_pad';
import { Attachment } from '../attachment/attachment.model';
import { environment } from '../../../environments/environment';
import S3 from 'aws-sdk/clients/s3';
import { NotificationService } from '../notification/notification.service';

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
    private s3: S3;
    private lastPadW: number;
    uniqueId: string;
    signaturePad: SignaturePad;
    signatoryName = '';
    @Input() folderPath: string;
    @Input() forceDirectUpload = false;
    @Input() preFilledSignatoryName = null;
    @ViewChild('signatureCanvas', { static: true }) signatureCanvas: ElementRef;
    @ViewChild('signatureCanvasWrap', { static: true }) signatureCanvasWrap: ElementRef;
    @Output() signatureConfirmed = new EventEmitter<Attachment>();
    @Output() signatureCanceled = new EventEmitter();

    uploadLoader = false;

    constructor(
        private attachmentSrv: AttachmentService,
        private logger: LoggerService,
        private utils: UtilsService,
        private dialogSrv: DialogService,
        private notify: NotificationService
    ) {
        this.s3 = new S3({
            apiVersion: '2006-03-01',
            httpOptions: {
                timeout: 3600000, // 1 hour timeout
            },
            correctClockSkew: true,
        });
    }

    ngOnInit() {
        this.uniqueId = this.utils.generateUuid();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.lastPadW > 0 && this.lastPadW != this.signatureCanvasWrap.nativeElement.clientWidth) {
            this.lastPadW = this.signatureCanvasWrap.nativeElement.clientWidth;
            this.initSignaturePad();
        }
    }

    /**
     * Initializes the canvas for signatures
     */
    initSignaturePad() {
        this.logger.log('[SIGNATURE COMPONENT] initializing signature pad');

        if (this.preFilledSignatoryName != null) {
            this.signatoryName = this.utils.clone(this.preFilledSignatoryName);
        }

        // timeout because we cant resize the canvas to the parent dimensions accordingly if we are too fast
        setTimeout(() => {
            // resize canvas to parent element dimensions
            this.signatureCanvas.nativeElement.width = this.signatureCanvasWrap.nativeElement.clientWidth;
            this.signatureCanvas.nativeElement.height = 150;
            if (this.signaturePad) {
                this.signaturePad.clear();
            } else {
                this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
            }
        }, 500);
    }

    /**
     * Triggered when the user wants to create a new signature
     */
    onShowSignatureDialog() {
        this.dialogSrv.open('job-signature-' + this.uniqueId);
        this.initSignaturePad();
    }

    /**
     * Clears the signature canvas
     */
    onClearSignature() {
        this.signaturePad.clear();
    }

    /**
     * Triggered when the user cancels the signature dialog
     */
    onCancelSignature() {
        this.dialogSrv.close('job-signature-' + this.uniqueId);
        this.signatureCanceled.emit();
    }

    /**
     * Triggered when the user confirms the signature dialog
     */
    async onConfirmSignature() {
        let signatureAttachment = new Attachment();
        signatureAttachment.displayName = this.signatoryName.trim() == '' ? 'Unterschrift' : this.signatoryName;
        signatureAttachment.fileName = signatureAttachment._id + '.png';
        signatureAttachment.fileMimeType = 'image/png';

        // build filePath
        let folderToUse = this.utils.clone(this.folderPath);
        if (folderToUse != null && folderToUse != '') {
            // remove forward-slash at start or end, if there is one
            if (folderToUse.charAt(0) === '/') {
                folderToUse = folderToUse.substring(1);
            }
            if (folderToUse.charAt(folderToUse.length - 1) === '/') {
                folderToUse = folderToUse.substring(0, folderToUse.length - 1);
            }
            signatureAttachment.filePath = folderToUse + '/' + signatureAttachment.fileName;
        } else {
            signatureAttachment.filePath = signatureAttachment._id + '/' + signatureAttachment.fileName;
        }

        this.signatoryName = ''; // reset

        if (this.forceDirectUpload) {
            // upload signature directly to s3
            try {
                this.uploadLoader = true;
                let s3UploadResponse = await this.s3
                    .putObject({
                        Bucket: environment.AWS_S3_BUCKET_NAME,
                        Body: this.utils.dataURItoBlob(this.signaturePad.toDataURL(), 'image/png'),
                        Key: environment.AWS_S3_CUSTOMER_DATA_BASE_PATH + signatureAttachment.filePath,
                        ContentType: signatureAttachment.fileMimeType,
                    })
                    .promise();
                this.logger.log('[SIGNATURE COMPONENT] direct upload for signature successful', signatureAttachment);
                this.uploadLoader = false;
                this.dialogSrv.close('job-signature-' + this.uniqueId);
                this.signatureConfirmed.emit(signatureAttachment);
            } catch (s3UploadErr) {
                this.logger.error('[SIGNATURE COMPONENT] could not direct-upload file to s3', s3UploadErr);
                this.utils.sentryCaptureException(s3UploadErr);
                this.notify.error('Die Unterschrift konnte nicht hochgeladen werden. Bitte prüfe deine Verbindung und versuche es erneut.');
                this.uploadLoader = false;
            }
        } else {
            // save to attachment folder, will be synced lateron
            try {
                await this.attachmentSrv.saveAttachmentToDiskPromise(signatureAttachment.filePath, this.signaturePad.toDataURL(), true);
                this.logger.log('[SIGNATURE COMPONENT] saved signature to disk', signatureAttachment.filePath);
                this.dialogSrv.close('job-signature-' + this.uniqueId);
                this.signatureConfirmed.emit(signatureAttachment);
            } catch (err) {
                this.logger.error('[SIGNATURE COMPONENT] could not save signature to disk', err);
                this.utils.sentryCaptureException(err);
            }
        }
    }
}
