/**
 * The model for user license types
 */
export class LicenseType {
    name: string; // type internal name
    displayName: string; // type display name
    rights: UserRights; // rights for this role
    defaultRole: boolean; // if this type is a dokupit default role (can not be deleted)
    viewer: boolean; // if the license is a viewer license (free)

    constructor(data?: any) {
        this.name = null;
        this.displayName = '';
        this.defaultRole = false;
        this.viewer = false;
        this.rights = new UserRights();

        if (data != null) {
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.displayName != null) {
                this.displayName = data.displayName;
            }
            if (data.defaultRole != null) {
                this.defaultRole = data.defaultRole;
            }
            if (data.viewer != null) {
                this.viewer = data.viewer;
            }
            if (data.rights != null) {
                this.rights = new UserRights(data.rights, this.name);
            } else {
                this.rights = new UserRights(null, this.name); // maybe data.rights is null, but data.name wasnt, so init again
            }
        }

        // set defaultRole & viewer property for our default license types
        if (this.name === 'admin' || this.name === 'mobile') {
            this.defaultRole = true;
            this.viewer = false;
        } else if (this.name === 'viewer') {
            this.defaultRole = true;
            this.viewer = true;
        }
    }
}

export class UserRights {
    canEditCompanyProfile: boolean;
    canEditCompanySoftwareSettings: boolean;
    canEditLicenses: boolean;
    canSeeShop: boolean;
    canEditGlobalServiceGroups: boolean;
    canManageProjects: boolean;
    canEditProjectDocuments: boolean;
    canEditProjectPhoto: boolean;
    canArchiveOrDeleteProjects: boolean;
    canCreateReports: boolean;
    canSeeCreatedReports: boolean;
    canDeleteCreatedReports: boolean;
    canCreateAccounting: boolean;
    canEditReportTemplates: boolean;
    canEditAccountingTemplates: boolean;
    canManageCalendar: boolean;
    canAnalyzeTimetracking: boolean;
    canSeeProjectPlansView: boolean;
    canSeePhotoDate: boolean;
    canSeeDashboardTodayStatistics: boolean;
    canSeeCompanyWideNotifications: boolean;
    canSeeCalendar: boolean;
    hasAccessToAllProjects: boolean;
    blockMobileAppLogout: boolean;

    constructor(data?: any, licenseTypeName?: string) {
        this.canEditCompanyProfile = licenseTypeName === 'admin' ? true : false;
        this.canEditCompanySoftwareSettings = licenseTypeName === 'admin' ? true : false;
        this.canEditLicenses = licenseTypeName === 'admin' ? true : false;
        this.canSeeShop = licenseTypeName === 'admin' ? true : false;
        this.canEditGlobalServiceGroups = licenseTypeName === 'admin' ? true : false;
        this.canManageProjects = licenseTypeName === 'admin' ? true : false;
        this.canEditProjectDocuments = licenseTypeName === 'admin' ? true : false;
        this.canEditProjectPhoto = licenseTypeName === 'admin' ? true : false;
        this.canArchiveOrDeleteProjects = licenseTypeName === 'admin' ? true : false;
        this.canCreateReports = licenseTypeName === 'admin' ? true : false;
        this.canSeeCreatedReports = licenseTypeName === 'admin' ? true : false;
        this.canDeleteCreatedReports = licenseTypeName === 'admin' ? true : false;
        this.canCreateAccounting = licenseTypeName === 'admin' ? true : false;
        this.canEditReportTemplates = licenseTypeName === 'admin' ? true : false;
        this.canEditAccountingTemplates = licenseTypeName === 'admin' ? true : false;
        this.canManageCalendar = this.canManageProjects;
        this.canAnalyzeTimetracking = licenseTypeName === 'admin' ? true : false;
        this.canSeeProjectPlansView = true;
        this.canSeePhotoDate = licenseTypeName === 'viewer' ? false : true;
        this.canSeeDashboardTodayStatistics = licenseTypeName === 'admin' ? true : false;
        this.canSeeCompanyWideNotifications = licenseTypeName === 'admin' ? true : false;
        this.canSeeCalendar = this.canManageProjects;
        this.hasAccessToAllProjects = false;
        this.blockMobileAppLogout = false;

        if (data != null) {
            if (data.canEditCompanyProfile != null) {
                this.canEditCompanyProfile = data.canEditCompanyProfile;
            }
            if (data.canEditCompanySoftwareSettings != null) {
                this.canEditCompanySoftwareSettings = data.canEditCompanySoftwareSettings;
            }
            if (data.canEditLicenses != null) {
                this.canEditLicenses = data.canEditLicenses;
            }
            if (data.canSeeShop != null) {
                this.canSeeShop = data.canSeeShop;
            } else {
                this.canSeeShop = data.canManageProjects || false; // not defined in data, so take default value from canManageProjects (as this property was introduced in a later update)
            }
            if (data.canEditGlobalServiceGroups != null) {
                this.canEditGlobalServiceGroups = data.canEditGlobalServiceGroups;
            }
            if (data.canManageProjects != null) {
                this.canManageProjects = data.canManageProjects;
            }
            if (data.canEditProjectDocuments != null) {
                this.canEditProjectDocuments = data.canEditProjectDocuments;
            } else {
                // not defined in data, so take default value from canManageProjects (as this property was introduced in a later update)
                this.canEditProjectDocuments = this.canManageProjects;
            }
            if (data.canEditProjectPhoto != null) {
                this.canEditProjectPhoto = data.canEditProjectPhoto;
            }
            if (data.canArchiveOrDeleteProjects != null) {
                this.canArchiveOrDeleteProjects = data.canArchiveOrDeleteProjects;
            }
            if (data.canCreateReports != null) {
                this.canCreateReports = data.canCreateReports;
            } else if (data.canCreateReportsOrAccounting != null) {
                this.canCreateReports = data.canCreateReportsOrAccounting; // if data.canCreateReports is not set, use value of .canCreateReportsOrAccounting as this property was renamed in a later update
            }
            if (data.canSeeCreatedReports != null) {
                this.canSeeCreatedReports = data.canSeeCreatedReports;
            } else if (data.canCreateReportsOrAccounting != null) {
                this.canSeeCreatedReports = data.canCreateReportsOrAccounting; // if data.canSeeCreatedReports is not set, use value of .canCreateReportsOrAccounting as this property was renamed in a later update
            }
            if (data.canDeleteCreatedReports != null) {
                this.canDeleteCreatedReports = data.canDeleteCreatedReports;
            } else if (data.canCreateReportsOrAccounting != null) {
                this.canDeleteCreatedReports = data.canCreateReportsOrAccounting; // if data.canDeleteCreatedReports is not set, use value of .canCreateReportsOrAccounting as this property was renamed in a later update
            }
            if (data.canCreateAccounting != null) {
                this.canCreateAccounting = data.canCreateAccounting;
            } else if (data.canCreateReportsOrAccounting != null) {
                this.canCreateAccounting = data.canCreateReportsOrAccounting; // if data.canCreateAccounting is not set, use value of .canCreateReportsOrAccounting as this property was renamed in a later update
            }
            if (data.canEditReportTemplates != null) {
                this.canEditReportTemplates = data.canEditReportTemplates;
            } else {
                // not defined in data, so take default value from canCreateReportsOrAccounting (as this property was introduced in a later update)
                this.canEditReportTemplates = data.canCreateReportsOrAccounting;
            }
            if (data.canEditAccountingTemplates != null) {
                this.canEditAccountingTemplates = data.canEditAccountingTemplates;
            } else {
                // not defined in data, so take default value from canCreateReportsOrAccounting (as this property was introduced in a later update)
                this.canEditAccountingTemplates = data.canCreateReportsOrAccounting;
            }
            if (data.canManageCalendar != null) {
                this.canManageCalendar = data.canManageCalendar;
            } else {
                this.canManageCalendar = data.canManageProjects || false; // not defined in data, so take default value from canManageProjects (as this property was introduced in a later update)
            }
            if (data.canAnalyzeTimetracking != null) {
                this.canAnalyzeTimetracking = data.canAnalyzeTimetracking;
            }
            if (data.canSeeProjectPlansView != null) {
                this.canSeeProjectPlansView = data.canSeeProjectPlansView;
            }
            if (data.canSeePhotoDate != null) {
                this.canSeePhotoDate = data.canSeePhotoDate;
            }
            if (data.canSeeDashboardTodayStatistics != null) {
                this.canSeeDashboardTodayStatistics = data.canSeeDashboardTodayStatistics;
            }
            if (data.canSeeCompanyWideNotifications != null) {
                this.canSeeCompanyWideNotifications = data.canSeeCompanyWideNotifications;
            }
            if (data.canSeeCalendar != null) {
                this.canSeeCalendar = data.canSeeCalendar;
            } else {
                this.canSeeCalendar = data.canManageProjects || false; // not defined in data, so take default value from canManageProjects (as this property was introduced in a later update)
            }
            if (data.hasAccessToAllProjects != null) {
                this.hasAccessToAllProjects = !!data.hasAccessToAllProjects;
            }
            if (data.blockMobileAppLogout != null) {
                this.blockMobileAppLogout = !!data.blockMobileAppLogout;
            }
        }
    }
}
