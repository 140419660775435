<pintura-editor
    [src]="src"
    [options]="pinturaOpts"
    (load)="handleLoad($event)"
    (loaderror)="handleLoadError($event)"
    (close)="handleClose($event)"
    (processstart)="handleStartProcessing()"
    (process)="handleProcess($event)"
    #editor
></pintura-editor>
