<ng-container *ngIf="isVisible">
    <div
        class="dp-dialog-content"
        [ngClass]="{
            'no-separator': noSeparator === true,
            'dp-dialog-fullscreen': fullscreen === true,
            'dp-dialog-fullwidth': fullWidth === true && !fullscreen,
        }"
    >
        <div
            class="dp-dialog-header"
            [ngClass]="{
                'no-padding': noPadding === true,
                'ion-hide': !showHeader,
            }"
        >
            <ion-toolbar [class.no-close-button]="hideCloseBtn" #headerToolbar>
                <ng-content select="[dialog-header]"></ng-content>

                <ion-buttons slot="end" *ngIf="!hideCloseBtn || fullscreenToggleable">
                    <ion-button fill="clear" shape="round" color="primary" (click)="toggleFullscreen()">
                        <ng-container *ngIf="fullscreenToggleable">
                            <fa-icon slot="icon-only" icon="arrows-maximize" *ngIf="!fullscreen"></fa-icon>
                            <fa-icon slot="icon-only" icon="arrows-minimize" *ngIf="fullscreen"></fa-icon>
                        </ng-container>
                    </ion-button>
                    <ion-button fill="clear" shape="round" color="primary" (click)="close()">
                        <fa-icon slot="icon-only" icon="times"></fa-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </div>
        <div
            class="dp-dialog-body"
            [ngClass]="{
                'dp-dialog-small': small === true,
                'no-padding': noPadding === true,
            }"
        >
            <ng-content></ng-content>
        </div>
        <div
            class="dp-dialog-footer"
            [ngClass]="{
                'no-padding': noPadding === true,
            }"
        >
            <ng-content select="[dialog-footer]"></ng-content>
        </div>
        <div *ngIf="!dismissable" class="dialog-top-right"></div>
    </div>
</ng-container>
