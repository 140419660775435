import { Attachment } from "../../../core/attachment/attachment.model";

export class PlanAttachment extends Attachment {
	original?: string;
	width: number;
	height: number;
	jpeg?: TileData;
	webp?: TileData;

	constructor(data?: any) {
		super(data);

		this.width = 0;
		this.height = 0;

		if (data != null) {
			if (data.original != null) {
				this.original = data.original;
			}
			if (data.width != null) {
				this.width = data.width;
			}
			if (data.height != null) {
				this.height = data.height;
			}
			if (data.jpeg != null) {
				this.jpeg = data.jpeg;
			}
			if (data.webp != null) {
				this.webp = data.webp;
			}
		}
	}
}

export interface TileData {
	archiveName: string; // path and name of tile archive in context of the enclosing attachment's path
	maxZoom: number; // maximum level of zoom for these tiles
	tileSize: number; // size in px of these tiles
}
