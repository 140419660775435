import { BaseItem } from "../core/db/base-item.model";

export class ProjectSharing extends BaseItem {
	projectId: string;
	projectSharedWith: string;
	projectPermissions: ProjectPermissions;

	constructor(data?: any) {
		super(data, false);

		this.type = "project-sharing";
		this.projectId = "";
		this.projectSharedWith = "";
		this.projectPermissions = new ProjectPermissions();

		if (data != null) {
			if (data.type != null) {
				this.type = data.type;
			}
			if (data.projectId != null) {
				this.projectId = data.projectId;
			}
			if (data.projectSharedWith != null) {
				this.projectSharedWith = data.projectSharedWith;
			}
			if (data.projectPermissions != null) {
				this.projectPermissions = new ProjectPermissions(data.projectPermissions);
			}
		}

		// add project-sharing prefix to pk
		if (this.pk != null && !this.pk.startsWith("project-sharing#")) {
			this.pk = "project-sharing#" + this._id;
			this.sk = "project-sharing#" + this._id;
		}
	}
}

export class ProjectPermissions {
	visibleServiceGroups: Array<string>;
	workableServiceGroups: Array<string>;
	viewerWorkableServiceGroups: Array<string>;

	constructor(data?: any) {
		this.visibleServiceGroups = [];
		this.workableServiceGroups = [];
		this.viewerWorkableServiceGroups = [];

		if (data != null) {
			if (data.visibleServiceGroups != null) {
				this.visibleServiceGroups = data.visibleServiceGroups;
			}
			if (data.workableServiceGroups != null) {
				this.workableServiceGroups = data.workableServiceGroups;
			}
			if (data.viewerWorkableServiceGroups != null) {
				this.viewerWorkableServiceGroups = data.viewerWorkableServiceGroups;
			}
		}
	}
}
