import { Injectable } from '@angular/core';
import { LoggerService } from '../core/logger/logger.service';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { NotificationService } from '../core/notification/notification.service';
import { Storage } from '@ionic/storage-angular';
import { DbService } from '../core/db/db.service';
import { AttachmentService } from '../core/attachment/attachment.service';
import { HttpClient } from '@angular/common/http';
import { CompanyFeatures, CompanySettings } from '../core/shared-models/company.model';
import { UtilsService } from '../core/utils/utils.service';
import { AuthService } from '../auth/auth.service';
import { AuthInfo } from '../auth/auth-info.interface';
import { Address } from '../core/shared-models/address.model';
import { ContactInfo } from '../core/shared-models/contact-info.model';
import { Attachment } from '../core/attachment/attachment.model';
import { SyncResponse } from '../core/sync/sync-response.interface';
import { UserSettings } from '../core/shared-models/user.model';
import { LicenseType, UserRights } from '../core/shared-models/license-type.model';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    // auth info
    latestAuthInfo: AuthInfo;
    authInfoSubscription: Subscription;

    photoDocumentationOnly = this.authSrv.photoDocumentationOnly;

    currentUserRights: UserRights = new UserRights();
    currentCompanyFeatures: CompanyFeatures = new CompanyFeatures();

    constructor(
        private router: Router,
        private storage: Storage,
        private db: DbService,
        private attSrv: AttachmentService,
        private authSrv: AuthService,
        private http: HttpClient,
        private logger: LoggerService,
        private utils: UtilsService
    ) {
        this.initStorage();
        this.authInfoSubscription = this.authSrv.authInfoChanged$.subscribe((authInfo) => {
            this.latestAuthInfo = this.utils.clone(authInfo);

            if (this.latestAuthInfo != null) {
                let currentUserLicenseType = this.latestAuthInfo.companyData.availableLicenseTypes.find(
                    (x) => x.name === this.latestAuthInfo.userData.licenseType
                );
                if (currentUserLicenseType != null) {
                    this.currentUserRights = currentUserLicenseType.rights;
                }
                this.photoDocumentationOnly = this.authSrv.photoDocumentationOnly;

                this.currentCompanyFeatures = this.latestAuthInfo.companyData.companyFeatures;
                this.utils.latestCompanySettings = this.getCompanySettings();
            }
        });
    }

    async initStorage() {
        // If using a custom driver:
        // await this.storage.defineDriver(MyCustomDriver)
        await this.storage.create();
    }

    /**
     * Tries to update the company info / settings in the online db. Sends an direct request to the dynamoDB, so it fails without network connection
     * Returns an observable
     */
    public updateCompanyInfoAndSettings(params: {
        displayName?: string;
        address?: Address;
        contactInfo?: ContactInfo;
        logo?: Attachment;
        companySettings?: CompanySettings;
        availableLicenseTypes?: Array<LicenseType>;
    }) {
        return new Observable((observer) => {
            this.logger.log('[SETTINGS SERVICE] trying to update company info and settings');

            let companyData = this.utils.clone(this.authSrv.authInfo.companyData);

            if (params.displayName != null) {
                companyData.displayName = params.displayName;
            }
            if (params.address != null) {
                companyData.address = params.address;
            }
            if (params.contactInfo != null) {
                companyData.contactInfo = params.contactInfo;
            }
            if (params.logo != null) {
                companyData.logo = params.logo;
            }
            if (params.companySettings != null) {
                companyData.companySettings = params.companySettings;
            }
            if (params.availableLicenseTypes != null) {
                companyData.availableLicenseTypes = params.availableLicenseTypes;
            }

            this.http.put(environment.AWS_API_URL + '/company/company-info-settings', { companyData: companyData }).subscribe(
                (res) => {
                    // settings updated, tell authService to refresh all company data from server
                    this.logger.log('[SETTINGS SERVICE] company info and settings updated successfully');
                    this.authSrv
                        .startLoginWithTokens()
                        .then(() => {
                            observer.next(res);
                            observer.complete();
                        })
                        .catch((sessionErr) => {
                            observer.error(sessionErr);
                            observer.complete();
                        });
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    /**
     * Tries to update the user settings in the online db. Sends an direct request to the dynamoDB, so it fails without network connection
     * Returns an observable
     */
    public updateUserSettings(userSettings: UserSettings) {
        return new Observable((observer) => {
            this.logger.log('[SETTINGS SERVICE] trying to update user settings', userSettings);

            this.http
                .put(environment.AWS_API_URL + '/user/user-settings', {
                    pk: this.authSrv.authInfo.userData.pk,
                    sk: this.authSrv.authInfo.userData.sk,
                    userSettings: userSettings,
                })
                .subscribe(
                    (res) => {
                        // settings updated, tell authService to refresh all auth info data from server
                        this.logger.log('[SETTINGS SERVICE] user settings updated successfully');
                        this.authSrv
                            .startLoginWithTokens()
                            .then(() => {
                                observer.next(res);
                                observer.complete();
                            })
                            .catch((sessionErr) => {
                                observer.error(sessionErr);
                                observer.complete();
                            });
                    },
                    (err) => {
                        observer.error(err);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Gets current company settings
     */
    public getCompanySettings() {
        return this.latestAuthInfo.companyData.companySettings;
    }

    /**
     * Gets current user settings
     */
    public getUserSettings() {
        return this.latestAuthInfo.userData.userSettings;
    }

    /**
     * Gets current user rights based on licenseType
     */
    public getUserRights() {
        return this.currentUserRights;
    }

    /**
     * Gets current company features
     */
    public getCompanyFeatures() {
        return this.currentCompanyFeatures;
    }
}
