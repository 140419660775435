// import { Deploy } from 'cordova-plugin-ionic/dist/ngx';

import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { QuillModule } from 'ngx-quill';
import { NgSelectModule } from '@ng-select/ng-select';
import { IonicStorageModule } from '@ionic/storage-angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// https://angular.io/guide/i18n#i18n-pipes
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeTurk from '@angular/common/locales/tr';

/**
 * Initialize Sentry for error tracking
 * --- Start
 */
import { ApiAuthenticationInterceptor } from './core/http-interceptors/api-auth-interceptor';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import * as SentryAngular from '@sentry/angular-ivy';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxEditorModule } from 'ngx-editor';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        CoreModule,
        FontAwesomeModule,
        SweetAlert2Module.forRoot(),
        LeafletModule,
        QuillModule.forRoot(),
        NgSelectModule,
        MatMomentDateModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        MsalModule.forRoot(
            new PublicClientApplication({
                // MSAL Configuration
                auth: {
                    clientId: environment.AZ_CLIENT_ID,
                    authority: environment.AZ_AUTHORITY,
                    redirectUri: '/auth',
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage,
                    storeAuthStateInCookie: true, // set to true for IE 11
                },
                system: {
                    allowNativeBroker: false,
                    loggerOptions: {
                        loggerCallback: () => {},
                        piiLoggingEnabled: false,
                    },
                },
            }),
            {
                interactionType: InteractionType.Popup, // MSAL Guard Configuration
            },
            { interactionType: InteractionType.Popup, protectedResourceMap: new Map() }
        ),
        NgxEditorModule.forRoot({
            locals: {
                bold: 'Fett',
                italic: 'Kursiv',
                code: 'Code',
                underline: 'Unterstrichen',
                strike: 'Durchgestrichen',
                blockquote: 'Zitat',
                bullet_list: 'Liste ungeordnet',
                ordered_list: 'Liste geordnet',
                heading: 'Überschrift',
                h1: 'Überschrift 1',
                h2: 'Überschrift 2',
                h3: 'Überschrift 3',
                h4: 'Überschrift 4',
                h5: 'Überschrift 5',
                h6: 'Überschrift 6',
                align_left: 'Links ausrichten',
                align_center: 'Mittig ausrichten',
                align_right: 'Rechts ausrichten',
                align_justify: 'Blocksatz',
                text_color: 'Textfarbe',
                background_color: 'Hintergrundfarbe',
                insertLink: 'Insert Link',
                removeLink: 'Remove Link',
                insertImage: 'Insert Image',
                url: 'URL',
                text: 'Text',
                openInNewTab: 'Neuer Tab',
                insert: 'Einfügen',
                altText: 'Alt Text',
                title: 'Titel',
                remove: 'Entfernen',
            },
        }),
        EditorModule,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler() },
        { provide: SentryAngular.TraceService, deps: [Router] },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [SentryAngular.TraceService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ApiAuthenticationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'de-AT' },
        DatePipe,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
    constructor(library: FaIconLibrary, faConfig: FaConfig) {
        // configure fontawesome
        library.addIconPacks(far, fal, fab);
        faConfig.fixedWidth = true;
        faConfig.defaultPrefix = 'far';

        // Register Angular Locales
        registerLocaleData(localeEn, 'en');
        registerLocaleData(localeDe, 'de');
        registerLocaleData(localeTurk, 'tr');
    }
}
