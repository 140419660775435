import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
	selector: "[dpIsLoading]",
})
export class IsLoadingDirective implements OnChanges {
	@Input() dpIsLoading: boolean;

	constructor(private el: ElementRef) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.dpIsLoading != null) {
			if (this.dpIsLoading === true) {
				this.el.nativeElement.classList.add("dp-is-loading");
			} else {
				this.el.nativeElement.classList.remove("dp-is-loading");
			}
		}
	}
}
