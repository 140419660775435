export class Address {
    companyName?: string;
    street: string;
    zip: string;
    city: string;
    country: string;

    constructor(data?: any, removeCompanyNameProperty = false) {
        if (!removeCompanyNameProperty) {
            this.companyName = '';
        }
        this.street = '';
        this.zip = '';
        this.city = '';
        this.country = '';

        if (data != null) {
            if (!removeCompanyNameProperty && data.companyName != null) {
                this.companyName = data.companyName;
            }
            if (data.street != null) {
                this.street = data.street;
            }
            if (data.zip != null) {
                this.zip = data.zip;
            }
            if (data.city != null) {
                this.city = data.city;
            }
            if (data.country != null) {
                this.country = data.country;
            }
        }
    }
}
