import { LoggerService } from './../logger/logger.service';
import { NotificationService } from './../notification/notification.service';
import { SyncService } from './../sync/sync.service';
import { UtilsService } from './../utils/utils.service';
import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonMenu } from '@ionic/angular';
import { SettingsService } from '../../settings/settings.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
    isAdmin = false;
    isViewer = false;
    isBetaVersion = this.utils.isBetaVersion;
    isDevVersion = !environment.production;
    versionInfo = this.utils.versionInfo;
    showLogoutBtn = false;
    disable = false;

    @ViewChild('mobileAppMenu') appMobileMenu: IonMenu;

    constructor(
        public authSrv: AuthService,
        public utils: UtilsService,
        private syncSvc: SyncService,
        private notifySvc: NotificationService,
        public settingsSrv: SettingsService,
        private logger: LoggerService
    ) {}

    ngOnInit() {
        this.authSrv.authInfoChanged$.subscribe((authInfo) => {
            if (authInfo) {
                this.isAdmin = this.authSrv.isAdminLicense;
                this.isViewer = this.authSrv.isViewerLicense;
                this.showLogoutBtn = !this.settingsSrv.getUserRights().blockMobileAppLogout;
            }
        });

        this.utils.tabLock.subscribe((lockState) => {
            this.disable = !!lockState;
        });
    }

    onLogout() {
        this.syncSvc.hasUnsyncedLocalData().subscribe(
            async (hasLocalData) => {
                if (hasLocalData) {
                    this.logger.log('[MOBILE MENU] Local data remains. Asking user whether to proceed.');
                    try {
                        await this.notifySvc.confirm(
                            'Es befinden sich Daten auf dem Mobilgerät, die noch nicht hochgeladen wurden. Wenn du fortsetzt werden diese unwiderruflich gelöscht!',
                            'Datenabgleich unvollständig',
                            'Abmelden und löschen',
                            'Abbrechen',
                            true
                        );

                        this.authSrv.logout();
                    } catch (promptCancel) {
                        this.logger.log('[MOBILE MENU] User cancelled on unsynced local data warning');
                    }
                } else {
                    this.logger.log('[MOBILE MENU] No local data remains. Starting logout...');
                    this.authSrv.logout();
                }
            },
            (err) => {
                this.logger.error('[MOBILE MENU] Error while checking remaining local data');
                this.notifySvc.error(
                    'Beim Prüfen des Abmeldevorgangs ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
                    'Fehler',
                    undefined,
                    false
                );
                this.utils.sentryCaptureException(err);
            }
        );
    }

    onToggleMobileMenu() {
        this.appMobileMenu.toggle();
    }

    handleClose() {
        this.utils.triggerMobileMainMenuStatus('close');
    }

    handleOpen() {
        this.utils.triggerMobileMainMenuStatus('open');
    }
}
