import { Component, OnInit } from "@angular/core";
import { SyncService } from "../sync.service";
import { SyncInfoInterface } from "../sync-info.interface";

@Component({
	selector: "app-sync-status",
	templateUrl: "./sync-status.component.html",
	styleUrls: ["./sync-status.component.scss"],
})
export class SyncStatusComponent implements OnInit {
	syncStatus: boolean | "downstream" | "upstream" | "cleanup";
	syncInfo: SyncInfoInterface = {
		currentRunUpstreamCount: 0,
		currentRunDownstreamCount: 0,
		totalUpstreamCount: 0,
		totalDownstreamCount: 0,
		lastUpstreamStartTimestamp: 0,
		lastDownstreamStartTimestamp: 0,
		currentSyncWarning: null,
	};

	constructor(private syncSrv: SyncService) {}

	ngOnInit() {
		// subscribe to sync status changes
		this.syncSrv.syncRunning$.subscribe((syncStatus) => {
			this.syncStatus = syncStatus;
		});
		this.syncSrv.syncInfo$.subscribe((syncInfo) => {
			this.syncInfo = syncInfo;
		});
	}

	/**
	 * Triggered when the debug upstream sync button is pressed
	 */
	onStartDebugUpstream() {
		this.syncSrv.syncUpstream();
	}

	/**
	 * Triggered when the debug downstream sync button is pressed
	 */
	onStartDebugDownstream() {
		this.syncSrv.syncDownstream();
	}
}
