import { BaseItem } from '../../core/db/base-item.model';
import { Field } from './article-details/field/field.model';
import { Attachment } from '../../core/attachment/attachment.model';
import { ServiceGroupPhotoCategory } from '../service-group.model';
import { JobPhoto } from '../../projects/project-details/project-jobs/job.model';

export class Article extends BaseItem {
    name: string;
    articleNumber: string;
    articleShortText: string;
    icon: {
        iconName: string;
        iconType: 'dp' | 'fa' | 'dpimg';
    };
    fields: Array<Field>;
    fieldDisplayType: 'static' | 'dynamic';
    photos: Array<JobPhoto>;
    photoCategories: Array<ServiceGroupPhotoCategory>;
    pricing: ArticlePricing;
    documents: Array<Attachment>;
    allowedLicenseTypes: Array<string>;
    articleAddedNotificationEnabled: boolean; // send notification if this article is added to a job and this option is true
    articleReminderEnabled: boolean; // send notification after defined time if this article is added to a job and this option is true
    articleReminderDuration: number;
    articleReminderDurationType: 'days' | 'weeks' | 'months' | 'years';
    articleReminderName: string;
    skipAutoCreation: boolean; // if true this article won't be automatically created on service group addition to project

    constructor(data?: any, removePropertiesForNesting = false) {
        super(data, removePropertiesForNesting);

        this.type = 'article';
        this.name = '';
        this.articleNumber = '';
        this.articleShortText = '';
        this.icon = {
            iconName: null,
            iconType: null,
        };
        this.fields = [];
        this.fieldDisplayType = 'static';
        this.photos = [];
        this.photoCategories = [];
        this.pricing = new ArticlePricing();
        this.documents = [];
        this.allowedLicenseTypes = ['mobile'];
        this.articleAddedNotificationEnabled = false;
        this.articleReminderEnabled = false;
        this.articleReminderDuration = 1;
        this.articleReminderDurationType = 'years';
        this.articleReminderName = '';

        if (data != null) {
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.articleNumber != null) {
                this.articleNumber = data.articleNumber;
            }
            if (data.articleShortText != null) {
                this.articleShortText = data.articleShortText;
            }
            if (data.icon != null) {
                if (typeof data.icon === 'string') {
                    let iconType = null;
                    if (data.icon.startsWith('assets/icon/colorable')) {
                        iconType = 'dp';
                    } else if (data.icon.startsWith('assets/icon')) {
                        iconType = 'dpimg';
                    } else {
                        iconType = 'fa';
                    }
                    this.icon = {
                        iconName: data.icon,
                        iconType: iconType,
                    };
                } else if (data.icon.icon != null) {
                    // map old deprecated data
                    let iconType = null;
                    if (data.icon.icon.startsWith('assets/icon/colorable')) {
                        iconType = 'dp';
                    } else if (data.icon.icon.startsWith('assets/icon')) {
                        iconType = 'dpimg';
                    } else {
                        iconType = 'fa';
                    }
                    this.icon = {
                        iconName: data.icon.icon,
                        iconType: iconType,
                    };
                } else {
                    this.icon = data.icon;
                }
            }
            if (data.fields != null) {
                this.fields = data.fields.map((x) => new Field(x));
            }
            if (data.fieldDisplayType != null) {
                this.fieldDisplayType = data.fieldDisplayType;
            }
            if (data.photos != null) {
                this.photos = data.photos.map((x) => new JobPhoto(x));
            }
            if (data.photoCategories != null) {
                this.photoCategories = data.photoCategories.map((x) => new ServiceGroupPhotoCategory(x));
            }
            if (data.pricing != null) {
                this.pricing = new ArticlePricing(data.pricing);
            }
            if (data.documents != null) {
                this.documents = data.documents.map((x) => new Attachment(x));
            }
            if (data.allowedLicenseTypes != null) {
                this.allowedLicenseTypes = data.allowedLicenseTypes;
            }
            this.articleAddedNotificationEnabled = data.articleAddedNotificationEnabled === true;
            this.articleReminderEnabled = data.articleReminderEnabled === true;

            if (data.articleReminderDuration != null) {
                this.articleReminderDuration = data.articleReminderDuration;
            }
            if (data.articleReminderDurationType != null) {
                this.articleReminderDurationType = data.articleReminderDurationType;
            }
            if (data.articleReminderName != null) {
                this.articleReminderName = data.articleReminderName;
            }
            this.skipAutoCreation = !!data.skipAutoCreation;
        }
    }
}

export class ArticlePricing {
    pricingCalculations: Array<ArticlePricingCalculation>;

    constructor(data?: any) {
        this.pricingCalculations = [];

        if (data != null) {
            if (data.pricingCalculations != null) {
                this.pricingCalculations = data.pricingCalculations.map((x) => new ArticlePricingCalculation(x));
            }
        }
    }
}

export class ArticlePricingCalculation {
    name: string;
    pricingType: ArticlePricingType;
    formula: [];
    formulaPriceBasis: FormulaPriceBasis;
    formulaUnitPrice: number; // used if formulaPriceBasis is PricePerUnit
    formulaPricingTable: Array<{
        min: string;
        max: string;
        priceType: 'fixed' | 'unit';
        price: number;
        articleNumber: string;
        articleShortText: string;
    }>; // used if formulaPriceBasis is PricingTable
    variations: Array<ArticlePricingVariation>;
    fixedArticlePrice: number;
    fixedPriceArticleNumber: string;
    fixedPriceArticleShortText: string;
    unit: string;
    amountField: string;
    amountFormula: [];

    constructor(data?: any) {
        this.name = 'Preisberechnung';
        this.pricingType = ArticlePricingType.Formula;
        this.formula = [];
        this.formulaPriceBasis = FormulaPriceBasis.PricePerUnit;
        this.formulaUnitPrice = 0;
        this.formulaPricingTable = [];
        this.variations = [];
        this.fixedArticlePrice = 0;
        this.fixedPriceArticleNumber = '';
        this.fixedPriceArticleShortText = '';
        this.unit = '';
        this.amountField = null;
        this.amountFormula = [];

        if (data != null) {
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.pricingType != null) {
                this.pricingType = data.pricingType;
            }
            if (data.formula != null) {
                this.formula = data.formula;
            }
            if (data.formulaPriceBasis != null) {
                this.formulaPriceBasis = data.formulaPriceBasis as FormulaPriceBasis;
            }
            if (data.formulaUnitPrice != null && data.formulaUnitPrice != '') {
                this.formulaUnitPrice = data.formulaUnitPrice;
            }
            if (data.formulaPricingTable != null) {
                this.formulaPricingTable = data.formulaPricingTable.map((x) => {
                    return {
                        min: x.min || '',
                        max: x.max || '',
                        priceType: x.priceType || 'fixed',
                        price: x.price || 0,
                        articleNumber: x.articleNumber || '',
                        articleShortText: x.articleShortText || '',
                    };
                });
            }
            if (data.variations != null) {
                this.variations = data.variations.map((x) => new ArticlePricingVariation(x));
            }
            if (data.fixedArticlePrice != null && data.fixedArticlePrice != '') {
                this.fixedArticlePrice = data.fixedArticlePrice;
            }
            if (data.fixedPriceArticleNumber != null) {
                this.fixedPriceArticleNumber = data.fixedPriceArticleNumber;
            }
            if (data.fixedPriceArticleShortText != null) {
                this.fixedPriceArticleShortText = data.fixedPriceArticleShortText;
            }
            if (data.unit != null) {
                this.unit = data.unit;
            }
            if (data.amountField != null) {
                this.amountField = data.amountField;
            }
            if (data.amountFormula != null) {
                this.amountFormula = data.amountFormula;
            }
        }
    }
}

export class ArticlePricingVariation {
    fieldId: string;
    fieldName: string;
    prices: Array<{
        fieldValue: string | number;
        price: number;
        articleNumber: string;
        articleShortText: string;
    }>;

    constructor(data?: any) {
        this.fieldId = '';
        this.fieldName = '';
        this.prices = [];

        if (data != null) {
            if (data.fieldId != null) {
                this.fieldId = data.fieldId;
            }
            if (data.fieldName != null) {
                this.fieldName = data.fieldName;
            }
            if (data.prices != null) {
                this.prices = data.prices.map((x) => {
                    return {
                        fieldValue: x.fieldValue || '',
                        price: x.price || 0,
                        articleNumber: x.articleNumber || '',
                        articleShortText: x.articleShortText || '',
                    };
                });
            }
        }
    }
}

export enum FormulaPriceBasis {
    FormulaResultIsPrice = 'result-is-price',
    PricingTable = 'pricing-table',
    PricePerUnit = 'price-per-unit',
}

export enum ArticlePricingType {
    Formula = 'formula',
    Variation = 'variation',
    Fixed = 'fixed',
}
