import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonInput, IonModal } from '@ionic/angular';
import { UtilsService } from '../utils/utils.service';
import { evaluate } from 'mathjs';

@Component({
    selector: 'app-calculator-keyboard',
    templateUrl: './calculator-keyboard.component.html',
    styleUrls: ['./calculator-keyboard.component.scss'],
})
export class CalculatorKeyboardComponent {
    constructor(public utils: UtilsService) {}

    @ViewChild(IonModal) modal: IonModal;
    @ViewChild('calculatorPreviewInput') calculatorPreviewInput: IonInput;

    @Input() fieldName = 'Rechnung';
    @Output() calculationChanged = new EventEmitter<string>();

    initialValue = '';
    calculatorCurrentResult: string | false = null;

    public async show(initialValue) {
        //this.inputFieldToUpdate = inputFieldToUpdate;
        //this.inputFieldToUpdate.focus();

        if (initialValue != null) {
            this.initialValue = initialValue;
        }
        await this.modal.present();
        let previewInputElem = await this.calculatorPreviewInput.getInputElement();
        previewInputElem.focus();
        if (this.initialValue != '') {
            previewInputElem.selectionStart = this.initialValue.length;
            previewInputElem.selectionEnd = previewInputElem.selectionStart;
        }
        this.onEvaluateCurrentCalculationResult(initialValue);
    }

    async onCalculatorButtonPress(key: string, pointerEvent) {
        pointerEvent.preventDefault(); // prevent default, to keep focus on input element
        pointerEvent.stopPropagation();
        pointerEvent.stopImmediatePropagation();
        let inputElem = await this.calculatorPreviewInput.getInputElement();
        let currentVal = inputElem.value || '';
        let cursorIndex = 0;
        if (inputElem.selectionStart) {
            cursorIndex = inputElem.selectionStart;
        } else if (currentVal != '') {
            cursorIndex = currentVal.length - 1;
        }

        if (key === 'BACKSPACE') {
            inputElem.value = inputElem.value.slice(0, cursorIndex - 1) + inputElem.value.slice(cursorIndex);
            inputElem.selectionStart = cursorIndex - 1; // set selection start to last index after changing input element value
            inputElem.selectionEnd = inputElem.selectionStart; // just set selectionEnd = selectionStart as we dont want to mark multiple chars and just set the cursor
            this.calculationChanged.next(inputElem.value);
        } else if (key === 'ENTER') {
            this.calculationChanged.next(inputElem.value);
            this.modal.dismiss();
        } else {
            inputElem.value = currentVal.substring(0, cursorIndex) + key + currentVal.substring(cursorIndex);
            inputElem.selectionStart = cursorIndex + 1; // set selection start to last index + 1 after changing input element value
            inputElem.selectionEnd = inputElem.selectionStart; // just set selectionEnd = selectionStart as we dont want to mark multiple chars and just set the cursor
            this.calculationChanged.next(inputElem.value);
        }
        this.onEvaluateCurrentCalculationResult(inputElem.value);
    }

    /**
     * Triggered when the calculator input changes and the math result shall be calculated
     * @param input the mathemtic input string from the input
     */
    onEvaluateCurrentCalculationResult(input) {
        if (input == null || input == '') {
            this.calculatorCurrentResult = null;
        } else {
            let inputVal = input.split(',').join('.');
            try {
                let calculatorResult = evaluate(inputVal);
                this.calculatorCurrentResult = parseFloat(calculatorResult).toFixed(2);
            } catch (evaluateErr) {
                this.calculatorCurrentResult = false;
            }
        }
    }
}
