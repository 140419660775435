import { BaseItem } from "../core/db/base-item.model";

export class Product extends BaseItem {
	name: string;
	unit: string;
	enableAmount: boolean;
	amount: number;
	icon: {
		iconName: string;
		iconType: "dp" | "fa" | "dpimg";
	};
	productNumber: string;
	price: number;
	createAccountingRowsForProduct: boolean;

	constructor(data?: any, removePropertiesForNesting = false) {
		super(data, removePropertiesForNesting);

		this.type = "product";
		this.name = "";
		this.unit = "";
		this.enableAmount = true;
		this.amount = null;
		this.icon = {
			iconName: null,
			iconType: null,
		};
		this.productNumber = "";
		this.price = 0;
		this.createAccountingRowsForProduct = true;

		if (data != null) {
			if (data.type != null) {
				this.type = data.type;
			}
			if (data.name != null) {
				this.name = data.name;
			}
			if (data.unit != null) {
				this.unit = data.unit;
			}
			if (data.enableAmount != null) {
				this.enableAmount = data.enableAmount;
			}
			if (data.amount != null) {
				this.amount = data.amount;
			}
			if (data.icon != null) {
				this.icon = data.icon;
			}
			if (data.productNumber != null) {
				this.productNumber = data.productNumber;
			}
			if (data.price != null) {
				this.price = data.price;
			}
			if (data.createAccountingRowsForProduct != null) {
				this.createAccountingRowsForProduct = data.createAccountingRowsForProduct;
			}
		}

		// add product prefix to pk
		if (this.pk != null && !this.pk.startsWith("product#")) {
			this.pk = "product#" + this._id;
			this.sk = "product#" + this._id;
		}
	}
}
