import { PlanAttachment } from './plan-attachment.model';
import { BaseItem } from '../../../core/db/base-item.model';

export class BuildingPlan extends BaseItem {
    name: string;
    attachment: PlanAttachment;
    dataStatus: PlanStatus;
    errCode?: PlanErrCodes;
    projectId: string;
    legacyPlan = false;

    constructor(data?: any) {
        super(data, false);

        this.type = 'building-plan';
        this.name = '';
        this.projectId = '';
        this.attachment = null;

        if (data != null) {
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.attachment != null) {
                this.attachment = new PlanAttachment(data.attachment);
            }
            if (data.dataStatus != null) {
                this.dataStatus = data.dataStatus as PlanStatus;
            }
            if (data.projectId) {
                this.projectId = data.projectId;
            }
            if (data.legacyPlan) {
                this.legacyPlan = data.legacyPlan;
            }
        }

        // add article prefix to pk
        if (this.pk != null && !this.pk.startsWith('building-plan#')) {
            this.pk = 'building-plan#' + this._id;
            this.sk = 'building-plan#' + this._id;
        }
    }
}

// The stati of our plan processing on the server
// the listed order is as it progresses with availability
// Converting and Converted my be skipped if the source is a file format
// that needs no conversion
export enum PlanStatus {
    Pending = 'PENDING',
    Converting = 'CONVERTING',
    Converted = 'CONVERTED',
    Processing = 'PROCESSING',
    Done = 'DONE',
    Failed = 'FAILED',
}

// Error codes which can occur during preparation of plan data
export enum PlanErrCodes {
    PDF_NO_PAGES = 'PDF_NO_PAGES',
    PDF_EMPTY_FILE = 'PDF_EMPTY_FILE',
    PDF_ENCRYPTED = 'PDF_ENCRYPTED',
    PDF_INTERNAL_ERROR = 'PDF_INTERNAL_ERROR',
}
