<div class="signature-start-wrap ion-text-center">
  <ion-button (click)="onShowSignatureDialog()" size="small">
    <fa-icon icon="signature" slot="start"></fa-icon>
    Jetzt unterschreiben
  </ion-button>
</div>

<app-dialog
  class="signature-dialog"
  [dialogId]="'job-signature-' + uniqueId"
  [small]="true"
  [dismissable]="false"
  [hideCloseBtn]="true"
>
  <ng-container dialog-header>
    <h2 class="ion-text-center">Unterschrift</h2>
  </ng-container>

  <ion-card class="signature-name">
    <ion-card-content>
      <ion-item class="job-code" lines="none">
        <ion-label position="floating">Name</ion-label>
        <ion-input
          type="text"
          placeholder="Name eingeben"
          [(ngModel)]="signatoryName"
          [disabled]="preFilledSignatoryName != null"
        ></ion-input>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <div class="signature-canvas-wrap" #signatureCanvasWrap>
    <canvas class="signature-canvas" #signatureCanvas></canvas>
  </div>

  <ng-container dialog-footer>
    <ion-toolbar class="signature-canvas-actions" color="none">
      <ion-buttons slot="start">
        <ion-button size="small" color="danger" fill="clear" class="dp-mr-m" (click)="onClearSignature()">
          <fa-icon icon="signature" slot="start"></fa-icon>
          Feld leeren
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button size="small" fill="clear" color="warning" (click)="onCancelSignature()">
          <!-- <fa-icon icon="times" slot="start"></fa-icon> -->
          Abbrechen
        </ion-button>
        <ion-button fill="solid" color="success" (click)="onConfirmSignature()" [dpIsLoading]="uploadLoader">
          <fa-icon icon="check" slot="start"></fa-icon>
          Bestätigen
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ng-container>
</app-dialog>
