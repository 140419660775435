import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import Swal from 'sweetalert2';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private toasts: Array<HTMLIonToastElement> = [];

  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    private utils: UtilsService,
    private toastCtrl: ToastController
  ) {}

  /**
   * Notifies user with success message
   */
  success(msg: string, title?: string, toast = true, timer = 2000, dismissable = true) {
    if (this.utils.isPhone && toast) {
      this.showToast(msg, title, 'top', timer, 'dp-success-toast', 'success');
    } else {
      this.showSweetAlert({
        msg: msg,
        title: title,
        icon: 'success',
        toast: toast,
        timer: timer,
        dismissable: dismissable,
      });
    }
  }

  /**
   * Notifies user of given error
   */
  error(msg: string, title?: string, code?: string | number, toast = true, timer = 4000, dismissable = true) {
    if (code) {
      msg += '<hr>Fehlercode: ' + code;
    }

    if (this.utils.isPhone && toast) {
      this.showToast(msg, title, 'top', timer, 'dp-error-toast', 'error');
    } else {
      this.showSweetAlert({
        msg: msg,
        title: title,
        icon: 'error',
        toast: toast,
        timer: timer,
        dismissable: dismissable,
      });
    }
  }

  /**
   * Notifies user of given warning
   */
  warn(msg: string, title?: string, toast = true, timer = 5000, dismissable = true) {
    if (this.utils.isPhone && toast) {
      this.showToast(msg, title, 'top', timer, 'dp-warn-toast', 'warn');
    } else {
      this.showSweetAlert({
        msg: msg,
        title: title,
        icon: 'warning',
        toast: toast,
        timer: timer,
        dismissable: dismissable,
      });
    }
  }

  /**
   * Notifies user with info message
   */
  info(msg: string, title?: string, toast = true, timer = 5000, dismissable = true) {
    if (this.utils.isPhone && toast) {
      this.showToast(msg, title, 'top', timer, 'dp-info-toast', 'info');
    } else {
      this.showSweetAlert({
        msg: msg,
        title: title,
        icon: 'info',
        toast: toast,
        timer: timer,
        dismissable: dismissable,
      });
    }
  }

  /**
   * Shows confirmation dialog. Returns promise which resolves when the user confirms the action
   */
  confirm(msg: string, title?: string, confirmText = 'OK', cancelText = 'Abbrechen', destructive = false) {
    return new Promise<boolean>((resolve, reject) => {
      if (this.utils.isNative) {
        this.alertController
          .create({
            message: msg,
            header: title,
            cssClass: 'dp-confirm-dialog',
            buttons: [
              {
                text: confirmText,
                role: destructive ? 'destructive' : 'confirm',
                handler: () => {
                  console.log('Confirm dialog accepted');
                  resolve(true);
                },
              },
              {
                text: cancelText,
                role: 'cancel',
                handler: () => {
                  console.log('Confirm dialog canceled');
                  reject();
                },
              },
            ],
          })
          .then((alert) => {
            alert.present();
          });
      } else {
        Swal.fire({
          title: title,
          html: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: confirmText,
          cancelButtonText: cancelText,
          confirmButtonColor: destructive ? '#eb445a' : '#2092c9',
          cancelButtonColor: '#ffc409',
          heightAuto: false, // for ionic compatibility
        })
          .then((res) => {
            if (res.isConfirmed) {
              resolve(true);
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      }
    });
  }

  /**
   * Shows a prompt with 3 buttons
   * @returns a promise which either rejects on cancel and dismiss or resolves with a boolean either true on confirm or false on deny
   */
  tripleConfirm(
    msg: string,
    title?: string,
    confirmText = 'Ok',
    denyText = 'Nicht Ok',
    cancelText = 'Abbrechen',
    confirmDestructive = false,
    denyDestructive = false
  ) {
    return new Promise((resolve, reject) => {
      if (this.utils.isNative) {
        this.alertController
          .create({
            message: msg,
            header: title,
            cssClass: 'dp-confirm-dialog',
            buttons: [
              {
                text: confirmText,
                role: confirmDestructive ? 'destructive' : undefined,
                handler: () => {
                  console.log('Multi prompt Confirm');
                  resolve(true);
                },
              },
              {
                text: denyText,
                role: denyDestructive ? 'destructive' : undefined,
                handler: () => {
                  console.log('Multi prompt denied');
                  resolve(false);
                },
              },
              {
                text: cancelText,
                role: 'cancel',
                handler: () => {
                  console.log('Multi prompt cancelled');
                  reject();
                },
              },
            ],
          })
          .then((alert) => {
            alert.present();
          });
      } else {
        Swal.fire({
          title: title,
          html: msg,
          icon: 'warning',
          showCancelButton: true,
          showDenyButton: true,
          showConfirmButton: true,
          confirmButtonText: confirmText,
          cancelButtonText: cancelText,
          denyButtonText: denyText,
          confirmButtonColor: confirmDestructive ? '#eb445a' : '#2092c9',
          denyButtonColor: denyDestructive ? '#eb445a' : '#2092c9',
          cancelButtonColor: '#ffc409',
          heightAuto: false, // for ionic compatibility
        })
          .then((res) => {
            if (res.isConfirmed) {
              resolve(true);
            }
            if (res.isDenied) {
              resolve(false);
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      }
    });
  }

  /**
   * Shows prompt dialog with a single text input. Returns promise which resolves with the input value or undefined if empty. Cancel action rejects the promise
   */
  prompt(msg: string, title?: string, prefilledValue?: string, placeholder?: string) {
    return new Promise((resolve, reject) => {
      this.alertController
        .create({
          message: msg,
          header: title,
          cssClass: 'dp-confirm-dialog',
          inputs: [
            {
              name: 'text_input',
              type: 'text',
              value: prefilledValue,
              placeholder: placeholder,
            },
          ],
          buttons: [
            {
              text: 'OK',
              handler: (data) => {
                console.log('Prompt dialog accepted', data);
                resolve(data ? data.text_input : undefined);
              },
            },
            {
              text: 'Abbrechen',
              role: 'cancel',
              handler: () => {
                console.log('Prompt dialog canceled');
                reject();
              },
            },
          ],
        })
        .then(async (alert) => {
          await alert.present();

          // as we don't have a native API for triggering focus and keyups we do it ourselves
          let items = alert.getElementsByTagName('input');
          if (items.length > 0) {
            items[0].addEventListener('keyup', (event) => {
              // Number 13 is the "Enter" key on the keyboard
              if (event.key == 'Enter' || event.keyCode === 13) {
                // See above button handler in alert's buttons
                // we do the same here for correct further handling in app
                alert.dismiss({ text_input: items[0].value || undefined });
                resolve(items[0].value || undefined);
              }
            });
            items[0].focus();
          }
        });
    });
  }

  /**
   * Shows a sweet alert with given configuration
   */
  showSweetAlert(options: {
    msg: string;
    title: string;
    icon: 'success' | 'error' | 'warning' | 'info' | 'question';
    toast: boolean;
    timer: number;
    dismissable: boolean;
  }) {
    if (options.dismissable == null) {
      options.dismissable = true;
    }

    return Swal.fire({
      title: options.title,
      html: options.msg,
      icon: options.icon,
      toast: options.toast,
      timer: options.timer,
      timerProgressBar: true,
      showConfirmButton: false,
      allowOutsideClick: options.dismissable,
      position: this.utils.mobileViewActive ? 'top' : 'top-right',
      heightAuto: false, // for ionic compatibility
    });
  }

  /**
   * Closes the currently shown sweet alert
   */
  closeCurrentSweetAlert() {
    return Swal.close();
  }

  async showToast(
    message: string,
    header?: string,
    position: 'top' | 'bottom' | 'middle' = 'bottom',
    duration = 3000,
    id?: string,
    type: 'blank' | 'info' | 'error' | 'warn' | 'success' = 'blank'
  ) {
    let toastOpts = {
      header: header,
      message: message,
      duration: duration,
      position: position,
      cssClass: 'dp-toast',
      animated: true,
      translucent: false,
      color: 'light',
      buttons: [
        {
          side: 'end',
          text: 'OK',
          role: 'cancel',
        },
      ],
    } as any;

    // prepare extra data
    if (id) {
      toastOpts['id'] = id;
    }
    if (type != 'blank') {
      let iconBtn = 'alert-circle-outline';
      switch (type) {
        case 'error':
          toastOpts.color = 'danger';
          iconBtn = 'close-circle-outline';
          break;
        case 'warn':
          toastOpts.color = 'warning';
          iconBtn = 'warning-outline';
          break;
        case 'info':
          toastOpts.color = 'light';
          iconBtn = 'alert-circle-outline';
          break;
        case 'success':
          toastOpts.color = 'success';
          iconBtn = 'checkmark-circle-outline';
          break;
      }

      toastOpts.buttons.push({
        side: 'start',
        icon: iconBtn,
      });
    }

    let toast = await this.toastCtrl.create(toastOpts);

    if (id) {
      // when using an id only show them once
      let toastIndex = this.toasts.findIndex((t) => t.id == id);
      if (toastIndex != -1) {
        await this.toasts[toastIndex].dismiss();
      }
      this.toasts.push(toast);
      await toast.present();

      await toast.onDidDismiss();
      this.toasts = this.toasts.filter((t) => t.id != id);
      this.toasts = this.toasts.filter((t) => t.id != id);
    } else {
      await toast.present();
    }
  }

  async dismissToastsById(ids: Array<string>) {
    for (let id of ids) {
      let toast = this.toasts.find((t) => t.id == id);
      if (toast) {
        setTimeout(async (_) => {
          await toast.dismiss();
        }, 200);
      }
    }
  }
}
