<div class="attachment-select-wrapper">
    <ng-container *ngIf="dialog">
        <!-- Display button which opens dialog -->
        <ion-button [color]="btnColor" (click)="onShowFileSelectDialog()" expand="block">
            <fa-icon icon="upload" slot="start"></fa-icon>
            {{ buttonText != null ? buttonText : 'Datei hochladen' }}
        </ion-button>
        <app-dialog
            dialogId="att-select-dialog-{{ uniqueId }}"
            [small]="true"
            #fileSelectDialog
            [dismissable]="!currentlyUploading"
            [hideCloseBtn]="true"
        >
            <div class="file-select-in-dialog">
                <ng-container *ngTemplateOutlet="selectTemplate"></ng-container>
            </div>
        </app-dialog>
    </ng-container>

    <!-- Direct display without dialog -->
    <ng-container *ngIf="!dialog">
        <div class="file-select-heading">
            {{ buttonText }}
        </div>
        <ng-container *ngTemplateOutlet="selectTemplate"></ng-container>
    </ng-container>

    <!-- this template is shown in dialog or directly based on dialog input setting -->
    <ng-template #selectTemplate>
        <ng-container *ngIf="forceDirectUpload && currentlyUploading; else defaultView">
            <div class="file-select-heading ion-text-center dp-mt-s">
                <h3>Upload läuft ...</h3>
            </div>
            <div class="upload-progress-wrap dp-mb-s">
                <div class="progress-text ion-text-center">
                    {{ (uploadedBytes / (1024 * 1024)).toFixed(2) }} MB von {{ (totalBytesToUpload / (1024 * 1024)).toFixed(2) }} MB
                    hochgeladen
                </div>
                <ion-progress-bar color="primary" [value]="uploadedBytes / totalBytesToUpload"></ion-progress-bar>
            </div>
        </ng-container>
        <ng-template #defaultView>
            <div
                class="file-select-wrap"
                [ngClass]="{ 'file-over': fileOverDropZone }"
                (dragenter)="onDragEnter($event)"
                (dragleave)="onDragLeave($event)"
                (dragover)="onDragOver($event)"
                (drop)="!utils.isPhone && onDropFiles($event)"
            >
                <div class="file-select-choices">
                    <div class="file-select-choice library-select">
                        <div class="choice-icon">
                            <fa-icon [icon]="['fal', 'folders']"></fa-icon>
                        </div>
                        <div class="choice-title">Datei ausw.</div>
                        <input
                            type="file"
                            class="choose-file-input"
                            [multiple]="multipleFiles"
                            id="choose-file-input{{ uniqueId }}"
                            (change)="onFilesChosen($event.target.files)"
                            #fileSelectInput
                        />
                        <label for="choose-file-input{{ uniqueId }}" class="choose-file-input-label"></label>
                    </div>
                    <div class="file-select-choice open-camera" (click)="onTakePhoto()" *ngIf="allowedFileTypes.includes('image')">
                        <div class="choice-icon">
                            <fa-icon [icon]="['fal', 'camera']"></fa-icon>
                        </div>
                        <div class="choice-title">Kamera</div>
                    </div>
                </div>
                <div class="file-dropzone-info ion-text-center" *ngIf="!utils.isPhone">
                    <span>{{ fileOverDropZone ? 'Datei jetzt ablegen' : 'oder Dateien hierher ziehen' }}</span>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>
