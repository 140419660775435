import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subscription, from } from 'rxjs';
import { DbService } from '../core/db/db.service';
import { LoggerService } from '../core/logger/logger.service';
import { ItemChangeInfo } from '../core/db/item-change-info.interface';
import { Project, ProjectListEntry, ProjectStatus } from './project.model';
import { UtilsService } from '../core/utils/utils.service';
import { ServiceGroup } from '../service-groups/service-group.model';
import { Job, JobMarker, JobStatus } from './project-details/project-jobs/job.model';
import { NotificationService } from '../core/notification/notification.service';
import { AuthService } from '../auth/auth.service';
import { switchMap, takeWhile } from 'rxjs/operators';
import { BuildingPlan, PlanStatus } from './project-details/project-building-plans/building-plan.model';
import { Report } from './project-details/project-reports/report.model';
import { ProjectSharing } from './project-sharing.model';
import { SettingsService } from '../settings/settings.service';
import { SyncService } from '../core/sync/sync.service';
import { JobExtension } from './project-details/project-jobs/job-details/job-extension/job-extension.model';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { DpNotification } from '../core/shared-models/notification.model';
import { Article, ArticlePricing } from '../service-groups/articles/article.model';
import { MangoQuery } from 'rxdb';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export interface JobSearchResult {
    job: Job;
    project: Project;
    dataSource: 'local' | 'online';
}

export interface BasicJobInfo {
    _id: string;
    jobNumber: string;
    name: string;
    code: string;
    buildingPlanId: string;
    marker: Array<JobMarker>;
    projectId: string;
    serviceGroupId: string;
    jobStatus: JobStatus;
}

export interface BasicJobListInfo extends BasicJobInfo {
    serviceGroupName?: string;
    serviceGroupChangesAllowed?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    public projects: Array<ProjectListEntry> = []; // holds all projects (or project folders) and their data data
    public projectList$ = new BehaviorSubject<ProjectListEntry[]>(this.projects); // subscribeable subject to get current project list
    public projectListLoading$ = new BehaviorSubject<boolean>(true);

    public availableProjectFolderNames$ = new BehaviorSubject<string[]>([]); // takes track of used project folder names

    private currentProjectData: Project;
    public currentProject$: BehaviorSubject<Project>; // subscribeable subject to get current project
    private currentProjectIsInitializing = false; // true while the project is currently initializing

    public currentUserProjectSharingData: ProjectSharing; // the current project sharing item (includes the current project permissions)

    private currentProjectServiceGroupListData: Array<ServiceGroup> = []; // holds the service groups data
    public currentProjectServiceGroupList$ = new BehaviorSubject<ServiceGroup[]>(this.currentProjectServiceGroupListData);
    private currentServiceGroupData: ServiceGroup; // the current active service group
    public currentServiceGroup$: BehaviorSubject<ServiceGroup>; // subscribeable subject to get current active servicegroup of project
    public currentServiceGroupChangesAllowed = false; // true if the user has the project rights to work in the current service group
    public serviceGroupChangesAllowedMap = new Map<string, boolean>(); // map of each available service group id and true or false if a user is allowed to edit
    public serviceGroupListLoading$ = new BehaviorSubject<boolean>(true);

    private currentProjectBuildingPlanListData: Array<BuildingPlan> = []; // holds the building plans data
    public currentProjectBuildingPlanList$ = new BehaviorSubject<BuildingPlan[]>(this.currentProjectBuildingPlanListData); // subscribeable subject to get current building plan list
    public buildingPlanListLoading$ = new BehaviorSubject<boolean>(true);

    private currentProjectReportListData: Array<Report> = []; // holds the reports data
    public currentProjectReportList$ = new BehaviorSubject<Report[]>(this.currentProjectReportListData); // subscribeable subject to get current report list
    public reportListLoading$ = new BehaviorSubject<boolean>(true);

    private projectListInitialized = false; // true as soon as the project list has been intialized after app is ready
    public currentProjectListSortingMode: 'name-a-z' | 'name-z-a' | 'project-number-1-9' | 'project-number-9-1' = null;

    public currentlySelectedJobs: Array<string> = []; // list of job ids if jobs were (multi)selected in job list (used for further actions like report-gen)

    private webSocketConnectionStatusSubscription: Subscription;

    /**
     * Use as parameter for the Array.prototype.sort!
     * Building plan sorting algorithm. Orders plans based on sortOrder or moves items without to the end.
     */
    private sortPlanFn = (planA: BuildingPlan, planB: BuildingPlan) => {
        if (planA.sortOrder < planB.sortOrder || planB.sortOrder == undefined) {
            return -1;
        } else if (planA.sortOrder > planB.sortOrder || planA.sortOrder == undefined) {
            // we also move items without sortOrder to the end of the array
            return 1;
        }
        return 0;
    };

    constructor(
        private db: DbService,
        private authSrv: AuthService,
        private logger: LoggerService,
        private utils: UtilsService,
        private notify: NotificationService,
        private syncSrv: SyncService,
        private storage: Storage,
        private settingsSrv: SettingsService,
        private fb: UntypedFormBuilder,
        private router: Router,
        private http: HttpClient
    ) {
        this.currentProject$ = new BehaviorSubject<Project>(this.currentProjectData);
        this.currentServiceGroup$ = new BehaviorSubject<ServiceGroup>(this.currentServiceGroupData);
        this.projectListLoading$.next(true);

        // wait until app is ready
        this.authSrv.appReady$.pipe(takeWhile((appReady) => this.projectListInitialized === false)).subscribe((appReady) => {
            if (appReady === true) {
                this.logger.log('[PROJECT SERVICE] app ready event received. initializing now');
                this.projectListLoading$.next(true);

                // get project list from db
                this.db.getItemsByType('project').subscribe((res: any[]) => {
                    this.logger.log('[PROJECT SERVICE] project list loaded', res);
                    // group projects by folder and add them
                    this.projects = [];
                    for (let entry of res) {
                        this.addProjectToProjectList(new Project(entry));
                    }

                    // check project list sorting mode
                    this.storage
                        .get('dp_project_list_sorting_mode')
                        .then((sortingMode) => {
                            this.logger.log('[PROJECT SERVICE] loaded project list sorting mode', sortingMode);
                            this.currentProjectListSortingMode = sortingMode;
                        })
                        .catch((sortModeErr) => {
                            this.logger.error('[PROJECT SERVICE] could not load project list sorting mode', sortModeErr);
                        })
                        .finally(() => {
                            this.sortProjectList();
                            this.updateUsedProjectFolderNames();

                            this.projectList$.next(this.projects);
                            this.projectListInitialized = true;
                            this.projectListLoading$.next(false);
                        });
                });

                // subscribe to itemChanged event from db, to observe project changes
                this.handleItemChanges();
            }
        });

        this.webSocketConnectionStatusSubscription = this.authSrv.webSocketConnectionStatusChanged$.subscribe((connected) => {
            if (connected) {
                // if the websocket connection status changes (e.g reconnect, etc.) we send the websocket our current project that we want to listen to
                if (this.currentProjectData != null) {
                    this.authSrv.sendToWebsocketApi({
                        action: 'listenToChangesByRelation',
                        relation: this.currentProjectData.pk,
                    });
                }
            }
        });
    }

    /**
     * Handles the item changes as soon as an change event has occured in db
     */
    private handleItemChanges() {
        this.logger.log('[PROJECT SERVICE] subscribing to db item changefeed, to observe project changes');
        this.db.itemsChanged$.subscribe((changes) => {
            for (let change of changes) {
                if (change.item.type === 'project') {
                    // observer project list changes

                    if (change.operation === 'PUT') {
                        let changedProject = new Project(change.item);
                        // find the changed project id in our current list
                        let changedProjectIndexInList = this.projects.findIndex((x) => {
                            if (x.entryType === 'folder') {
                                let found = x.folderProjects.find((y) => y._id === changedProject._id);
                                return found != null;
                            } else {
                                return x.project._id === changedProject._id;
                            }
                        });
                        if (changedProjectIndexInList > -1) {
                            // existing project in db changed
                            if (this.projects[changedProjectIndexInList].entryType === 'folder') {
                                // project was in a folder before the change - get index of project in this folder
                                let changedProjectIndexInFolder = this.projects[changedProjectIndexInList].folderProjects.findIndex(
                                    (y) => y._id === changedProject._id
                                );
                                if (changedProjectIndexInFolder > -1) {
                                    // check if the changedProject is still in the same folder, then we can just replace it
                                    if (
                                        this.projects[changedProjectIndexInList].folderProjects[changedProjectIndexInFolder].folder ===
                                        changedProject.folder
                                    ) {
                                        // folder is still the same - replace project in current folder
                                        this.logger.log(
                                            '[PROJECT SERVICE] project updated in db. updating project list (project stayed in same folder).'
                                        );
                                        this.projects[changedProjectIndexInList].folderProjects[changedProjectIndexInFolder] =
                                            changedProject; // update project
                                    } else {
                                        // folder is not the same, remove the project from current folder
                                        this.logger.log(
                                            '[PROJECT SERVICE] project updated in db. updating project list (project folder was changed too).'
                                        );
                                        if (this.projects[changedProjectIndexInList].folderProjects.length > 1) {
                                            // the folder contains more than 1 project, remove only the deleted project
                                            this.projects[changedProjectIndexInList].folderProjects.splice(changedProjectIndexInFolder, 1);
                                        } else {
                                            // the only project in this folder was deleted, remove the whole folder
                                            this.projects.splice(changedProjectIndexInList, 1);
                                            this.updateUsedProjectFolderNames();
                                        }
                                        // then add the project to the list again
                                        this.addProjectToProjectList(changedProject);
                                        this.sortProjectList(); // sort project list again as folders changed
                                    }
                                }
                            } else {
                                // project was not in a folder before the change, check if the project still has no folder after the change
                                if (this.projects[changedProjectIndexInList].project.folder === changedProject.folder) {
                                    // project folder is still null (so the same as before the change) we can just replace the project
                                    this.logger.log(
                                        '[PROJECT SERVICE] project updated in db. updating project list (project without folder).'
                                    );
                                    this.projects[changedProjectIndexInList].project = changedProject;
                                } else {
                                    // folder is not the same, remove the project from the list
                                    this.logger.log(
                                        '[PROJECT SERVICE] project updated in db. updating project list (project without folder moved to folder).'
                                    );
                                    this.projects.splice(changedProjectIndexInList, 1);
                                    // then add the project to the list again
                                    this.addProjectToProjectList(changedProject);
                                    this.sortProjectList(); // sort project list again as folders changed
                                }
                            }

                            // check if the changed project is our current project
                            if (this.currentProjectData != null && change.item._id == this.currentProjectData._id) {
                                this.logger.log('[PROJECT SERVICE] current project updated in db. re-initializing current project.');
                                this.setCurrentProject(changedProject);
                            }
                        } else {
                            // new project added to db
                            this.logger.log('[PROJECT SERVICE] new project found in db. adding to project list.');
                            this.addProjectToProjectList(new Project(change.item)); // add project to list
                            this.sortProjectList();
                        }
                    } else if (change.operation === 'DELETE') {
                        // project in list deleted
                        let deletedProject = new Project(change.item);
                        // project deleted, find index where we need to delete the project
                        if (deletedProject.folder != null) {
                            // project in a folder was deleted, find index and remove project from folder
                            let deletedProjectIndexInList = this.projects.findIndex((x) => x.folder === deletedProject.folder); // find folder of project in list
                            if (deletedProjectIndexInList > -1) {
                                let deletedProjectIndexInFolder = this.projects[deletedProjectIndexInList].folderProjects.findIndex(
                                    (x) => x._id === deletedProject._id
                                ); // find project in folder
                                if (this.projects[deletedProjectIndexInList].folderProjects.length > 1) {
                                    // the folder contains more than 1 project, remove only the deleted project
                                    this.projects[deletedProjectIndexInList].folderProjects.splice(deletedProjectIndexInFolder, 1);
                                } else {
                                    // the only project in this folder was deleted, remove the whole folder
                                    this.projects.splice(deletedProjectIndexInList, 1);
                                    this.updateUsedProjectFolderNames();
                                }
                                this.logger.log('[PROJECT SERVICE] project deleted in db. updating project list (project in folder).');
                            }
                        } else {
                            let deletedProjectIndexInList = this.projects.findIndex(
                                (x) => x.project != null && x.project._id === deletedProject._id
                            ); // find project in list
                            this.logger.log('[PROJECT SERVICE] project deleted in db. updating project list.');
                            this.projects.splice(deletedProjectIndexInList, 1);
                        }
                    }

                    this.projectList$.next(this.projects); // update project list event
                } else if (change.item.type === 'building-plan') {
                    // check if the item is actually related to our current project, otherwise we dont need to update our lists
                    if (this.currentProjectData != null && this.currentProjectData.relation == change.item.relation) {
                        // item is related to our current project, so update our building plan list
                        let projectBpIndex = this.currentProjectBuildingPlanListData.findIndex((x) => x._id === change.item._id);
                        if (change.operation === 'PUT') {
                            if (projectBpIndex > -1) {
                                // bp in list changed
                                this.logger.log(
                                    '[PROJECT SERVICE] building plan updated in db. updating current project building plan list.'
                                );
                                this.currentProjectBuildingPlanListData[projectBpIndex] = new BuildingPlan(change.item);

                                this.currentProjectBuildingPlanListData = this.currentProjectBuildingPlanListData.sort(this.sortPlanFn);

                                if (
                                    this.currentProjectBuildingPlanListData[projectBpIndex].dataStatus == PlanStatus.Done &&
                                    this.utils.isNative
                                ) {
                                    try {
                                        this.syncSrv.syncPlanData(this.currentProjectBuildingPlanListData[projectBpIndex]);
                                    } catch (syncErr) {
                                        this.logger.error('[PROJECT SERVICE] Failed syncing plan after change feed update/delete', syncErr);
                                    }
                                }
                            } else {
                                // bp not in list, so it was added
                                this.logger.log('[PROJECT SERVICE] new building plan found. updating current project building plan list.');
                                // add job to project building plan list
                                let bpToAdd = new BuildingPlan(change.item);
                                this.currentProjectBuildingPlanListData.push(bpToAdd);
                                this.currentProjectBuildingPlanListData = this.currentProjectBuildingPlanListData.sort(this.sortPlanFn);

                                if (bpToAdd.dataStatus == PlanStatus.Done && this.utils.isNative) {
                                    try {
                                        this.syncSrv.syncPlanData(bpToAdd);
                                    } catch (syncErr) {
                                        this.logger.error('[PROJECT SERVICE] Failed syncing plan after change feed insert', syncErr);
                                    }
                                }
                            }
                        } else if (change.operation === 'DELETE' && projectBpIndex > -1) {
                            this.logger.log('[PROJECT SERVICE] building plan deleted in db. updating current project building plan list.');
                            this.currentProjectBuildingPlanListData.splice(projectBpIndex, 1);
                        }
                        //this.currentProjectBuildingPlanListData.sort(this.sortJobsByJobNumber);
                        this.currentProjectBuildingPlanList$.next(this.currentProjectBuildingPlanListData);
                    }
                } else if (change.item.type === 'report') {
                    // check if the item is actually related to our current project, otherwise we dont need to update our lists
                    if (this.currentProjectData != null && this.currentProjectData.relation == change.item.relation) {
                        let projectReportIndex = this.currentProjectReportListData.findIndex((x) => x._id === change.item._id);
                        if (change.operation === 'PUT') {
                            if (projectReportIndex > -1) {
                                // existing report in list changed, update
                                this.logger.log('[PROJECT SERVICE] report updated in db. updating current project report list.');
                                this.currentProjectReportListData[projectReportIndex] = new Report(change.item);
                            } else {
                                // new report added to list
                                // add report to project report list
                                let reportToAdd = new Report(change.item);
                                this.currentProjectReportListData.push(reportToAdd);
                            }
                        } else if (change.operation === 'DELETE' && projectReportIndex > -1) {
                            this.logger.log('[PROJECT SERVICE] report deleted in db. updating current project report list.');
                            this.currentProjectReportListData.splice(projectReportIndex, 1);
                        }
                        this.currentProjectReportListData.sort(this.utils.sortByCreatedAtPropertyDescending);
                        this.currentProjectReportList$.next(this.currentProjectReportListData);
                    }
                } else if (change.item.type === 'service-group') {
                    let refreshNeeded = false;
                    if (this.currentProjectData != null && this.currentProjectData.relation == change.item.relation) {
                        refreshNeeded = true;
                    } else {
                        if (
                            this.currentProjectServiceGroupListData.find(
                                (x) => x.syncedServiceGroup === true && x.originalId === change.item._id
                            ) != null
                        ) {
                            refreshNeeded = true;
                        }
                    }
                    if (refreshNeeded) {
                        this.logger.log('[PROJECT SERVICE] a service-group of current project has changed. reload service groups');
                        this.loadProjectServiceGroups()
                            .then(() => {})
                            .catch((reloadErr) => {
                                this.logger.error('[PROJECT SERVICE] could not reload service groups', reloadErr);
                            });
                    }
                }
            }
        });
    }

    /**
     * Changes the current project sorting mode
     * @param newMode
     */
    public async changeProjectSortingMode(newMode: 'name-a-z' | 'name-z-a' | 'project-number-1-9' | 'project-number-9-1') {
        try {
            this.currentProjectListSortingMode = newMode;
            this.storage.set('dp_project_list_sorting_mode', newMode);
            this.sortProjectList();
            this.projectList$.next(this.projects);
        } catch (setSortErr) {
            this.logger.error('[PROJECT SERVICE] could not change project sorting mode', setSortErr);
        }
    }

    /**
     * Adds project into the project list (in a folder or not if no folder is set)
     * @param project
     * @private
     */
    private addProjectToProjectList(project: Project) {
        if (project.folder != null) {
            // check if we already have this folder in our list
            let folderFoundIndex = this.projects.findIndex((x) => x.folder === project.folder);
            if (folderFoundIndex > -1) {
                this.projects[folderFoundIndex].folderProjects.push(project);
            } else {
                this.projects.push({
                    entryType: 'folder',
                    folder: project.folder,
                    folderProjects: [project],
                });
                this.updateUsedProjectFolderNames();
            }
        } else {
            this.projects.push({
                entryType: 'project',
                project: project,
            });
        }
    }

    /**
     * Sorts the current project list by name
     * @private
     */
    private sortProjectList() {
        try {
            if (
                this.currentProjectListSortingMode === 'project-number-1-9' ||
                this.currentProjectListSortingMode === 'project-number-9-1'
            ) {
                this.projects.sort((a, b) => {
                    if (a.entryType === 'folder' && b.entryType === 'folder') {
                        // if both items we compare are folders -> compare by folder name (alphabetically) because they have no project number
                        if (this.currentProjectListSortingMode === 'project-number-9-1') {
                            return b.folder.localeCompare(a.folder);
                        } else {
                            return a.folder.localeCompare(b.folder);
                        }
                    } else if (
                        (a.project?.projectNumber == null || a.project?.projectNumber === '') &&
                        (b.project?.projectNumber == null || b.project?.projectNumber === '')
                    ) {
                        // if both items we compare have no project numbers -> compare by project name
                        let aNameCompare = a.entryType === 'folder' ? a.folder : a.project.name;
                        let bNameCompare = b.entryType === 'folder' ? b.folder : b.project.name;
                        if (this.currentProjectListSortingMode === 'project-number-9-1') {
                            return bNameCompare.localeCompare(aNameCompare);
                        } else {
                            return aNameCompare.localeCompare(bNameCompare);
                        }
                    } else {
                        let compareA;
                        let compareB;
                        if (a.entryType === 'folder' || a.project.projectNumber == null || a.project.projectNumber === '') {
                            compareA = '0';
                        } else {
                            compareA = a.project.projectNumber.toString();
                        }
                        if (b.entryType === 'folder' || b.project.projectNumber == null || b.project.projectNumber === '') {
                            compareB = '0';
                        } else {
                            compareB = b.project.projectNumber.toString();
                        }
                        // replace all chars except numbers for sorting
                        let aReplaced = parseFloat(compareA.replace(/[^0-9]/g, ''));
                        let bReplaced = parseFloat(compareB.replace(/[^0-9]/g, ''));
                        // check if the replaced values are valid numbers, then sort with these numbers
                        if (!isNaN(aReplaced) && !isNaN(bReplaced)) {
                            if (aReplaced == bReplaced) {
                                return 0;
                            } else if (aReplaced > bReplaced) {
                                if (this.currentProjectListSortingMode === 'project-number-9-1') {
                                    return -1;
                                } else {
                                    return 1;
                                }
                            } else {
                                if (this.currentProjectListSortingMode === 'project-number-9-1') {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            }
                        } else {
                            if (compareA == compareB) {
                                return 0;
                            } else if (compareA > compareB) {
                                if (this.currentProjectListSortingMode === 'project-number-9-1') {
                                    return -1;
                                } else {
                                    return 1;
                                }
                            } else {
                                if (this.currentProjectListSortingMode === 'project-number-9-1') {
                                    return 1;
                                } else {
                                    return -1;
                                }
                            }
                        }
                    }
                });
            } else {
                this.projects.sort((a, b) => {
                    let compareA = a.entryType === 'folder' ? a.folder : a.project.name;
                    let compareB = b.entryType === 'folder' ? b.folder : b.project.name;
                    if (this.currentProjectListSortingMode === 'name-z-a') {
                        return compareB.localeCompare(compareA);
                    } else {
                        return compareA.localeCompare(compareB); // default sorting
                    }
                });
            }

            // project outer list is now sorted, now we have to sort projects inside of folders
            for (let entry of this.projects) {
                if (entry.entryType === 'folder') {
                    if (
                        this.currentProjectListSortingMode === 'project-number-1-9' ||
                        this.currentProjectListSortingMode === 'project-number-9-1'
                    ) {
                        // sort projects in folder by project number
                        if (Array.isArray(entry.folderProjects)) {
                            entry.folderProjects.sort((a, b) => {
                                if (
                                    (a.projectNumber == null || a.projectNumber === '') &&
                                    (b.projectNumber == null || b.projectNumber === '')
                                ) {
                                    // if both items we compare have no project numbers -> compare by project name
                                    if (this.currentProjectListSortingMode === 'project-number-9-1') {
                                        return b.name.localeCompare(a.name);
                                    } else {
                                        return a.name.localeCompare(b.name);
                                    }
                                } else {
                                    let compareA;
                                    let compareB;
                                    if (a.projectNumber == null || a.projectNumber === '') {
                                        compareA = '0';
                                    } else {
                                        compareA = a.projectNumber.toString();
                                    }
                                    if (b.projectNumber == null || b.projectNumber === '') {
                                        compareB = '0';
                                    } else {
                                        compareB = b.projectNumber.toString();
                                    }
                                    // replace all chars except numbers for sorting
                                    let aReplaced = parseFloat(compareA.replace(/[^0-9]/g, ''));
                                    let bReplaced = parseFloat(compareB.replace(/[^0-9]/g, ''));
                                    // check if the replaced values are valid numbers, then sort with these numbers
                                    if (!isNaN(aReplaced) && !isNaN(bReplaced)) {
                                        if (aReplaced == bReplaced) {
                                            return 0;
                                        } else if (aReplaced > bReplaced) {
                                            if (this.currentProjectListSortingMode === 'project-number-9-1') {
                                                return -1;
                                            } else {
                                                return 1;
                                            }
                                        } else {
                                            if (this.currentProjectListSortingMode === 'project-number-9-1') {
                                                return 1;
                                            } else {
                                                return -1;
                                            }
                                        }
                                    } else {
                                        if (compareA == compareB) {
                                            return 0;
                                        } else if (compareA > compareB) {
                                            if (this.currentProjectListSortingMode === 'project-number-9-1') {
                                                return -1;
                                            } else {
                                                return 1;
                                            }
                                        } else {
                                            if (this.currentProjectListSortingMode === 'project-number-9-1') {
                                                return 1;
                                            } else {
                                                return -1;
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    } else {
                        // sort projects in folder by name
                        if (Array.isArray(entry.folderProjects)) {
                            entry.folderProjects.sort((a, b) => {
                                if (this.currentProjectListSortingMode === 'name-z-a') {
                                    return b.name.localeCompare(a.name);
                                } else {
                                    return a.name.localeCompare(b.name); // default sorting
                                }
                            });
                        }
                    }
                }
            }
        } catch (sortErr) {
            this.logger.error('[PROJECT SERVICE] failed to sort project list', sortErr);
        }
    }

    /**
     * Updates the availableProjectFolderNames with a list of all folder names currently occuring in project list
     * @private
     */
    private updateUsedProjectFolderNames() {
        let names = [];
        for (let project of this.projects) {
            if (project.folder != null && !names.includes(project.folder)) {
                names.push(project.folder);
            }
        }
        this.availableProjectFolderNames$.next(names);
    }

    /**
     * Sets the current project
     * @param project The project item
     */
    public async setCurrentProject(project: Project) {
        if (project == null) {
            this.logger.log('[PROJECT SERVICE] re-setting current project to NULL');
            this.currentProjectData = null;
            this.currentProject$.next(this.currentProjectData);
            return;
        }

        if (this.currentProjectIsInitializing && project._id === this.currentProjectData?._id) {
            // if the project is currently initializing (so setCurrentProject() is already running) - we dont start it again if the project id has not changed
            return;
        }
        this.currentProjectIsInitializing = true;
        this.logger.log('[PROJECT SERVICE] setting current project', project);

        this.currentProjectData = new Project(this.utils.clone(project));
        this.currentProject$.next(this.currentProjectData);

        // set our project last use time (used to cleanup unused projects for more free space)
        try {
            let lastUsedProjects = await this.storage.get('dp_last_used_projects');
            if (lastUsedProjects == null || !Array.isArray(lastUsedProjects)) {
                lastUsedProjects = [];
            }

            // check if our project is already included in the last used projects, then we only update the timestamp
            let foundIndex = lastUsedProjects.findIndex((x) => x.projectId === project._id);
            if (foundIndex > -1) {
                lastUsedProjects[foundIndex].timestamp = Date.now();
            } else {
                lastUsedProjects.push({
                    projectId: project._id,
                    timestamp: Date.now(),
                });
            }

            // update array
            await this.storage.set('dp_last_used_projects', lastUsedProjects);
            this.logger.log('[PROJECT SERVICE] updated last used projects');
        } catch (projectTimeErr) {
            this.logger.error('[PROJECT SERVICE] could not update last used projects', projectTimeErr);
        }

        let loadingFailed = false;
        let projectRefreshed = false;
        try {
            // try loading project service groups
            await this.loadProjectServiceGroups();
        } catch (err) {
            if (err === 'no-sharing' || err === 'no-service-groups') {
                // loading of service groups failed because no project sharings (or no service groups in the case of a support user) were found for this project, this could happen if the sync mode is limited and the project isnt available on device
                // start a sync for the project to get data, we need to wait for the result
                this.logger.log(
                    `[PROJECT SERVICE] syncing project because project data is not available. sync mode currently is ${this.syncSrv.syncMode$.getValue()}`
                );

                try {
                    await this.syncSrv.downloadItemsByRelation(project.pk);
                    projectRefreshed = true;

                    // sync for project finished, try loading service groups again now
                    await this.loadProjectServiceGroups();
                } catch (err) {
                    // something went wrong during sync of project or second try of loading service groups
                    // reset sync timestamp of this project (so project will be completely synced from scratch)
                    try {
                        this.logger.warn(
                            `[PROJECT SERVICE] sync of project data on open did not help. resetting project sync timestamp to 0 - starting project sync from scratch`
                        );
                        this.utils.sentryCaptureMessage(
                            `[PROJECT SERVICE] sync of project data on open did not help. resetting project sync timestamp to 0 - starting project sync from scratch`,
                            { project: project.pk }
                        );
                        await this.storage.remove('dp_sync_project#' + project._id);
                        await this.syncSrv.downloadItemsByRelation(project.pk);
                        // sync for project finished, try loading service groups again now
                        await this.loadProjectServiceGroups();
                    } catch (secondSyncErr) {
                        loadingFailed = true;
                    }
                }
            } else {
                this.logger.error('[PROJECT SERVICE] loading of service groups failed during setCurrentProject', err);
                this.utils.sentryCaptureMessage('[PROJECT SERVICE] loading of service groups failed during setCurrentProject');
                this.currentProjectIsInitializing = false;
                throw err;
            }
        }

        if (!loadingFailed) {
            if (!projectRefreshed) {
                // if we are in default sync mode we start a sync for the project to get the latest changes, but we dont need to wait for it
                this.logger.log('[PROJECT SERVICE] starting sync for current project', project);
                this.syncSrv
                    .downloadItemsByRelation(project.pk)
                    .then(() => {
                        this.logger.log('[PROJECT SERVICE] sync for current project done');
                    })
                    .catch(() => {
                        this.logger.error('[PROJECT SERVICE] sync for current project could not be finished. data may be older');
                    });
            }

            // we can do this asynchronously after our promise has resolved, they have their own loading indicators
            this.buildCurrentProjectBuildingPlanList();
            this.buildCurrentProjectReportList();

            this.authSrv.sendToWebsocketApi({
                action: 'listenToChangesByRelation',
                relation: project.pk,
            });

            this.currentlySelectedJobs = []; // reset
            this.currentProjectIsInitializing = false;
        } else {
            // loading failed, maybe our connection isnt ok and project could not be synced
            this.currentProjectIsInitializing = false;
            this.logger.log('[PROJECT SERVICE] loading of project failed. show info and go to dashboard');
            this.notify.info(
                'Das Projekt konnte nicht geöffnet werden. Prüfe deine Internetverbindung und starte eine neue Synchronisierung, oder starte die App neu.',
                'Öffnen fehlgeschlagen',
                false
            );
            this.router.navigate(['/']);
        }
    }

    /**
     * Loads the projects service groups from db.
     * Triggered when the current project has been set
     */
    public loadProjectServiceGroups() {
        return new Promise((resolve, reject) => {
            this.logger.log('[PROJECT SERVICE] loading project service groups');
            this.serviceGroupListLoading$.next(true);
            this.db
                .find({
                    selector: {
                        projectId: {
                            $eq: this.currentProjectData._id,
                        },
                        type: {
                            $eq: 'project-sharing',
                        },
                    },
                })
                .pipe(
                    switchMap((projectSharings: Array<ProjectSharing>) => {
                        // filter all project sharings for current user project sharing item - maybe needs performance improvement in the future (index for property projectSharedWith so that we can get only our current user project sharing item)
                        let currentUserProjectSharing: ProjectSharing = projectSharings.find((x) => {
                            return (
                                x.projectSharedWith === 'user#' + this.authSrv.authInfo.userData.userName ||
                                x.projectSharedWith === 'company#' + this.authSrv.authInfo.companyData._id
                            );
                        });

                        if (!currentUserProjectSharing) {
                            if (this.authSrv.authInfo.userData.dokupitSupportAccount === true) {
                                // create a dummy project sharing for our support user
                                currentUserProjectSharing = new ProjectSharing();
                                currentUserProjectSharing.projectId = this.currentProjectData._id;
                                currentUserProjectSharing.projectSharedWith = 'user#' + this.authSrv.authInfo.userData.userName;
                                currentUserProjectSharing.relation = 'project#' + this.currentProjectData._id;
                                this.logger.warn(
                                    '[PROJECT SERVICE] created dummy project sharing item for current support user',
                                    currentUserProjectSharing
                                );
                            } else if (this.settingsSrv.getUserRights().hasAccessToAllProjects === true) {
                                // create dummy project sharing for user which has always access to all projects
                                currentUserProjectSharing = new ProjectSharing();
                                currentUserProjectSharing.projectId = this.currentProjectData._id;
                                currentUserProjectSharing.projectSharedWith = 'user#' + this.authSrv.authInfo.userData.userName;
                                currentUserProjectSharing.relation = 'project#' + this.currentProjectData._id;
                                this.logger.log(
                                    '[PROJECT SERVICE] created dummy project sharing for current user who always has access to all projects',
                                    currentUserProjectSharing
                                );
                            }
                        }

                        if (!currentUserProjectSharing) {
                            // unrecoverable error
                            this.serviceGroupListLoading$.next(false);
                            this.logger.log(
                                '[PROJECT SERVICE] could not find a project sharing for project ' +
                                    this.currentProjectData._id +
                                    ' and current user ' +
                                    this.authSrv.authInfo.userData.userName
                            );
                            return of('no-sharing');
                        } else {
                            this.currentUserProjectSharingData = new ProjectSharing(currentUserProjectSharing);
                            this.logger.log('[PROJECT SERVICE] loaded project sharing item for current user', currentUserProjectSharing);
                            // get service group items from db
                            return this.db.find({
                                selector: {
                                    projectId: {
                                        $eq: this.currentProjectData._id,
                                    },
                                    type: {
                                        $eq: 'service-group',
                                    },
                                },
                            });
                        }
                    })
                )
                .subscribe(
                    (data: Array<any> | 'no-sharing') => {
                        if (data === 'no-sharing') {
                            this.logger.log('[PROJECT SERVICE] no project sharing found, abort loading of service groups');
                            reject(data);
                        } else {
                            if (data.length === 0) {
                                reject('no-service-groups'); // sharing found but no service groups (this can only happen if we are a support user which has a dummy-sharing but hasnt synced the project yet)
                            } else {
                                this.currentProjectServiceGroupListData = data.map((x) => new ServiceGroup(x));
                                this.logger.log(
                                    '[PROJECT SERVICE] got service group list for current project from db',
                                    this.currentProjectServiceGroupListData
                                );

                                // check if we need to filter the visisble service groups because of project rights
                                if (
                                    Array.isArray(this.currentUserProjectSharingData.projectPermissions.visibleServiceGroups) &&
                                    this.currentUserProjectSharingData.projectPermissions.visibleServiceGroups.length > 0
                                ) {
                                    this.currentProjectServiceGroupListData = this.currentProjectServiceGroupListData.filter((x) => {
                                        return this.currentUserProjectSharingData.projectPermissions.visibleServiceGroups.includes(x._id);
                                    });
                                }

                                this.currentProjectServiceGroupListData.sort((a, b) => a.name.localeCompare(b.name));
                                this.currentProjectServiceGroupList$.next(this.currentProjectServiceGroupListData);

                                // set first servicegroup as current servicegroup
                                this.currentServiceGroupData = this.currentProjectServiceGroupListData[0];

                                // check if the project rights allow changes in the current service group for the current user
                                this.checkCurrentServiceGroupRights();

                                // Calc service group rights for all groups and store them in the map
                                for (let sg of this.currentProjectServiceGroupListData) {
                                    this.checkServiceGroupRights(sg);
                                }

                                // load original service group, then finish
                                Promise.all(
                                    this.currentProjectServiceGroupListData.map((s) =>
                                        this.loadOriginalServiceGroupForServiceGroup(s).catch((e) => {
                                            this.logger.error(
                                                '[PROJECT SERVICE] could not get original service group for service group from db',
                                                e,
                                                s._id
                                            );
                                        })
                                    )
                                ).then((sgsHasChanged) => {
                                    this.logger.log(sgsHasChanged);
                                    this.currentServiceGroup$.next(this.currentServiceGroupData);
                                    this.serviceGroupListLoading$.next(false);
                                    resolve(null);
                                });
                            }
                        }
                    },
                    (err) => {
                        this.serviceGroupListLoading$.next(false);
                        this.logger.error('[PROJECT SERVICE] could not get service group list for current project from db', err);
                        this.notify.error('Vorlagen für aktuelles Projekt konnten nicht geladen werden');
                        this.utils.sentryCaptureException(err);
                        reject(err);
                    }
                );
        });
    }

    /**
     * Checks if the current service group has the sync setting active and loads the original service group if it has.
     * It replaces certain properties of the current servicegroup with the original ones if the sync setting is enabled and the original service group still exists
     * The promise resolves with the value true if the currentServiceGroupData has been changed. It resolves with false if the current service group data has not changed.
     * This function does NOT trigger any behaviour subject after changing the currentServiceGroupData variable, you have to trigger it yourself after calling this function
     * @private
     */
    public loadOriginalServiceGroupForServiceGroup(sg: ServiceGroup) {
        return new Promise<boolean>((resolve) => {
            if (sg.syncedServiceGroup) {
                this.db.getItemById(sg.originalId).subscribe(
                    (originalSgData) => {
                        let originalServiceGroup = new ServiceGroup(originalSgData);

                        // replace current service group data with data from original service group
                        sg.originalName = originalServiceGroup.name;
                        sg.defaultJobName = originalServiceGroup.defaultJobName;
                        sg.articles = originalServiceGroup.articles;
                        sg.fields = originalServiceGroup.fields;
                        sg.hasCustomIconInJobList = originalServiceGroup.hasCustomIconInJobList;
                        sg.hasCustomIconOnBuildingPlan = originalServiceGroup.hasCustomIconOnBuildingPlan;
                        sg.hasArticlesWithPhotos = originalServiceGroup.hasArticlesWithPhotos;
                        sg.settings = originalServiceGroup.settings;
                        sg.documents = originalServiceGroup.documents;
                        resolve(true);
                    },
                    (err) => {
                        this.logger.warn('[PROJECT SERVICE] failed to load original service group for service group ' + sg._id, err);
                        // don't capture this in sentry as the app works with the project's local service group as intended, and we get a awful lot of this messages which up to now newer were needed for error debugging from sentry itself
                        // this.utils.sentryCaptureMessage('[PROJECT SERVICE] Failed loading original service group');
                        resolve(false);
                    }
                );
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Sets the current service group based on given id
     * @param serviceGroupId id of the service group in the project
     */
    public setCurrentServiceGroup(serviceGroupId: string) {
        this.logger.log('[PROJECT SERVICE] setting current service group', serviceGroupId);
        let sgIndex = this.currentProjectServiceGroupListData.findIndex((x) => {
            return x._id == serviceGroupId;
        });
        if (sgIndex > -1) {
            this.currentServiceGroupData = new ServiceGroup(this.currentProjectServiceGroupListData[sgIndex]);

            // check if the project rights allow changes in the current service group for the current user
            this.checkCurrentServiceGroupRights();

            // load original service group
            this.loadOriginalServiceGroupForServiceGroup(this.currentServiceGroupData).then((sgHasChanged) => {
                this.currentServiceGroup$.next(this.currentServiceGroupData);
            });
        } else {
            this.logger.error('[PROJECT SERVICE] could not set service group. service group id was not found in project', serviceGroupId);
        }
    }

    /**
     * Checks if the user has the rights to work in the current service group
     * Sets the currentServiceGroupChangesAllowed variable accordingly
     * @private
     */
    private checkCurrentServiceGroupRights() {
        this.currentServiceGroupChangesAllowed = this.checkServiceGroupRights(this.currentServiceGroupData);
    }

    /**
     * Checks if the user has the rights to work in the given service group
     * Updates the service group rights map accordingly
     * @returns boolean whether it is editable or not
     * @private
     */
    private checkServiceGroupRights(sg: ServiceGroup) {
        // check if the project rights allow changes in the current service group for the current user
        if (this.authSrv.isAdminLicense) {
            // admin
            if (sg) {
                this.serviceGroupChangesAllowedMap.set(sg._id, true); // allow all
            }
        } else {
            // mobile, viewer or custom licenses - check if workable service groups are set, then allow the set ones (fallback to allowing all service groups if none are set)
            if (
                Array.isArray(this.currentUserProjectSharingData.projectPermissions.workableServiceGroups) &&
                this.currentUserProjectSharingData.projectPermissions.workableServiceGroups.length > 0
            ) {
                this.serviceGroupChangesAllowedMap.set(
                    sg._id,
                    this.currentUserProjectSharingData.projectPermissions.workableServiceGroups.includes(sg._id)
                );
            } else {
                this.serviceGroupChangesAllowedMap.set(sg._id, true);
            }
        }

        return this.serviceGroupChangesAllowedMap.get(sg._id);
    }

    /**
     * Gets all building plans for current project from db. Emits the new list to currentProjectBuildingPlanList subject
     * @private
     */
    private buildCurrentProjectBuildingPlanList() {
        this.buildingPlanListLoading$.next(true);
        this.db
            .find({
                selector: {
                    projectId: {
                        $eq: this.currentProjectData._id,
                    },
                    type: {
                        $eq: 'building-plan',
                    },
                },
            })
            .subscribe(
                (data: Array<any>) => {
                    this.logger.log('[PROJECT SERVICE] got building plan list for current project from db', data);
                    this.currentProjectBuildingPlanListData = data.map((x) => new BuildingPlan(x)).sort(this.sortPlanFn);
                    //this.currentProjectBuildingPlanListData.sort(this.sortJobsByJobNumber);
                    this.currentProjectBuildingPlanList$.next(this.currentProjectBuildingPlanListData);

                    this.buildingPlanListLoading$.next(false);
                },
                (err) => {
                    this.buildingPlanListLoading$.next(false);
                    this.logger.error('[PROJECT SERVICE] could not get building plan list for current project from db', err);
                    this.notify.error('Baupläne für aktuelles Projekt konnten nicht geladen werden');
                    this.utils.sentryCaptureException(err);
                }
            );
    }

    /**
     * Gets all reports for current project from db. Emits the new list to currentProjectReportList subject
     * @private
     */
    private buildCurrentProjectReportList() {
        this.reportListLoading$.next(true);
        this.db
            .find({
                selector: {
                    projectId: {
                        $eq: this.currentProjectData._id,
                    },
                    type: {
                        $eq: 'report',
                    },
                },
            })
            .subscribe(
                (data: Array<any>) => {
                    this.logger.log('[PROJECT SERVICE] got report list for current project from db', data);
                    this.currentProjectReportListData = data.map((x) => new Report(x));
                    this.currentProjectReportListData.sort(this.utils.sortByCreatedAtPropertyDescending);
                    this.currentProjectReportList$.next(this.currentProjectReportListData);
                    this.reportListLoading$.next(false);
                },
                (err) => {
                    this.reportListLoading$.next(false);
                    this.logger.error('[PROJECT SERVICE] could not get report list for current project from db', err);
                    this.notify.error('Berichte für aktuelles Projekt konnten nicht geladen werden');
                    this.utils.sentryCaptureException(err);
                }
            );
    }

    /**
     * Archives the current project and returns to dashboard
     */
    public async archiveCurrentProject() {
        try {
            // make clone of current project and change archived status (clone beacuse we have to set currentProject to null because the db changefeed would sync the project immediately again while archiving)
            let archivedProject = this.utils.clone(this.currentProjectData);
            archivedProject.projectStatus = ProjectStatus.ARCHIVING;
            this.currentProjectData = null; // set to null to prevent db-changefeed from reloading and re-syncing the current project
            await this.db.put(archivedProject).toPromise();
            this.router.navigate(['/']);
            this.notify.success('Projekt wird archiviert. Dies kann einige Minuten dauern.', null, true, 7000);
            this.setCurrentProject(null); // set current project and observable subscribers to null
        } catch (archiveErr) {
            this.logger.error('[PROJECT SETTINGS] could not archive project', archiveErr);
            this.notify.error('Projekt konnte nicht archiviert werden.');
            this.utils.sentryCaptureException(archiveErr);
        }
    }

    /**
     * Deletes the current project and returns to dashboard
     */
    public async deleteCurrentProject() {
        try {
            // make clone of current project and change deleting status (clone beacuse we have to set currentProject to null because the db changefeed would sync the project immediately again while deleting)
            let deletingProject = this.utils.clone(this.currentProjectData);
            deletingProject.projectStatus = ProjectStatus.DELETING;
            this.currentProjectData = null; // set to null to prevent db-changefeed from reloading and re-syncing the current project
            await this.db.put(deletingProject).toPromise();
            this.router.navigate(['/']);
            this.notify.success('Projekt wird gelöscht. Dies kann einige Minuten dauern.', null, true, 7000);
            this.setCurrentProject(null); // set current project and observable subscribers to null
        } catch (delErr) {
            this.logger.error('[PROJECT SETTINGS] could not delete project', delErr);
            this.notify.error('Projekt konnte nicht gelöscht werden.');
            this.utils.sentryCaptureException(delErr);
        }
    }

    /**
     * Gets jobs with given parameters from db. Returns the whole job model
     * @param params
     */
    public getJobs(
        params: {
            projectId?: string;
            serviceGroupId?: string;
            jobNumberSorting?: 'asc' | 'desc';
            limit?: number;
            skip?: number;
        } = {}
    ) {
        return new Observable<Job[]>((observer) => {
            let projectId = this.currentProjectData._id;
            if (params.projectId != null) {
                projectId = params.projectId;
            }

            let mangoQuery: MangoQuery = {};
            mangoQuery.selector = {};

            if (params.projectId != null) {
                mangoQuery.selector.projectId = {
                    $eq: projectId,
                };
            }

            if (params.serviceGroupId != null) {
                mangoQuery.selector.serviceGroupId = {
                    $eq: params.serviceGroupId,
                };
            }

            if (params.jobNumberSorting === 'asc') {
                mangoQuery.sort = [{ sortOrder: 'asc' }];
            } else {
                mangoQuery.sort = [{ sortOrder: 'desc' }];
            }

            if (params.limit != null) {
                mangoQuery.limit = params.limit;
            }
            if (params.skip != null) {
                mangoQuery.skip = params.skip;
            }

            this.db.dbItems
                .find(mangoQuery)
                .exec()
                .then((res) => {
                    observer.next(res.map((x) => new Job(x.toJSON())));
                    observer.complete();
                })
                .catch((err) => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    /**
     * Pretty much the same as getJobs but only extracts basic info properties to reduce resource consumption
     * This works just like the normal getJobs function, with the difference that not the whole job model is returned.
     * This function only returns basic job properties like (_id, jobNumber, projectId, buildingPlanId, etc.)
     * @param params
     */
    public getJobsWithBasicInfo(
        params: {
            projectId?: string;
            serviceGroupId?: string;
            jobNumberSorting?: 'asc' | 'desc';
            limit?: number;
            skip?: number;
            planId?: string;
            type?: string;
        } = {}
    ) {
        return new Observable<BasicJobInfo[]>((observer) => {
            let projectId = this.currentProjectData?._id;
            if (params.projectId != null) {
                projectId = params.projectId;
            }

            let type = 'job';
            if (params.type != null) {
                type = params.type;
            }

            let mangoQuery: MangoQuery = {};
            mangoQuery.selector = {};

            mangoQuery.selector.projectId = {
                $eq: projectId,
            };
            mangoQuery.selector.type = {
                $eq: type,
            };

            if (params.serviceGroupId != null) {
                mangoQuery.selector.serviceGroupId = {
                    $eq: params.serviceGroupId,
                };
            }

            if (params.jobNumberSorting === 'asc') {
                mangoQuery.sort = [{ sortOrder: 'asc' }];
            } else {
                mangoQuery.sort = [{ sortOrder: 'desc' }];
            }

            if (params.limit != null) {
                mangoQuery.limit = params.limit;
            }
            if (params.skip != null) {
                mangoQuery.skip = params.skip;
            }

            if (params.planId != null) {
                mangoQuery.selector.buildingPlanId = {
                    $eq: params.planId,
                };
            }

            // force index if key values are provided to keep performance the same
            if (params.projectId != null && params.serviceGroupId != null) {
                mangoQuery.index = ['projectId', 'serviceGroupId'];
            }

            // // #3
            // if (params.planId) {
            //     mangoQuery.selector.buildingPlanId = {
            //         $eq: params.planId,
            //     };
            //     mangoQuery.selector.type = {
            //         $eq: 'job',
            //     };
            // }
            // mangoQuery.index = ['projectId', 'serviceGroupId'];

            // #2
            // if (params.planId) {
            //     mangoQuery.selector.buildingPlanId = {
            //         $eq: params.planId,
            //     };
            // }
            // mangoQuery.index = ['projectId', 'serviceGroupId'];

            // #1
            // mangoQuery.selector.relation = {
            //     $eq: 'project#' + params.projectId,
            // };
            // mangoQuery.selector.type = {
            //     $eq: 'job',
            // };
            // mangoQuery.selector.buildingPlanId = {
            //     $eq: params.planId,
            // };
            // mangoQuery.index = ['relation', 'type'];

            // exec and keep only data subset

            this.db.dbItems
                .find(mangoQuery)
                .exec()
                .then((res) => {
                    observer.next(
                        res.map((x) => {
                            return {
                                _id: x.get('_id'),
                                jobNumber: x.get('jobNumber'),
                                name: x.get('name'),
                                code: x.get('code'),
                                buildingPlanId: x.get('buildingPlanId'),
                                marker: x.get('marker'),
                                projectId: x.get('projectId'),
                                serviceGroupId: x.get('serviceGroupId'),
                                jobStatus: x.get('jobStatus'),
                            } as BasicJobInfo;
                        })
                    );
                    observer.complete();
                })
                .catch((err) => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    /**
     * Creates a new job in current project
     * The given serviceGroup is used for the job, otherwise the currentServiceGroup of the project service is used
     * If the parameter linkedJobIds is defined, the job property linkedJobIds will be initialized with this value
     */
    public createNewJob(serviceGroup?: ServiceGroup, linkedJobIds?: Array<string>, project?: Project) {
        return new Observable<Job>((observer) => {
            let usedServiceGroup = this.currentServiceGroupData;
            if (serviceGroup != null) {
                usedServiceGroup = serviceGroup;
            }

            let usedProject = this.currentProjectData;
            if (project != null) {
                usedProject = project;
            }

            this.getNextJobNumber(usedServiceGroup, usedProject).subscribe(
                (jobNumber) => {
                    let newJob = new Job();
                    newJob.projectId = usedProject._id;
                    newJob.serviceGroupId = usedServiceGroup._id;
                    newJob.relation = 'project#' + newJob.projectId;
                    newJob.name = usedServiceGroup.defaultJobName;
                    newJob.jobNumber = jobNumber;
                    newJob.fields = usedServiceGroup.fields;
                    let workerName = this.authSrv.authInfo.userData.userName;
                    if (this.authSrv.authInfo.userData.firstName != '' || this.authSrv.authInfo.userData.lastName != '') {
                        workerName = this.authSrv.authInfo.userData.firstName + ' ' + this.authSrv.authInfo.userData.lastName;
                    }
                    newJob.worker = workerName;

                    if (linkedJobIds != null) {
                        newJob.linkedJobIds = linkedJobIds;
                    }

                    // check if we need to create a notification for the new job
                    if (usedServiceGroup.settings.sendEmailNotificationOnNewJob) {
                        let notification = new DpNotification();
                        notification.type = 'job-created';
                        notification.name = 'Neue Dokumentation';
                        notification.notificationFor = 'company#' + this.authSrv.authInfo.companyData._id;
                        notification.relatedJob = newJob.pk;
                        notification.relatedJobName = newJob.name;
                        notification.relatedJobNumber = newJob.jobNumber;
                        notification.relatedProject = usedProject.pk;
                        notification.relatedProjectName = usedProject.name;
                        notification.creatorCompanyId = this.authSrv.authInfo.companyData._id;
                        notification.createdBy = this.authSrv.authInfo.userData.userName;
                        notification.createdByDisplayName = workerName;
                        newJob.notificationsToCreate.push(notification);
                    }

                    this.db.put(newJob).subscribe(
                        (res) => {
                            this.logger.log('[PROJECT SERVICE] created new job', newJob, res);
                            observer.next(new Job(res));
                            observer.complete();
                        },
                        (err) => {
                            this.logger.error('[PROJECT SERVICE] could not create job', err);
                            observer.error(err);
                            observer.complete();
                        }
                    );
                },
                (err) => {
                    this.logger.error('[PROJECT SERVICE] could not create job, because we could not get next job number', err);
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    /**
     * Deletes given job in db
     * Also handles job-extension cleanup and job-linking cleanup which is needed when the job gets deleted
     */
    public async deleteJob(job: Job) {
        this.logger.log('[PROJECT SERVICE] deleting job', job);
        // update linked jobs and remove linking to this current job (as we will delete it soon)
        if (job.linkedJobIds.length > 0) {
            try {
                this.logger.log('[PROJECT SERVICE] updating job linkings before deleting the job');

                let jobResults = await Promise.all(
                    job.linkedJobIds.map((jobId) => {
                        return this.db.getItemById(jobId).toPromise();
                    })
                );

                let mappedJobResults = jobResults.map((x) => new Job(x));
                for (let jobRes of mappedJobResults) {
                    let currentJobLinkIndex = jobRes.linkedJobIds.findIndex((x) => x === job._id);
                    if (currentJobLinkIndex > -1) {
                        jobRes.linkedJobIds.splice(currentJobLinkIndex, 1);
                    }
                }
                // update the linked jobs in db
                let jobUpdateResults = await Promise.all(
                    mappedJobResults.map((updatedJob) => {
                        return this.db.put(updatedJob).toPromise();
                    })
                );
            } catch (jobLinkUpdateErr) {
                this.logger.error('[PROJECT SERVICE] could not update job linkings when deleting job', jobLinkUpdateErr);
            }
        }

        // delete job extension items in db
        if (job.jobExtensions.length > 0) {
            for (let extension of job.jobExtensions) {
                try {
                    // delete one by one because we are saving deletion ids to local storage for upstream sync
                    let jobExtensionDeleteRes = await this.db.delete(extension._id).toPromise();
                    this.logger.log('[PROJECT SERVICE] deleted job extension before deleting job', jobExtensionDeleteRes);
                } catch (jobExtensionErr) {
                    this.logger.error('[PROJECT SERVICE] could not delete job extension', jobExtensionErr);
                }
            }
        }

        // delete actual job in db
        return await this.db.delete(job._id).toPromise();
    }

    /**
     * Clones a given job for the servicegroup. Cloning encompasses all job data for fields, service data etc.
     * but replaces jobNumber, code, sync info and updates the create info and update timestamps, as well as removes
     * weather data , signatures, images and the marker placement but keeps the building plan
     * @see createNewJob
     * @param base the job to clone
     * @param serviceGroup optional servicegroup the clone should be made for
     * @param linkedJobIds optional ids of Jobs the clone should be linked with
     * @returns Observable of the cloned job
     */
    public async duplicateJob(base: Job, serviceGroup?: ServiceGroup, linkedJobIds?: Array<string>) {
        let usedServiceGroup = this.currentServiceGroupData;
        if (serviceGroup != null) {
            usedServiceGroup = serviceGroup;
        }

        let nextJobNumber = await this.getNextJobNumber(usedServiceGroup).toPromise();

        // First create a safe copy
        let cloneTmp = new Job(this.utils.duplicateItem(base));

        // Update some needed properties for the current user and situation
        cloneTmp.serviceGroupId = usedServiceGroup._id;
        cloneTmp.relation = 'project#' + cloneTmp.projectId;
        cloneTmp.jobNumber = nextJobNumber;
        cloneTmp.date = Date.now();
        cloneTmp.createdAt = Date.now();
        cloneTmp.createdBy = null; // will be set by dbservice on put

        // remove infos which should be made fresh
        cloneTmp.code = '';
        cloneTmp.weather = undefined;
        cloneTmp.signatures = undefined;
        cloneTmp.photos = [];
        cloneTmp.marker = [];
        cloneTmp.linkedJobIds = undefined;

        // set the current creator
        let workerName = this.authSrv.authInfo.userData.userName;
        if (this.authSrv.authInfo.userData.firstName != '' || this.authSrv.authInfo.userData.lastName != '') {
            workerName = this.authSrv.authInfo.userData.firstName + ' ' + this.authSrv.authInfo.userData.lastName;
        }
        cloneTmp.worker = workerName;
        cloneTmp.creatorCompanyId = this.authSrv.authInfo.companyData._id;

        if (linkedJobIds != null) {
            cloneTmp.linkedJobIds = linkedJobIds;
        }

        // loop through job extensions and create new versions
        cloneTmp.jobExtensions = []; // reset

        let clonedjobExtensionItems = [];
        for (let jobExtensionInfo of base.jobExtensions) {
            let jobExtensionItem = await this.db.getItemById(jobExtensionInfo._id).toPromise();
            let newJobExtension = this.duplicateJobExtension(jobExtensionItem, workerName, cloneTmp.pk);

            await this.db.put(newJobExtension).toPromise();
            clonedjobExtensionItems.push(newJobExtension);
            cloneTmp.jobExtensions.push({
                _id: newJobExtension._id,
                version: newJobExtension.version,
            });
        }

        // calculate job status
        cloneTmp.jobStatus = this.utils.calculateJobStatus(usedServiceGroup, cloneTmp, clonedjobExtensionItems);

        let putResult = await this.db.put(cloneTmp).toPromise();
        return new Job(putResult);
    }

    /**
     * Duplicates job extension data and returns an instance with the cloned data
     * @param data this data is used to create a new job extension
     * @param worker name of the worker who created the new dataset
     * @param relatedJobId optional - used to link the given data to the job's id
     * @returns instance of JobExtension
     */
    public duplicateJobExtension(data: any, worker: string, relatedJobId?: string): JobExtension {
        let newJobExtension = new JobExtension(this.utils.duplicateItem(data));
        newJobExtension.worker = worker;
        if (relatedJobId) {
            newJobExtension.relatedJob = relatedJobId;
        }

        if (newJobExtension.article != null) {
            newJobExtension.article.photos = []; // reset photos
        }

        return newJobExtension;
    }

    /**
     * Gets the next job number for current project and/or serviceGroup
     * The given service group is used, otherwise the currentServiceGroup of the project service is used
     * The given project is used, otherwise the currentProject of the project service is used
     */
    private getNextJobNumber(serviceGroup?: ServiceGroup, project?: Project) {
        return new Observable<string>((observer) => {
            let usedServiceGroup = this.currentServiceGroupData;
            if (serviceGroup != null) {
                usedServiceGroup = serviceGroup;
            }

            let usedProject = this.currentProjectData;
            if (project != null) {
                usedProject = project;
            }

            this.getJobs({
                projectId: usedProject._id,
                serviceGroupId: usedServiceGroup._id,
                jobNumberSorting: 'desc',
                limit: 1,
            }).subscribe(
                (jobs: Array<any>) => {
                    let nextJobNumber: number | string = 1;

                    // Either use 1 as base or intelligently incrementing
                    // last numeric parts of strings and also keep leading zeros
                    if (jobs.length > 0) {
                        let m = jobs[0].jobNumber?.match(/\d+|\D+/g) || [0];

                        if (isNaN(m[m.length - 1])) {
                            m[m.length - 1] += 1;
                        } else {
                            m[m.length - 1] = String(parseFloat(m[m.length - 1]) + 1).padStart(m[m.length - 1].length, '0');
                        }

                        nextJobNumber = m.join('');
                    }

                    this.logger.log('[PROJECT SERVICE] got next job number', nextJobNumber);
                    observer.next(nextJobNumber.toString());
                    observer.complete();
                },
                (err) => {
                    this.logger.error('[PROJECT SERVICE] could not get next job number', err);
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    }

    /**
     * Takes given job identifier code as string and searches the db if it was already used
     * @param code job identifier code as string
     * @returns Observable with boolean if either used or unused
     */
    public isCodeUnused(code: string): Observable<boolean> {
        if (this.authSrv.authInfo?.companyData.companySettings.allowNonUniqueCodeUse) {
            this.logger.log('[PROJECT SERVICE] Non unique codes are allowed. Skipping check...');

            return of(true) as Observable<boolean>;
        } else {
            return this.searchJobs(code, false).pipe(
                switchMap((searchRes) => {
                    this.logger.log('[PROJECT SERVICE] got search result for job search', searchRes);

                    for (let res of searchRes) {
                        if (res.job && res.job.code == code) {
                            return of(false) as Observable<boolean>;
                        }
                    }

                    return of(true) as Observable<boolean>;
                })
            );
        }
    }

    /**
     * Searches for jobs COMPANY-WIDE by given searchs string (searches in name, jobNumber and code)
     * @param searchString
     * @param searchOnline
     */
    searchJobs(searchString, searchOnline = true) {
        return from(
            new Promise<any[]>((resolve) => {
                try {
                    let jobProjects: Array<Project> = []; // project data of jobs in search results
                    let results: Array<JobSearchResult> = [];

                    let localQuery = new Promise(async (resolve) => {
                        let dbRes: any[] = [];
                        try {
                            if (this.utils.isNative) {
                                dbRes = await this.db.dbItems
                                    .find({
                                        selector: {
                                            $and: [
                                                { type: { $eq: 'job' } },
                                                {
                                                    $or: [
                                                        { code: { $eq: searchString } },
                                                        { jobNumber: { $eq: searchString } },
                                                        { name: { $eq: searchString } },
                                                    ],
                                                },
                                            ],
                                        },
                                    })
                                    .exec();
                            } else {
                                let searchStringRegexp = new RegExp('.*' + searchString + '.*', 'i'); // case insensitive regex
                                dbRes = await this.db.dbItems
                                    .find({
                                        selector: {
                                            $and: [
                                                { type: { $eq: 'job' } },
                                                {
                                                    $or: [
                                                        { code: { $eq: searchString } },
                                                        { jobNumber: { $regex: searchStringRegexp } },
                                                        { name: { $regex: searchStringRegexp } },
                                                    ],
                                                },
                                            ],
                                        },
                                    })
                                    .exec();
                            }
                            dbRes = Array.isArray(dbRes) ? dbRes.map((x) => new Job(x.toJSON())) : [];
                        } catch (dbErr) {
                            this.logger.log('[PROJECT SVC] local job search failed', dbErr);
                        }
                        resolve(dbRes);
                    });

                    let onlineQuery: Promise<any[]>;

                    if (searchOnline) {
                        onlineQuery = new Promise(async (resolve) => {
                            let onlineSearchRes: any[] = [];
                            try {
                                onlineSearchRes = await this.http
                                    .post<Array<any>>(environment.AWS_API_URL + '/search-job-by-qrcode', {
                                        code: searchString,
                                        companyId: this.authSrv.authInfo.companyData._id,
                                    })
                                    .toPromise();
                            } catch (onlineErr) {
                                this.logger.log('[PROJECT SVC] online job search failed', onlineErr);
                            }
                            resolve(onlineSearchRes);
                        });
                    } else {
                        onlineQuery = new Promise((resolve) => {
                            resolve([]);
                        });
                    }

                    Promise.all([localQuery, onlineQuery])
                        .then(async (responses) => {
                            let localRes = responses[0];
                            let onlineRes = responses[1];

                            // Map results
                            let jobs = Array.isArray(localRes) ? localRes : [];

                            // check if online res is an array
                            if (Array.isArray(onlineRes)) {
                                jobs = jobs.concat(onlineRes);
                            }

                            for (let job of jobs) {
                                let projectFound = jobProjects.find((x) => x._id == job.projectId);
                                let singleResult: JobSearchResult = {
                                    job: job,
                                    dataSource: job.type != null ? 'local' : 'online',
                                    project: projectFound,
                                };
                                if (projectFound == null) {
                                    try {
                                        let projectData = new Project(await this.db.getItemById(job.projectId).toPromise());
                                        jobProjects.push(projectData);
                                        singleResult.project = projectData;
                                    } catch (projectQueryErr) {
                                        this.logger.log(
                                            '[PROJECT SVC] could not get project from db for job search result',
                                            projectQueryErr
                                        );
                                    }
                                }
                                if (singleResult.project != null) {
                                    let resultFound = results.find((x) => x.job._id === singleResult.job._id);
                                    if (resultFound == null) {
                                        results.push(singleResult);
                                    }
                                }
                            }
                            resolve(results);
                        })
                        .catch((responseErr) => {
                            this.logger.error('[PROJECT SVC] job search error', responseErr);
                            resolve([]);
                        });
                } catch (searchErr) {
                    this.logger.error('[PROJECT SVC] job search error', searchErr);
                    resolve([]);
                }
            })
        );
    }

    /**
     * Searches for jobs only in given project by given searchs string (searches in name, jobNumber, code and jobStatus fields)
     * @param searchString
     * @param projectId
     * @param jobSortOrder if search results shall be orderer asc or desc
     * @param serviceGroupId if given, search results will be limited to given service group and not whole project
     */
    searchJobsInProject(searchString: string, projectId: string, jobSortOrder: 'asc' | 'desc', serviceGroupId?: string) {
        return from(
            new Promise<Job[]>(async (resolve) => {
                try {
                    // this will hold our query options for the rxdb mango query
                    let dbQuerySelector: any = {
                        relation: { $eq: 'project#' + projectId },
                        type: { $eq: 'job' },
                    };

                    let searchStringAndSplit = searchString.toString().split(' '); // we split the string on spaces (all parts must match to be included in the results)

                    /* FIXME: Due to bug in RxDB or not using an index while querying over SQLite we have
                     *  severely impacted performance due to running a FULL SCAN over the database.
                     *  Therefore we only use regex in web based situations and manually compare regex matches
                     *  in JS when mobile
                     */
                    if (!this.utils.isNative) {
                        if (searchStringAndSplit.length === 1) {
                            // only one search string, so we build our rxdb query accordingly
                            let searchStringRegexp = new RegExp('.*' + searchString + '.*', 'i'); // case insensitive regex
                            // case insensitive regex would be more performant if the index would be used correclty
                            // let searchStringRegexp = '^' + searchString;
                            dbQuerySelector['$or'] = [
                                { code: { $eq: searchString } },
                                { jobNumber: { $regex: searchStringRegexp } },
                                { name: { $regex: searchStringRegexp } },
                                { 'fields.value': { $regex: searchStringRegexp } },
                                { 'jobStatus.customIconFields.value': { $regex: searchStringRegexp } },
                            ];
                        } else {
                            // multiple search parts, so we have to build the query with AND selector so that all search strings have to match
                            let andSelectorArray = [];
                            for (let splitEntry of searchStringAndSplit) {
                                let splitEntryRegexp = new RegExp('.*' + splitEntry + '.*', 'i'); // case insensitive regex
                                andSelectorArray.push({
                                    $or: [
                                        { code: { $eq: splitEntry } },
                                        { jobNumber: { $regex: splitEntryRegexp } },
                                        { name: { $regex: splitEntryRegexp } },
                                        { 'fields.value': { $regex: splitEntryRegexp } },
                                        { 'jobStatus.customIconFields.value': { $regex: splitEntryRegexp } },
                                    ],
                                });
                            }
                            dbQuerySelector['$and'] = andSelectorArray;
                        }
                    }

                    // add our service group to the search params
                    if (serviceGroupId != null) {
                        dbQuerySelector.serviceGroupId = { $eq: serviceGroupId };
                    }

                    // now query the rxdb based on built query selector
                    let dbRes = await this.db.dbItems
                        .find({
                            selector: dbQuerySelector,
                            sort: [{ sortOrder: jobSortOrder === 'asc' ? 'asc' : 'desc' }],
                            index: ['relation', 'type'], // for performance optimization tell rxdb which index to use
                        })
                        .exec();

                    // In case of being native and having db results we do the in memory search now
                    if (this.utils.isNative && Array.isArray(dbRes)) {
                        // let searchStringRegexp = '^' + searchString;
                        let splitEntryRegexp: RegExp | Array<RegExp>;

                        if (searchStringAndSplit.length === 1) {
                            splitEntryRegexp = new RegExp('.*' + searchString + '.*', 'i');
                        } else {
                            splitEntryRegexp = searchStringAndSplit.map((s) => new RegExp('.*' + s + '.*', 'i'));
                        }

                        // we could refactor this to remove toJSON and directly access the properties but there is no real benefit
                        dbRes = dbRes.filter((x: any) => {
                            let j = x.toJSON() as Job;

                            if (Array.isArray(splitEntryRegexp)) {
                                let matchDict: any = {};
                                for (let i = 0; i < splitEntryRegexp.length; i++) {
                                    // Careful as we use the split search items as well as the regex patterns array
                                    if (j.code == searchStringAndSplit[i] && !matchDict[i]) {
                                        matchDict[i] = true;
                                    }
                                    if (j.jobNumber.toString().match(splitEntryRegexp[i]) != null && !matchDict[i]) {
                                        matchDict[i] = true;
                                    }
                                    if (j.name?.match(splitEntryRegexp[i]) != null && !matchDict[i]) {
                                        matchDict[i] = true;
                                    }
                                    if (j.fields?.length > 0 && !matchDict[i]) {
                                        for (let f of j.fields) {
                                            if (f.value?.toString().match(splitEntryRegexp[i]) != null) {
                                                matchDict[i] = true;
                                            }
                                        }
                                    }
                                    if (j.jobStatus?.customIconFields?.length > 0 && !matchDict[i]) {
                                        for (let cif of j.jobStatus.customIconFields) {
                                            if (cif.value?.toString().match(splitEntryRegexp[i]) != null) {
                                                matchDict[i] = true;
                                            }
                                        }
                                    }
                                }

                                return Object.keys(matchDict).length >= splitEntryRegexp.length;
                            } else {
                                if (j.code == searchString) return true;
                                if (j.jobNumber.toString().match(splitEntryRegexp) != null) return true;
                                if (j.name?.match(splitEntryRegexp) != null) return true;
                                if (j.fields?.length > 0) {
                                    for (let f of j.fields) {
                                        if (f.value?.toString().match(splitEntryRegexp) != null) {
                                            return true;
                                        }
                                    }
                                }
                                if (j.jobStatus?.customIconFields?.length > 0) {
                                    for (let cif of j.jobStatus.customIconFields) {
                                        if (cif.value?.toString().match(splitEntryRegexp) != null) {
                                            return true;
                                        }
                                    }
                                }
                            }

                            return false;
                        });
                    }

                    dbRes = Array.isArray(dbRes) ? dbRes.map((x) => new Job(x.toJSON())) : [];
                    resolve(dbRes);
                } catch (searchErr) {
                    this.logger.error('[PROJECT SVC] job search in project error', searchErr);
                    resolve([]);
                }
            })
        );
    }

    /**
     * Helper function which can be used as sort function to sort jobs by jobNumber
     */
    sortJobsByJobNumber(a, b) {
        a = a.jobNumber;
        b = b.jobNumber;

        let aParsed = parseFloat(a);
        let bParsed = parseFloat(b);

        if (!isNaN(aParsed) && !isNaN(bParsed)) {
            if (aParsed == bParsed) {
                return 0;
            } else if (aParsed > bParsed) {
                return 1;
            } else {
                return -1;
            }
        } else {
            let aReplaced = parseFloat(a.replace(/[^0-9.]/g, ''));
            let bReplaced = parseFloat(b.replace(/[^0-9.]/g, ''));
            // check if the replaced values are valid numbers, then sort with these numbers
            if (!isNaN(aReplaced) && !isNaN(bReplaced)) {
                if (aReplaced == bReplaced) {
                    return 0;
                } else if (aReplaced > bReplaced) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a == b) {
                    return 0;
                } else if (a > b) {
                    return 1;
                } else {
                    return -1;
                }
            }
        }
    }

    /**
     * Duplicates given service group
     * @param base the service group to duplicate
     * @returns Observable of the cloned service group
     */
    public async duplicateServiceGroup(base: ServiceGroup) {
        // First create a safe copy
        let cloneTmp = new ServiceGroup(this.utils.duplicateItem(base, true));

        // Reset documents
        cloneTmp.documents = [];

        cloneTmp.creatorCompanyId = this.authSrv.authInfo.companyData._id;

        // loop through all articles and set the documents to an empty array
        for (let article of cloneTmp.articles) {
            article.documents = [];
        }

        delete cloneTmp.liS3Path;

        let putResult = await this.db.put(cloneTmp).toPromise();
        return new ServiceGroup(putResult);
    }

    /**
     * Automatically creates jobs for given service group based on available services.
     */
    public async autoCreateJobsForServiceGroup(projectServiceGroup: ServiceGroup, project: Project = null) {
        try {
            let targetProject = this.currentProjectData;
            if (project != null) {
                targetProject = project;
            }

            // loop through services of service group
            let articles = projectServiceGroup.articles.filter((a) => a.skipAutoCreation != true);
            for (let serviceToCreate of articles) {
                // create job in db
                let createdJob = await this.createNewJob(projectServiceGroup, null, targetProject).toPromise();

                // create job extension with service
                let extensionModel = new JobExtension();
                extensionModel.relation = 'project#' + targetProject._id;
                extensionModel.relatedJob = 'job#' + createdJob._id;

                let extensionArticle = new Article(serviceToCreate, true);
                extensionArticle.pricing = new ArticlePricing(); // reset pricing as we dont need to store it
                extensionModel.article = extensionArticle;

                // get the current user name
                let workerName = this.authSrv.authInfo.userData.userName;
                if (this.authSrv.authInfo.userData.firstName != '' || this.authSrv.authInfo.userData.lastName != '') {
                    workerName = this.authSrv.authInfo.userData.firstName + ' ' + this.authSrv.authInfo.userData.lastName;
                }
                extensionModel.worker = workerName;

                // push job extension to db
                let extensionPut = await this.db.put(extensionModel).toPromise();

                // add job extension info to our job
                extensionModel = new JobExtension(extensionPut);
                createdJob.jobExtensions.push({
                    _id: extensionModel._id,
                    version: extensionModel.version,
                });

                // refresh job status
                createdJob.jobStatus = this.utils.calculateJobStatus(projectServiceGroup, createdJob, [extensionModel]);

                // adjust job name
                if (this.settingsSrv.getCompanySettings().adjustJobNameBasedOnArticles === true) {
                    createdJob.name = extensionModel.article.name;
                }

                // update job in db again
                let updatedJob = await this.db.put(createdJob).toPromise();
            }
        } catch (autoCreateErr) {
            this.logger.error('could not auto-create jobs for service group', autoCreateErr);
            this.notify.error('Dokumentationen konnten nicht automatisch angelegt werden');
        }
    }
}
