<ng-container *ngIf="icon">
    <!-- dp icons (colorable) -->
    <ng-container *ngIf="icon.iconType == 'dp'">
        <div
            class="field-icon-preview-wrap"
            [ngStyle]="{ width: limitSize != null ? limitSize + 'px' : '100%', height: limitSize != null ? limitSize + 'px' : '100%' }"
        >
            <ng-container *ngIf="lazyLoad; else noLazyLoad">
                <ion-img
                    class="field-icon article-icon-selection-img dp"
                    [src]="icon.iconName"
                    [ngClass]="{ 'icon-rounded': rounded }"
                    (ionImgDidLoad)="onImgLoad($event)"
                ></ion-img>
            </ng-container>
            <ng-template #noLazyLoad>
                <img [src]="icon.iconName" alt="Symbol" class="field-icon-img" (load)="onImgLoad($event, false)" />
            </ng-template>
        </div>
    </ng-container>

    <!-- dp icons (not colorable) - just the same as above, but without svg-injecting and coloring -->
    <ng-container *ngIf="icon.iconType == 'dpimg'">
        <div
            class="field-icon-preview-wrap"
            [ngStyle]="{ width: limitSize != null ? limitSize + 'px' : '100%', height: limitSize != null ? limitSize + 'px' : '100%' }"
        >
            <ng-container *ngIf="lazyLoad; else noLazyLoad">
                <ion-img
                    class="field-icon article-icon-selection-img dpimg"
                    [src]="icon.iconName"
                    [ngClass]="{ 'icon-rounded': rounded }"
                ></ion-img>
            </ng-container>
            <ng-template #noLazyLoad>
                <img [src]="icon.iconName" alt="Symbol" class="field-icon-img" />
            </ng-template>
        </div>
    </ng-container>

    <ng-container *ngIf="icon.iconType == 'fa'">
        <div class="fa-icon-wrap">
            <fa-icon
                *ngIf="icon.iconName != null && icon.iconName != undefined"
                class="field-icon"
                [icon]="icon.iconName"
                [fixedWidth]="true"
                [size]="faIconSize"
                [ngStyle]="{ color: color }"
            ></fa-icon>
        </div>
    </ng-container>
</ng-container>
