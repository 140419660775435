<ion-app [class.ui-mobile]="utilSvc.mobileViewActive">
	<app-mobile-menu></app-mobile-menu>
	<div id="main" [class.loggedin]="authSvc.isLoggedIn">
		<app-header></app-header>

		<div id="startup-loader-wrap" *ngIf="startupLoaderVisible">
			<div id="startup-loader-content">
				<ion-spinner name="crescent"></ion-spinner>
				<div>Daten werden vorbereitet</div>
			</div>
		</div>

        <div class="auth-device-lock">
            <div class="device-lock-info ion-text-center">
                <div>Dieser Benutzer ist gerade auf einem anderen Gerät aktiv. <br>Klicke "Hier verwenden" um DokuPit stattdessen hier zu verwenden.</div>
                <div *ngIf="nextUnlockPossibleString != ''" class="dp-mt-m ion-color-danger">
                    Du kannst dein aktives Gerät nicht so oft in kurzer Zeit wechseln. Der nächste Wechsel ist <strong>{{nextUnlockPossibleString}}</strong> möglich.
                </div>
                <ion-button class="dp-mt-m" (click)="unlockDevice()" size="large" [dpIsLoading]="unlockDeviceLoading">
                    <fa-icon icon="unlock" slot="start"></fa-icon>
                    Hier verwenden
                </ion-button>
            </div>
        </div>

		<ion-content scrollX="false" scrollY="false">
			<ion-router-outlet [animated]="utilSvc && utilSvc.mobileViewActive"></ion-router-outlet>
		</ion-content>
	</div>
</ion-app>
