import { BaseItem } from '../core/db/base-item.model';
import { Address } from '../core/shared-models/address.model';
import { Attachment } from '../core/attachment/attachment.model';
import { Field } from '../service-groups/articles/article-details/field/field.model';
import { DpNotification } from '../core/shared-models/notification.model';
import { CalendarEntry } from '../calendar/calendar-entry.model';

export class Project extends BaseItem {
    name: string;
    projectNumber: string;
    folder: string;
    projectAddress: Address;
    timeTrackingExpenseAllowance: string;
    client: {
        name: string;
        address: Address;
    };
    contacts: Array<ProjectContactInfo>;
    additionalFields: Array<ProjectAdditionalFieldCategory>;
    description: string;
    documents: Array<ProjectDocument>;
    projectImage: Attachment;
    projectSharingMethod: 'all' | 'selected';
    projectStatus: ProjectStatus;
    notificationsToCreate: Array<DpNotification>; // list of notifications which will be created in online db on next sync
    calendarEntriesToCreate: Array<CalendarEntry>; // calendar entries which will be created in online db on next sync
    legacy: boolean; // true if the project was migrated from v1

    constructor(data?: any) {
        super(data, false);

        this.type = 'project';
        this.name = '';
        this.projectNumber = '';
        this.folder = null;
        this.projectAddress = new Address();
        this.timeTrackingExpenseAllowance = null;
        this.client = {
            name: '',
            address: new Address(),
        };
        this.contacts = [];
        this.additionalFields = [];
        this.description = '';
        this.documents = [];
        this.projectSharingMethod = 'selected';
        this.projectImage = null;
        this.projectStatus = ProjectStatus.INPROGRESS;
        this.notificationsToCreate = [];
        this.calendarEntriesToCreate = [];
        this.legacy = false;

        if (data != null) {
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.projectNumber != null) {
                this.projectNumber = data.projectNumber;
            }
            if (data.folder != null) {
                this.folder = data.folder;
            }
            if (data.projectAddress != null) {
                this.projectAddress = new Address(data.projectAddress);
            }
            if (data.timeTrackingExpenseAllowance != null) {
                this.timeTrackingExpenseAllowance = data.timeTrackingExpenseAllowance;
            }
            if (data.client != null) {
                this.client = data.client;
            }
            if (data.contacts != null) {
                this.contacts = data.contacts.map((x) => new ProjectContactInfo(x));
            }
            if (data.additionalFields != null) {
                this.additionalFields = data.additionalFields.map((x) => new ProjectAdditionalFieldCategory(x));
            }
            if (data.description != null) {
                this.description = data.description;
            }
            if (data.documents != null) {
                this.documents = data.documents.map((x) => new ProjectDocument(x));
            }
            if (data.projectSharingMethod != null) {
                this.projectSharingMethod = data.projectSharingMethod;
            }
            if (data.projectStatus != null) {
                this.projectStatus = data.projectStatus as ProjectStatus;
            }
            if (data.notificationsToCreate != null) {
                this.notificationsToCreate = data.notificationsToCreate;
            }
            if (data.calendarEntriesToCreate != null) {
                this.calendarEntriesToCreate = data.calendarEntriesToCreate.map((x) => new CalendarEntry(x));
            }
            if (data.legacy != null) {
                this.legacy = data.legacy;
            }
            if (data.projectImage != null) {
                this.projectImage = data.projectImage as Attachment;
            }
        }

        // add project prefix to pk
        if (this.pk != null && !this.pk.startsWith('project#')) {
            this.pk = 'project#' + this._id;
            this.sk = 'project#' + this._id;
        }

        // add relation if it is not set
        if (this.relation == null) {
            this.relation = 'project#' + this._id;
        }
    }
}

export class ProjectContactInfo {
    name: string;
    phone: string;
    email: string;
    company: string;
    position: string;

    constructor(data?: any) {
        this.name = '';
        this.phone = '';
        this.email = '';
        this.company = '';
        this.position = '';

        if (data != null) {
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.phone != null) {
                this.phone = data.phone;
            }
            if (data.email != null) {
                this.email = data.email;
            }
            if (data.company != null) {
                this.company = data.company;
            }
            if (data.position != null) {
                this.position = data.position;
            }
        }
    }
}

export class ProjectAdditionalFieldCategory {
    categoryName: string;
    fields: Array<Field>;

    constructor(data?: any) {
        this.categoryName = '';
        this.fields = [];

        if (data != null) {
            if (data.categoryName != null) {
                this.categoryName = data.categoryName;
            }
            if (data.fields != null) {
                this.fields = data.fields.map((x) => new Field(x));
            }
        }
    }
}

export class ProjectDocument extends Attachment {
    confirmationNeeded: boolean;
    confirmationLicenseTypes: Array<string>;
    confirmationSignatures: Array<ProjectDocumentSignature>;

    constructor(data?: any) {
        super(data);

        this.confirmationNeeded = false;
        this.confirmationLicenseTypes = ['mobile'];
        this.confirmationSignatures = [];

        if (data != null) {
            if (data.confirmationNeeded != null) {
                this.confirmationNeeded = data.confirmationNeeded;
            }
            if (data.confirmationLicenseTypes != null) {
                this.confirmationLicenseTypes = data.confirmationLicenseTypes;
            }
            if (data.confirmationSignatures != null) {
                this.confirmationSignatures = data.confirmationSignatures.map((x) => new ProjectDocumentSignature(x));
            }
        }
    }
}

export class ProjectDocumentSignature {
    userName: string;
    signature: Attachment;

    constructor(data?: any) {
        this.userName = null;
        this.signature = null;

        if (data != null) {
            if (data.userName != null) {
                this.userName = data.userName;
            }
            if (data.signature != null) {
                this.signature = new Attachment(data.signature);
            }
        }
    }
}

// MinimalProjectInfo comes from the index creatorCompanyId-projectStatus-index
export interface MinimalProjectInfo {
    pk: string;
    sk: string;
    _id: string;
    name: string;
    projectNumber: string;
    projectStatus: ProjectStatus;
    projectAddress: Address;
    createdAt: number;
    creatorCompanyId: string;
    updatedAt: number;
}

export enum ProjectStatus {
    INPROGRESS = 'in-progress',
    COMPLETED = 'completed',
    DELETING = 'deleting',
    ARCHIVING = 'archiving',
    DELETED = 'deleted',
    ARCHIVED = 'archived',
    RESTORING_FROM_ARCHIVE = 'restoring-from-archive',
    RESTORING_FROM_TRASH = 'restoring-from-trash',
}

export interface ProjectListEntry {
    entryType: 'project' | 'folder';
    project?: Project;
    folder?: string;
    folderProjects?: Array<Project>;
}
