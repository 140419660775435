import { AuthService } from './../../../auth/auth.service';
import { DbService } from './../../db/db.service';
import { last, map, switchMap } from 'rxjs/operators';
import { UploadService } from './../../upload/upload.service';
import { PlanAttachment } from './../../../projects/project-details/project-building-plans/plan-attachment.model';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { AttachmentSelectComponent } from './../attachment-select/attachment-select.component';

import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import { CameraService } from '../../camera/camera.service';
import { LoggerService } from '../../logger/logger.service';
import { AttachmentService } from '../attachment.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { UtilsService } from '../../utils/utils.service';
import { Storage } from '@ionic/storage-angular';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../notification/notification.service';
import { HttpResponse } from 'aws-sdk';
import { from } from 'rxjs';
import { BuildingPlan, PlanStatus } from 'src/app/projects/project-details/project-building-plans/building-plan.model';
import * as moment from 'moment';
import * as Sentry from '@sentry/capacitor';
import { SettingsService } from 'src/app/settings/settings.service';

// Response from API POST /plans
interface PlanPrepareResponse {
    statusCode: number;
    body: {
        uploadTarget: string;
        planId: string;
    };
}

@Component({
    selector: 'app-plan-attachment-select',
    templateUrl: './plan-attachment-select.component.html',
    styleUrls: ['./plan-attachment-select.component.scss'],
})
export class PlanAttachmentSelectComponent extends AttachmentSelectComponent {
    // ViewChilds are not inherited cause the template is not inherited either
    @ViewChild('fileSelectDialog') fileSelectDialog: DialogComponent;
    @ViewChild('fileSelectInput') fileSelectInput: ElementRef;

    @Input() buttonStyle: 'button' | 'fab' = 'button';
    @Input() project = null; // Needed for our API request
    @Input() btnIcon = 'upload'; // used for fab
    @Input() btnSize: 'large' | 'small' = 'large'; // used for fab
    // @Input() btnColor: string = "success";
    @Input() fabInline = false; // When set it does not render the ion-fab tag; useful for adding this button inside an existing fab list
    @Input() tooltipPosition = 'bottom';
    @Input() replacePlan: BuildingPlan = undefined; // ID of building plan, When set the chosen file will replace this plan

    constructor(
        protected storage: Storage,
        protected cameraSrv: CameraService,
        protected attachmentSrv: AttachmentService,
        public utils: UtilsService,
        protected logger: LoggerService,
        protected notify: NotificationService,
        protected settingsSrv: SettingsService,
        private httpClient: HttpClient,
        private uploadSrv: UploadService,
        private db: DbService,
        private authSrv: AuthService
    ) {
        super(storage, cameraSrv, attachmentSrv, utils, logger, notify, settingsSrv);
    }

    async ngOnInit() {
        super.ngOnInit();

        if (this.project == null) {
            throw new Error('A project ID is needed in order to process plans');
        }
    }

    /**
     * Triggered when the user chooses one or multiple files via the choose-dialog or the dropzone.
     * They will be uploaded (or saved for later upload) and if everything has finished the fileSelected Event will be triggered
     */
    async onFilesChosen(files: FileList | Array<File>) {
        this.logger.log('[PLAN ATTACHMENT SELECT] files chosen', files);

        let filesArray;
        if (!Array.isArray(files)) {
            filesArray = Array.from(files);
        } else {
            filesArray = files;
        }
        let attachments: PlanAttachment[] = [];

        let allowFileUpload = true;
        for (let file of filesArray) {
            if (!this.allowedImageMimeTypes.includes(file.type) && file.type !== 'application/pdf') {
                allowFileUpload = false;
            }
        }

        // check if file upload is allowed
        if (!allowFileUpload) {
            this.notify.error('Das Hochladen dieses Datei-Typs ist nicht erlaubt!');
            return;
        }

        // reset upload progress (only used if directUpload is true)
        this.totalBytesToUpload = 0;
        this.uploadedBytesPerAttachment = [];
        this.uploadedBytes = 0;

        // create attachment models for each chosen file
        for (let file of filesArray) {
            this.totalBytesToUpload += file.size;

            let attachment = new PlanAttachment();
            attachment.displayName = file.name;
            attachment.fileName = attachment.displayName;
            attachment.fileMimeType = file.type;
            attachments.push(attachment);
        }

        this.logger.log('[PLAN ATTACHMENT SELECT] uploading files now');
        this.currentlyUploading = true;
        let successfulAttachments = [];

        try {
            let fileIndex = 0;
            for (let file of filesArray) {
                let buildingPlanDisplayName = this.utils.cutFileEnding(attachments[fileIndex].displayName);
                // First we need to ask our API where to put this file
                let apiResponse = await this.httpClient
                    .post<PlanPrepareResponse>(
                        `${environment.AWS_API_URL}/plans`,
                        {
                            project: this.project,
                            planName: buildingPlanDisplayName,
                            fileMimeType: attachments[fileIndex].fileMimeType,
                            creator:
                                (this.authSrv.authInfo?.userData.firstName != '' || this.authSrv.authInfo?.userData.lastName != ''
                                    ? `${this.authSrv.authInfo?.userData.firstName} ${this.authSrv.authInfo?.userData.lastName}`
                                    : this.authSrv.authInfo?.userData.userName) || '',
                            companyId: this.authSrv.authInfo?.companyData._id,
                            replaceId: this.replacePlan ? this.replacePlan._id : undefined,
                        },
                        {
                            headers: new HttpHeaders({
                                'Content-Type': 'application/json',
                            }),
                        }
                    )
                    .toPromise();

                // then we actually upload it
                this.logger.log('[PLAN ATTACHMENT SELECT] got API response for direct upload', file);

                if (apiResponse.statusCode == 200) {
                    if (this.replacePlan) {
                        // first clone our plan for replacement or the binding will overwrite our data on the fly again
                        let tmpPlan = this.utils.clone(this.replacePlan);
                        tmpPlan.dataStatus = PlanStatus.Pending;

                        await this.db.put(tmpPlan, false, 'if-newer').toPromise();
                    } else {
                        let tmpPlan = new BuildingPlan();
                        tmpPlan._id = apiResponse.body.planId;
                        tmpPlan.pk = 'building-plan#' + tmpPlan._id;
                        tmpPlan.sk = 'building-plan#' + tmpPlan._id;
                        tmpPlan.name = buildingPlanDisplayName;
                        tmpPlan.version = -1;
                        tmpPlan.projectId = this.project;
                        tmpPlan.relation = 'project#' + this.project;
                        tmpPlan.dataStatus = PlanStatus.Pending;
                        await this.db.put(tmpPlan, false, 'if-newer').toPromise();
                    }

                    await this.uploadSrv
                        .uploadFile(apiResponse.body.uploadTarget, file)
                        .pipe(
                            map((event) => {
                                if (event && event.type == HttpEventType.UploadProgress) {
                                    let existingProgress = this.uploadedBytesPerAttachment.find(
                                        (x) => x.attachmentId == attachments[fileIndex]._id
                                    );

                                    if (existingProgress) {
                                        existingProgress.uploadedBytes = event.loaded;
                                    } else {
                                        this.uploadedBytesPerAttachment.push({
                                            attachmentId: attachments[fileIndex]._id,
                                            uploadedBytes: event.loaded,
                                        });
                                    }
                                    let calculateUploadedBytes = 0;
                                    for (let progressPerAttachment of this.uploadedBytesPerAttachment) {
                                        calculateUploadedBytes += progressPerAttachment.uploadedBytes;
                                    }
                                    this.uploadedBytes = calculateUploadedBytes;
                                } else if (event instanceof HttpResponse) {
                                    this.logger.log('[PLAN ATTACHMENT SELECT] file completely uploaded');
                                }

                                return event;
                            }),
                            last() // only return completed event
                        )
                        .toPromise();

                    this.logger.log('[PLAN ATTACHMENT SELECT] direct upload for attachment successful', attachments[fileIndex]);
                    successfulAttachments.push(attachments[fileIndex]);
                    fileIndex++;
                } else {
                    this.logger.error('[PLAN ATTACHMENT SELECT] Upload failed', attachments[fileIndex]);
                    this.notify.error(
                        'Ein oder mehrere Dateien konnten nicht hochgeladen werden. Bitte prüfe deine Verbindung und versuche es erneut.'
                    );
                }
            }
        } catch (uploadErr) {
            this.logger.error('[PLAN ATTACHMENT SELECT] could not upload files to endpoint', uploadErr);
            this.notify.error(
                'Ein oder mehrere Dateien konnten nicht hochgeladen werden. Bitte prüfe deine Verbindung und versuche es erneut.'
            );
        }

        // Wrapping up
        if (this.dialog) this.fileSelectDialog.close();
        if (this.fileSelectInput) {
            this.fileSelectInput.nativeElement.value = ''; // reset input to allow new uploads
        }
        if (successfulAttachments.length == 1) {
            this.fileSelected.emit(successfulAttachments[0]); // emit only one item instead of array, if there is only one entry
        } else {
            this.fileSelected.emit(successfulAttachments);
        }
        this.currentlyUploading = false;
    }

    /**
     * Triggered when the user clicks the 'open-camera' button
     */
    // onTakePhoto() {
    // 	throw new Error("Not yet implemented");
    // }

    onTakePhoto() {
        this.logger.log('[PLAN ATTACHMENT SELECT] trying to take photo');

        let photoFileName;
        let photoFileMimeType;

        this.cameraSrv
            .takePhoto(false)
            .pipe(
                switchMap((cameraPhoto) => {
                    photoFileName = 'Planfoto_' + moment().format('DD_MM_YYYY_HH_mm_ss') + '.' + cameraPhoto.format;
                    photoFileMimeType = 'image/' + cameraPhoto.format;

                    if (!this.allowedImageMimeTypes.includes(photoFileMimeType)) {
                        throw new Error('file mime type not allowed');
                    }

                    if (!cameraPhoto.webPath && cameraPhoto.base64String) {
                        this.logger.warn('[PLAN ATTACHMENT SELECT] camera photo item should have path but base64 found');
                        return from(fetch(`data:${photoFileMimeType};base64,${cameraPhoto.base64String}`));
                    }

                    return from(fetch(cameraPhoto.webPath));
                }),
                switchMap((fetchData) => fetchData.blob())
            )
            .subscribe(
                (res) => {
                    this.logger.log('[PLAN ATTACHMENT SELECT] saved attachment to disk', res);
                    let fileData = new File([res], photoFileName, { type: photoFileMimeType });
                    this.onFilesChosen([fileData]);
                },
                (err) => {
                    // check if file upload is allowed
                    if (err.message === 'file mime type not allowed') {
                        this.notify.error('Das Hochladen dieses Datei-Typs ist nicht erlaubt!');
                        return;
                    }
                    this.logger.error('[PLAN ATTACHMENT SELECT] failed to take photo', err);
                    this.utils.sentryCaptureException(err, 'error');
                    if (this.dialog) this.fileSelectDialog.close();
                }
            );
    }
}
