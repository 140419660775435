import { BaseItem } from '../core/db/base-item.model';
import { Article } from './articles/article.model';
import { Field, FieldType } from './articles/article-details/field/field.model';
import { Attachment } from '../core/attachment/attachment.model';
import { CameraOrientation } from '../core/camera/camera-orientation.enum';

export class ServiceGroup extends BaseItem {
    name: string;
    defaultJobName: string;
    articles: Array<Article>;
    fields: Array<Field>;
    hasCustomIconInJobList: boolean; // true if the service group contains a select field which wants to show an custom icon in job list
    hasCustomIconOnBuildingPlan: boolean; // true if the service group contains a select field which wants to show an custom icon on building plan
    hasArticlesWithPhotos: boolean; // true if the service group contains articles which have individual photo settings
    originalId: string;
    originalName: string; // the original service group name
    projectId: string;
    settings: ServiceGroupSettings;
    documents: Array<Attachment>;
    syncedServiceGroup: boolean; // true if certain settings shall be taken from the original service group instead of the values saved to this service group (used for job articles and fields)
    legacy: boolean; // true if the serviceGroup was migrated from v1 (job template in v1)

    constructor(data?: any, removePropertiesForNesting = false) {
        super(data, removePropertiesForNesting);

        this.type = 'service-group';
        this.name = '';
        this.defaultJobName = 'Dokumentation';
        this.articles = [];
        this.fields = [];
        this.hasCustomIconInJobList = false;
        this.hasCustomIconOnBuildingPlan = false;
        this.hasArticlesWithPhotos = false;
        this.originalId = null;
        this.originalName = '';
        this.projectId = null;
        this.settings = new ServiceGroupSettings();
        this.documents = [];
        this.syncedServiceGroup = true;
        this.legacy = false;

        if (data != null) {
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.defaultJobName != null) {
                this.defaultJobName = data.defaultJobName;
            }
            if (data.articles != null) {
                this.articles = data.articles.map((x) => new Article(x, true));
            }
            if (data.fields != null) {
                this.fields = data.fields.map((x) => new Field(x));
            }
            if (data.hasCustomIconInJobList != null) {
                this.hasCustomIconInJobList = data.hasCustomIconInJobList;
            }
            if (data.hasCustomIconOnBuildingPlan != null) {
                this.hasCustomIconOnBuildingPlan = data.hasCustomIconOnBuildingPlan;
            }
            if (data.hasArticlesWithPhotos != null) {
                this.hasArticlesWithPhotos = data.hasArticlesWithPhotos;
            }
            if (data.originalId != null) {
                this.originalId = data.originalId;
            }
            if (data.originalName != null) {
                this.originalName = data.originalName;
            }
            if (data.projectId != null) {
                this.projectId = data.projectId;
            }
            if (data.settings != null) {
                this.settings = new ServiceGroupSettings(data.settings);
            }
            if (data.documents != null) {
                this.documents = data.documents.map((x) => new Attachment(x));
            }
            if (data.syncedServiceGroup != null) {
                this.syncedServiceGroup = data.syncedServiceGroup;
            }
            if (data.legacy != null) {
                this.legacy = data.legacy;
            }
        }

        // add service-group prefix to pk
        if (this.pk != null && !this.pk.startsWith('service-group#')) {
            this.pk = 'service-group#' + this._id;
            this.sk = 'service-group#' + this._id;
        }
    }

    /**
     * This function checks if the service group contains select fields with the property "showIconInJobList".
     * It sets the service group property hasCustomIconInJobList to true, if there are fields with this property
     */
    checkForCustomIconsToShowInJobList() {
        let hasCustomIconInJobList = false;
        for (let field of this.fields) {
            if (field.showIconInJobList) {
                hasCustomIconInJobList = true;
                break;
            }
        }
        if (hasCustomIconInJobList == false) {
            for (let article of this.articles) {
                for (let field of article.fields) {
                    if (this.checkFieldCustomIconDisplaySetting(field, 'joblist-custom-icons')) {
                        hasCustomIconInJobList = true;
                        break;
                    }
                }
                if (hasCustomIconInJobList == true) {
                    break;
                }
            }
        }
        this.hasCustomIconInJobList = hasCustomIconInJobList;
    }

    /**
     * This function checks if the service group contains select fields with the property "showIconOnBuildingPlan".
     * It sets the service group property hasCustomIconOnBuildingPlan to true, if there are fields with this property
     */
    checkForCustomIconsToShowOnBuildingPlan() {
        let hasCustomIconOnBuildingPlan = false;
        for (let field of this.fields) {
            if (field.showIconOnBuildingPlan) {
                hasCustomIconOnBuildingPlan = true;
                break;
            }
        }
        if (hasCustomIconOnBuildingPlan == false) {
            for (let article of this.articles) {
                for (let field of article.fields) {
                    if (this.checkFieldCustomIconDisplaySetting(field, 'building-plan-custom-icons')) {
                        hasCustomIconOnBuildingPlan = true;
                        break;
                    }
                }
                if (hasCustomIconOnBuildingPlan == true) {
                    break;
                }
            }
        }
        this.hasCustomIconOnBuildingPlan = hasCustomIconOnBuildingPlan;
    }

    /**
     * Helper function to determine if a service group has custom icons which need to be shown in job list or building plan
     * @param field
     * @param checkType
     * @private
     */
    private checkFieldCustomIconDisplaySetting(field: Field, checkType: 'joblist-custom-icons' | 'building-plan-custom-icons') {
        if (field.type === FieldType.Group) {
            let subFieldTrue = false;
            for (let subField of field.subFields) {
                if (this.checkFieldCustomIconDisplaySetting(subField, checkType)) {
                    subFieldTrue = true;
                    break;
                }
            }
            return subFieldTrue;
        } else {
            if (checkType === 'joblist-custom-icons') {
                return field.showIconInJobList;
            } else if (checkType === 'building-plan-custom-icons') {
                return field.showIconOnBuildingPlan;
            } else {
                return false;
            }
        }
    }

    /**
     * This function checks if the service group contains articles which have individual photo settings.
     * It sets the service group property hasArticlePhotos to true, if there are articles with photos
     */
    checkForArticlePhotos() {
        let hasArticlesWithPhotos = false;
        for (let article of this.articles) {
            if (article.photoCategories != null && article.photoCategories.length > 0) {
                hasArticlesWithPhotos = true;
                break;
            }
        }
        this.hasArticlesWithPhotos = hasArticlesWithPhotos;
    }
}

export class ServiceGroupSettings {
    dataRecording: {
        photoCategories: Array<ServiceGroupPhotoCategory>;
        locationRecording: DataRecordingSetting;
        locationMarkerColor: string;
        multipleLocationMarker: boolean;
        startMobileDocumentationOnPlan: boolean;
        codeRecording: DataRecordingSetting;
        weatherRecording: DataRecordingSetting;
        signatureRecording: DataRecordingSetting;
    };
    sendEmailNotificationOnNewJob: boolean;
    autoCreateDocumentationsInProject: boolean; // if true all services will be created as new jobs when creating a project
    headingAdditionalFields: string;
    positionAdditionalFields: 'below' | 'above';

    constructor(data?: any) {
        this.dataRecording = {
            photoCategories: [],
            locationRecording: DataRecordingSetting.Required,
            locationMarkerColor: '#009CE0',
            multipleLocationMarker: false,
            startMobileDocumentationOnPlan: false,
            codeRecording: DataRecordingSetting.Required,
            weatherRecording: DataRecordingSetting.Disabled,
            signatureRecording: DataRecordingSetting.Disabled,
        };
        this.sendEmailNotificationOnNewJob = false;
        this.autoCreateDocumentationsInProject = false;
        this.headingAdditionalFields = 'Zusätzliche Daten';
        this.positionAdditionalFields = 'below';

        if (data != null) {
            if (data.dataRecording != null) {
                if (data.dataRecording.photoCategories != null) {
                    this.dataRecording.photoCategories = data.dataRecording.photoCategories.map((x) => new ServiceGroupPhotoCategory(x));
                }
                if (data.dataRecording.locationRecording != null) {
                    this.dataRecording.locationRecording = data.dataRecording.locationRecording;
                }
                if (data.dataRecording.locationMarkerColor != null) {
                    this.dataRecording.locationMarkerColor = data.dataRecording.locationMarkerColor;
                }
                if (data.dataRecording.multipleLocationMarker != null) {
                    this.dataRecording.multipleLocationMarker = data.dataRecording.multipleLocationMarker;
                }
                if (data.dataRecording.startMobileDocumentationOnPlan != null) {
                    this.dataRecording.startMobileDocumentationOnPlan = data.dataRecording.startMobileDocumentationOnPlan;
                }
                if (data.dataRecording.codeRecording != null) {
                    this.dataRecording.codeRecording = data.dataRecording.codeRecording;
                }
                if (data.dataRecording.weatherRecording != null) {
                    this.dataRecording.weatherRecording = data.dataRecording.weatherRecording;
                }
                if (data.dataRecording.signatureRecording != null) {
                    this.dataRecording.signatureRecording = data.dataRecording.signatureRecording;
                }
            }
            if (data.sendEmailNotificationOnNewJob) {
                this.sendEmailNotificationOnNewJob = data.sendEmailNotificationOnNewJob;
            }
            if (data.autoCreateDocumentationsInProject) {
                this.autoCreateDocumentationsInProject = data.autoCreateDocumentationsInProject;
            }
            if (data.headingAdditionalFields != null) {
                this.headingAdditionalFields = data.headingAdditionalFields;
            }
            if (data.positionAdditionalFields != null) {
                this.positionAdditionalFields = data.positionAdditionalFields;
            }
        }
    }
}

export class ServiceGroupPhotoCategory {
    categoryName: string;
    categoryRecording: DataRecordingSetting;
    categoryMinAmount: number;
    lockCameraOrientation: CameraOrientation;
    readonly: boolean;

    constructor(data?: any) {
        this.categoryName = '';
        this.categoryRecording = DataRecordingSetting.Required;
        this.categoryMinAmount = 1;
        this.lockCameraOrientation = CameraOrientation.NONE;

        if (data != null) {
            if (data.categoryName != null) {
                this.categoryName = data.categoryName;
            }
            if (data.categoryRecording != null) {
                this.categoryRecording = data.categoryRecording;
            }
            if (data.categoryMinAmount != null) {
                this.categoryMinAmount = data.categoryMinAmount;
            }
            if (data.lockCameraOrientation != null) {
                this.lockCameraOrientation = data.lockCameraOrientation;
            }
            if (data.readonly) {
                this.readonly = true;
            }
        }
    }
}

export enum DataRecordingSetting {
    Required = 'required',
    Optional = 'optional',
    Disabled = 'disabled',
}
