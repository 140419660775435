import { ElementRef, Input, Output, EventEmitter, Component, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'app-dp-backdrop',
    templateUrl: './dp-backdrop.component.html',
    styleUrls: ['./dp-backdrop.component.scss'],
})
export class DpBackdropComponent implements OnInit, OnDestroy {
    @Output() onTap = new EventEmitter();
    @Input() autoDismiss = true;
    @Input() appendTo: string = undefined; // the nearest CSS selector where this backdrop should be appended to
    @Input() show = false;

    element: any;

    constructor(private el: ElementRef) {
        this.element = this.el.nativeElement;
    }

    ngOnInit() {
        if (this.appendTo) {
            this.element.closest(this.appendTo).appendChild(this.element);
            // document.getElementsByTagName(this.appendTo)[0].appendChild(this.element);
        }
    }

    ngOnDestroy() {
        this.element?.remove();
    }

    onBackdropTap() {
        if (this.autoDismiss) {
            this.show = false;
        }
        this.onTap.emit();
    }
}
