import { LoggerService } from './../logger/logger.service';
import { debounceTime } from 'rxjs/operators';
import { Job, JobStatus, JobStatusIcon } from './../../projects/project-details/project-jobs/job.model';
import { DataRecordingSetting, ServiceGroup } from './../../service-groups/service-group.model';
import { Field, FieldType } from './../../service-groups/articles/article-details/field/field.model';
import { Injectable, OnDestroy } from '@angular/core';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
    AbstractControl,
    FormArray,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Platform } from '@ionic/angular';
import { Attachment } from '../attachment/attachment.model';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import packageJson from './../../../../package.json';
import { App } from '@capacitor/app';
import { ConnectionStatus, Network } from '@capacitor/network';

import { ReportFilter } from '../../projects/project-details/project-reports/report.model';
import { JobExtension } from '../../projects/project-details/project-jobs/job-details/job-extension/job-extension.model';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { CompanySettings } from '../shared-models/company.model';
import { Address } from '../shared-models/address.model';
import { CalendarEntry } from '../../calendar/calendar-entry.model';
import { Project } from '../../projects/project.model';
import { addBreadcrumb, captureException, Scope, SeverityLevel, withScope, captureMessage } from '@sentry/capacitor';

const mime = require('mime');

@Injectable({
    providedIn: 'root',
})
export class UtilsService implements OnDestroy {
    private _supportsWebP = false;
    private _mobileBreakpoint = 1020; // the max screen width where the UI should switch to a mobile/touch layout
    private _phoneBreakpoint = 728; // the max screen width where the app and ui should behave like on a phone (e.g. use modals)

    isBetaVersion = packageJson.isBeta;
    versionInfo = packageJson.version;
    nativeVersionInfo = undefined;

    private _isOnline = false;
    get isOnline() {
        return this._isOnline;
    }

    set isOnline(val: boolean) {
        if (this._isOnline != val) {
            this.onlineChanged.next(val);
        }

        this._isOnline = val;
    }

    isFileTypeDirectory(fileType) {
        return fileType === 'NSFileTypeDirectory' || fileType === 'directory';
    }

    isFileTypeFile(fileType) {
        return fileType === 'NSFileTypeRegular' || fileType === 'file';
    }

    isNative = false;
    isPhone = false;
    isTablet = false;

    isKeyboardOpen = false;
    mobileViewActive = false; // for CSS specific breakpoints, no real feature dependency
    fullWindowHeight = 0;

    mobileMainMenuDidClose = new BehaviorSubject<void>(undefined);
    mobileMainMenuDidOpen = new BehaviorSubject<void>(undefined);
    splitPaneDidToggle = new BehaviorSubject<boolean>(undefined); // emitted when toggleSplitPaneMenu was called, false means the menu is currently closed and toggles to being opened
    tabLock = new BehaviorSubject<boolean>(false);

    onlineChanged = new BehaviorSubject<boolean>(this._isOnline); // emitted when connected or disconnected
    latestCompanySettings: CompanySettings = null; // updated from settings-service which sets this variable drectly
    os = 'unknown';

    private keyboardDidShowFn = (e) => {
        this.isKeyboardOpen = true;
    };
    private keyboardDidHideFn = () => {
        this.isKeyboardOpen = false;
        this.fullWindowHeight = this.platform.height();
    };

    constructor(
        private platform: Platform,
        private logger: LoggerService,
        private fb: UntypedFormBuilder
    ) {
        this.checkWebPSupport()
            .then((_) => {
                this._supportsWebP = true;
            })
            .catch((f) => {
                this._supportsWebP = false;
            });

        this.isNative = this.platform.is('hybrid') || this.platform.is('electron');
        if (this.isNative) {
            App.getInfo()
                .then((info) => {
                    this.nativeVersionInfo = info.version;
                })
                .catch((appErr) => {
                    this.sentryCaptureException(appErr, 'warning');
                });
        }

        // Detect our running environment on start...
        this.platform.ready().then((_) => {
            this.fullWindowHeight = this.platform.height();
            this.detectPlatformEnvironment();
        });
        // ... and changing screen size
        this.platform.resize.pipe(debounceTime(300)).subscribe((_) => {
            this.calcDimensionsAndDisplayProps();
        });

        // preset our online state based on the navigator
        this.isOnline = navigator.onLine;

        Network.getStatus()
            .then((status) => {
                this.logger.log('[UTILS] initial network status', status);
                this.isOnline = status.connected;
            })
            .catch((statusErr) => {
                this.logger.error('[UTILS] failed getting initial network status', statusErr);
                this.sentryCaptureException(statusErr);
            })
            .finally(() => {
                this.logger.log('[UTILS] Adding listener for n');
                Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
                    this.logger.log('[UTILS] network change', status);
                    this.isOnline = status.connected;
                });
            });

        // Using window listeners cause Capacitor plugin in v1.0.2 bugs out with detection
        window.addEventListener('keyboardDidShow', this.keyboardDidShowFn);
        window.addEventListener('keyboardDidHide', this.keyboardDidHideFn);
    }

    ngOnDestroy(): void {
        // Clear network detection
        Network.removeAllListeners();

        window.removeEventListener('keyboardDidShow', this.keyboardDidShowFn);
        window.removeEventListener('keyboardDidHide', this.keyboardDidHideFn);
    }

    /**
     * Returns a new uuid
     */
    generateUuid() {
        return uuidv4();
    }

    /**
     * Helper to either get your desired number value or the screen's width
     * @returns either the given number or the screen width whichever is the smaller
     */
    getMinByScreenWidth(num: number) {
        return Math.min(this.platform.width(), num);
    }

    /**
     * Returns a deep clone of given data
     * @param object the object to clone
     */
    clone(object: any) {
        // TODO: create own deepCopy function to remove lodash from project
        // We can't just use JSON.stringify and .parse due to possible cyclic structures and spread operator only makes shallow copies
        return _cloneDeep(object);
    }

    /**
     * Returns chunked array of given array
     */
    chunkArray(array: Array<any>, chunkSize: number) {
        let chunks = [];
        let i = 0;
        let n = array.length;

        while (i < n) {
            chunks.push(array.slice(i, (i += chunkSize)));
        }

        return chunks;
    }

    /**
     * Duplicates a given item by cloning the item, generating a new id and removing the revision and other basic item properties which are needed
     * @param item
     * @param addCopyString
     */
    duplicateItem(item: any, addCopyString = false) {
        let clone = this.clone(item);
        let oldId = item._id;
        clone._id = this.generateUuid();

        if (clone.pk != null) {
            clone.pk = clone.pk.replace(oldId, clone._id);
        }
        if (clone.sk != null) {
            clone.sk = clone.sk.replace(oldId, clone._id);
        }

        if (addCopyString) {
            clone.name = clone.name != null ? `${clone.name} Kopie` : null;
        }

        delete clone._rev;
        delete clone.synced;
        delete clone.syncedAt;
        delete clone.ttl;
        delete clone.createdBy;
        delete clone.updatedBy;

        if (clone.createdAt != null) {
            clone.createdAt = Date.now();
        }
        if (clone.updatedAt != null) {
            clone.updatedAt = Date.now();
        }

        if (clone.version != null) {
            clone.version = 1;
        }

        return clone;
    }

    /**
     * Duplicates given field model
     * @param sourceField
     */
    duplicateField(sourceField: Field) {
        let fieldClone = new Field(this.duplicateItem(sourceField, true));
        if (Array.isArray(fieldClone.subFields)) {
            let clonedSubFields: Array<Field> = [];
            let subFieldIdMapping = [];
            for (let subField of fieldClone.subFields) {
                let oldId = subField._id;
                let clonedSubField = this.duplicateField(subField);
                clonedSubFields.push(clonedSubField);
                subFieldIdMapping.push({
                    oldId: oldId,
                    newId: clonedSubField._id,
                });
            }
            // as subfields are now cloned we try to replace old ids in field visibility conditions
            for (let clonedField of clonedSubFields) {
                if (clonedField.condition != null && Array.isArray(clonedField.condition.conditionFields)) {
                    for (let field of clonedField.condition.conditionFields) {
                        // replace old condition field if with new field id after cloning
                        let idMappingFound = subFieldIdMapping.find((x) => x.oldId === field._id);
                        field._id = idMappingFound?.newId;
                    }
                    // remove conditions where no mapping existed - e.g. fields that have broken condition data or lingering condition data no longer used
                    clonedField.condition.conditionFields = clonedField.condition.conditionFields.filter((f) => f._id != null);
                }
            }
            fieldClone.subFields = clonedSubFields;
        }
        return fieldClone;
    }

    /**
     * Returns fileName by cutting the file ending of given fileName
     * @param fileName
     */
    cutFileEnding(fileName: string) {
        return fileName.replace(/\.[^/.]+$/, '');
    }

    /**
     * Returns fileEnding by cutting it off
     * @param fileName
     */
    getFileEnding(fileName: string) {
        return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
    }

    /**
     * Returns the mime type for given file ending
     * @param fileEnding
     */
    getMimeTypeForFileEnding(fileEnding: string) {
        return mime.getType(fileEnding);
    }

    /**
     * Takes given array and returns a formArray
     * @param array The given array
     */
    arrayToFormArray(array: Array<any>) {
        const formArray = new UntypedFormArray([]);

        for (const item of array) {
            if (typeof item === 'object' && item != null) {
                formArray.push(this.objectToFormGroup(item));
            } else {
                formArray.push(new UntypedFormControl(item));
            }
        }
        return formArray;
    }

    /**
     * Check if a form group's controls contain at least some value
     * @param fg the group to check
     * @returns boolean if it has value(s)
     */
    groupHasValue(fg: UntypedFormGroup) {
        return Object.values(fg.controls).some((fc) => {
            if (fc.value instanceof Object) {
                return Object.values(fc.value).some((iv) => !!iv);
            } else {
                return !!fc.value;
            }
        });
    }

    /**
     * Takes given object and returns a formGroup
     * @param object The given object
     * @param requiredControls Array of property names that shall be required
     */
    objectToFormGroup(object: object, requiredControls?: Array<string>) {
        const formGroup = new UntypedFormGroup({});
        for (const propertyName of Object.keys(object)) {
            const propertyValue = object[propertyName];
            const propertyRequired = requiredControls?.includes(propertyName);
            if (propertyValue == null) {
                if (propertyRequired) {
                    formGroup.addControl(propertyName, new UntypedFormControl(object[propertyName], Validators.required));
                } else {
                    formGroup.addControl(propertyName, new UntypedFormControl(object[propertyName]));
                }
            } else if (Array.isArray(propertyValue)) {
                formGroup.addControl(propertyName, this.arrayToFormArray(propertyValue));
            } else if (typeof propertyValue === 'object') {
                formGroup.addControl(propertyName, this.objectToFormGroup(propertyValue, requiredControls));
            } else {
                if (propertyRequired) {
                    formGroup.addControl(propertyName, new UntypedFormControl(object[propertyName], Validators.required));
                } else {
                    formGroup.addControl(propertyName, new UntypedFormControl(object[propertyName]));
                }
            }
        }
        return formGroup;
    }

    /**
     * Checks given object if it has an attachment included, by checking for path properties
     * Returns the attachment objects which were found
     * @param object
     */
    findAttachmentInObject(object: object) {
        let attachments: Attachment[] = [];
        let propertyToCheck = 'filePath';

        for (let key of Object.keys(object)) {
            if (key == propertyToCheck) {
                // found
                attachments.push(new Attachment(object));
            } else if (typeof object[key] == 'object' && object[key] != null) {
                // recursive check nested objects
                let subCheckAttachments = this.findAttachmentInObject(object[key]);
                if (subCheckAttachments.length > 0) {
                    attachments = attachments.concat(subCheckAttachments.map((x) => new Attachment(x)));
                }
            }
        }
        return attachments;
    }

    /**
     * Checks given object if it has an attachment included, by checking for path properties
     * Returns the attachment objects which were found
     * @param object
     */
    findAttachmentFilePathInObject(object: object) {
        let propertyToCheck = 'filePath';

        for (let key of Object.keys(object)) {
            if (key == propertyToCheck) {
                // found
                return object[key];
            } else if (typeof object[key] == 'object' && object[key] != null) {
                // recursive check nested objects
                return this.findAttachmentInObject(object[key]);
            }
        }
        return undefined;
    }

    /**
     * Updates the app's environment variables like being native or small mobile screen etc.
     */
    private detectPlatformEnvironment() {
        const smallest = Math.min(this.platform.width(), this.platform.height());
        const largest = Math.max(this.platform.width(), this.platform.height());
        this.logger.log('[UTILS] sm and lg screen dims', [smallest, largest]);

        // get current os name
        this.os = this.detectOS();
        // add current os as CSS class
        document.body.classList.add(this.os);

        // NOTICE: fix the edge case of the Huawei phablet keyboard - we'll need some sort of os specific detection. iOS max 767 for phones, android goes deeper down to 728 ...
        // this.isPhone = this.platform.is("mobile") && smallest <= 460 && (this.platform.is("android") || this.platform.is("ios"));
        // this.isPhone = this.platform.is("mobile") && smallest <= 767 && (this.platform.is("android") || this.platform.is("ios"));
        // this.logger.log("[UTILS] Dimensions", smallest, largest, ...this.platform.platforms());

        this.isPhone =
            (this.platform.is('mobile') || this.platform.is('phablet') || this.platform.is('capacitor')) &&
            smallest < this._phoneBreakpoint &&
            (this.platform.is('android') || this.platform.is('ios'));
        // TODO: check if there are overlaps of devices where isPhone and isTablet are both true
        this.isTablet =
            this.platform.is('ipad') ||
            ((this.platform.is('mobile') || this.platform.is('capacitor')) &&
                smallest > 460 &&
                smallest < 820 &&
                largest > 780 &&
                largest < 1400 &&
                (this.platform.is('android') || this.platform.is('ios')));

        this.calcDimensionsAndDisplayProps();

        // NOTICE: cordova-plugin-screen-orientation does not work with all our compatible iOS and Android versions
        // we will limit the screen rotation by using the native IDEs tools in the meantime
        //
        // Smartphones should not be able to rotate the screen as the UI is optimized for portrait
        this.applyBaseScreenlock();
        // Keyboard.setResizeMode({ mode: KeyboardResize.Body });
    }

    private detectOS() {
        let userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os: 'MacOS' | 'iOS' | 'Windows' | 'Android' | 'Linux' = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'MacOS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    public applyBaseScreenlock() {
        if (this.isPhone) {
            this.logger.log(`[UTILS] Applying screen rotation lock for dimensions ${this.platform.width()} x ${this.platform.height()}`);

            // apply general portrait lock for phones but wait a delay in case of foregoing screenlocks or unlocks (e.g. camera)
            setTimeout((_) => {
                ScreenOrientation.lock({ orientation: 'portrait' }).catch((err) => {
                    this.logger.warn('[UTILS] Could not lock orientation', err);
                });
            }, 400);
        } else {
            ScreenOrientation.unlock().catch((err) => {
                console.warn('[UTILS] Could not unlock orientation', err);
            });
        }
    }

    /**
     * Updates flags like mobileViewActive based on the current screen sizes
     */
    private calcDimensionsAndDisplayProps() {
        // this.mobileViewActive = this.platform.width() <= this._mobileBreakpoint || (this.platform.width() <= 1400 && this.platform.height() <= 768);
        this.mobileViewActive =
            this.platform.width() <= this._mobileBreakpoint ||
            (this.platform.width() <= 1400 && this.platform.height() <= 834) ||
            this.platform.is('ipad') ||
            this.platform.is('capacitor');

        if (!this.isKeyboardOpen) {
            this.fullWindowHeight = this.platform.height();
        }
    }

    /**
     * Removes a trailing slash from a given string. Be careful though as "/" strings end up being ""
     * @returns string without trailing slash
     */
    public stripTrailingSlash(str: string): string {
        return str.endsWith('/') ? str.slice(0, -1) : str;
    }

    /**
     * Adds a trailing slash from a given string. Be careful though as "/" strings end up being ""
     * @returns string with trailing slash
     */
    public addTrailingSlash(str: string): string {
        return str.endsWith('/') ? str : str + '/';
    }

    public stripFileExtension(str: string): string {
        return str.replace(/\.[^/.]+$/, '');
    }

    /**
     * Sort function used to .sort() by createdAt property ascending
     */
    public sortByCreatedAtPropertyAscending(x, y) {
        return x.createdAt - y.createdAt;
    }

    /**
     * Sort function used to .sort() by createdAt property descending
     */
    public sortByCreatedAtPropertyDescending(x, y) {
        return y.createdAt - x.createdAt;
    }

    /**
     * Transforms given isoDateString to a timestamp in milliseconds
     * @param isoDateString
     * @constructor
     */
    isoDateStringToTimestamp(isoDateString: string) {
        return moment.utc(isoDateString).valueOf();
    }

    /**
     * Transforms given timestamp in milliseconds to isoDateString
     * @param timestamp
     * @constructor
     */
    timestampToIsoDateString(timestamp: number) {
        return moment(timestamp).toISOString();
    }

    /**
     * Converts given dataURI to blob
     * @param dataURI
     * @param mimeType
     */
    dataURItoBlob(dataURI, mimeType) {
        let binary = atob(dataURI.split(',')[1]);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: mimeType });
    }

    /**
     * Get acronym of given text
     * @param text
     */
    getAcronym(text: string) {
        if (text == null || text == '') {
            return '';
        } else {
            return text
                .trim()
                .split(/\s/)
                .reduce((accumulator, word) => accumulator + word.charAt(0), '');
        }
    }

    /**
     * A bit of a hack but currently only working way to get a working desktop split pane menu while having our
     * mobile layout for e.g. smaller window sizes
     * Use this method for an ion-split-pane layout with ion-menu where each subpage needs to toggle the menu
     */
    toggleSplitPaneMenu(identifier: string) {
        // const splitPane = document.querySelector("ion-split-pane");
        const splitPane = document.getElementById(identifier);
        const windowWidth = window.innerWidth;
        const when = this.getSplitPaneWhen();
        if (windowWidth >= this._mobileBreakpoint) {
            // split pane view is visible
            const open = (splitPane as any).when === when;
            (splitPane as any).when = open ? false : when;
            this.splitPaneDidToggle.next(!!open);
        } else {
            // split pane view is not visible
            // toggle menu open
            const menu = splitPane.querySelector('ion-menu');
            this.splitPaneDidToggle.next(false);
            return menu.open();
        }
    }

    getSplitPaneWhen() {
        return `(min-width: ${this._mobileBreakpoint}px)`;
    }

    /**
     * Get WebP support in browser
     * @returns {boolean} true on WebP support
     */
    supportsWebP() {
        return this._supportsWebP;
    }

    /**
     * Detect browser's WebP support
     * @returns {Promise} resolves on support and rejects when not
     */
    checkWebPSupport() {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = function () {
                resolve(null);
            };
            img.onerror = function () {
                reject();
            };
            img.src = '../../../assets/img/1.webp';
        });

        /**
         * Alternative way which does not work in Safari and Firefox
         * as their toDataUrl always returns pngs
         * var elem = document.createElement("canvas");

         if (!!(elem.getContext && elem.getContext("2d"))) {
			// was able or not to get WebP representation
			return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
		} else {
			// very old browser like IE 8, canvas not supported
			return false;
		}
         */
    }

    /**
     * helper for sending the tab locked info to reacting components
     */
    toggleTabLock() {
        this.tabLock.next(!this.tabLock.value);
    }

    /**
     * Check if field is an empty array
     * @param arrayToCheck the array
     */
    isEmptyArray(arrayToCheck) {
        return Array.isArray(arrayToCheck) && arrayToCheck.length < 1;
    }

    /**
     * Calculates the given jobs status
     * @param currentServiceGroup servicegroup this job belongs to
     * @param job the job to check
     * @param jobExtensions jobExtensions data of the job if they shall also be taken into account
     * @param refresh what status to refresh (all -> refreshes the whole job status, data / photos / map / code only refreshes the given part, data-photos refreshes the 2 parts data & photos)
     * @returns status object containing booleans for the individual job completion steps
     */
    calculateJobStatus(
        currentServiceGroup: ServiceGroup,
        job: Job,
        jobExtensions?: Array<JobExtension>,
        refresh: 'all' | 'data' | 'photos' | 'map' | 'code' | 'data-photos' = 'all'
    ): JobStatus {
        let dataOk = true;
        let photosOk = true;
        let mapOk = true;
        let codeOk = true;

        let jobArticles = job.articles;
        let jobFields = job.fields;

        let dataStatusCalculationNeeded = true;
        let photosStatusCalculationNeeded = true;
        let mapStatusCalculationNeeded = true;
        let codeStatusCalculationNeeded = true;
        let customIconCalculationNeeded = true;
        let buildingPlanCustomIconCalculationNeeded = true;

        // check if we can skip certain calculations
        if (job.jobStatus != null) {
            if (refresh === 'data') {
                // only refresh data status
                photosStatusCalculationNeeded = false;
                mapStatusCalculationNeeded = false;
                codeStatusCalculationNeeded = false;
                customIconCalculationNeeded = false;
                buildingPlanCustomIconCalculationNeeded = true;
            } else if (refresh === 'photos') {
                // only refresh photos status
                dataStatusCalculationNeeded = false;
                mapStatusCalculationNeeded = false;
                codeStatusCalculationNeeded = false;
                customIconCalculationNeeded = false;
                buildingPlanCustomIconCalculationNeeded = false;
            } else if (refresh === 'map') {
                // only refresh map status
                dataStatusCalculationNeeded = false;
                photosStatusCalculationNeeded = false;
                codeStatusCalculationNeeded = false;
                customIconCalculationNeeded = false;
                buildingPlanCustomIconCalculationNeeded = false;
            } else if (refresh === 'code') {
                // only refresh code status
                dataStatusCalculationNeeded = false;
                photosStatusCalculationNeeded = false;
                mapStatusCalculationNeeded = false;
                customIconCalculationNeeded = false;
                buildingPlanCustomIconCalculationNeeded = false;
            } else if (refresh === 'data-photos') {
                // only refresh data and photo stati
                mapStatusCalculationNeeded = false;
                codeStatusCalculationNeeded = false;
                customIconCalculationNeeded = false;
                buildingPlanCustomIconCalculationNeeded = true;
            }
        }

        if (dataStatusCalculationNeeded) {
            if (jobArticles.length > 0) {
                for (let article of jobArticles) {
                    if (dataOk === true) {
                        for (let field of article.fields) {
                            let fieldStatus = this.checkFieldFilledStatus(field, article.fields);
                            if (!fieldStatus.ok) {
                                dataOk = false;
                                break;
                            }
                        }
                    } else {
                        break;
                    }
                }
            }

            let atLeastOneAdditionalFieldHasValue = false;
            if (dataOk) {
                // we only need to check this if the data is still ok
                for (let field of jobFields) {
                    let fieldStatus = this.checkFieldFilledStatus(field, jobFields);
                    if (!fieldStatus.ok) {
                        dataOk = false;
                    }
                    if (fieldStatus.filled) {
                        atLeastOneAdditionalFieldHasValue = true;
                    }
                }
            }

            // if the data is still ok, check job extensions data if there are any
            let atLeastOneJobExtensionHasValue = false;
            if (dataOk && jobExtensions != null && jobExtensions.length > 0) {
                for (let jobExtension of jobExtensions) {
                    if (dataOk) {
                        for (let field of jobExtension.article.fields) {
                            let fieldStatus = this.checkFieldFilledStatus(field, jobExtension.article.fields);
                            if (!fieldStatus.ok) {
                                dataOk = false;
                            }
                            if (fieldStatus.filled) {
                                atLeastOneJobExtensionHasValue = true;
                            }
                        }
                    } else {
                        break;
                    }
                }
            }

            if (dataOk) {
                // if the data is still ok check if the job is empty (no articles or fields documented)
                if (jobArticles.length == 0 && atLeastOneAdditionalFieldHasValue === false && atLeastOneJobExtensionHasValue === false) {
                    // this job currently has no articles and there are no additional fields filled
                    if (currentServiceGroup.articles.length > 0 || jobFields.length > 0) {
                        // there are available articles which could be selected, or there are job fields which could be filled -> data is not ok
                        dataOk = false;
                    }
                }
            }
        }

        if (photosStatusCalculationNeeded) {
            // calculate photosOK based on required photos of service group
            for (let photoCategory of currentServiceGroup.settings.dataRecording.photoCategories) {
                if (photoCategory.categoryRecording == DataRecordingSetting.Required) {
                    let jobPhotoCount = job.photos.filter((x) => x.photoCategoryName == photoCategory.categoryName).length;
                    if (jobPhotoCount < photoCategory.categoryMinAmount) {
                        photosOk = false;
                        break;
                    }
                }
            }

            // if the photos are still ok, check job extensions photos
            if (photosOk && jobExtensions != null && jobExtensions.length > 0) {
                for (let jobExtension of jobExtensions) {
                    if (photosOk) {
                        for (let photoCategory of jobExtension.article.photoCategories) {
                            if (photoCategory.categoryRecording == DataRecordingSetting.Required) {
                                let jobPhotoCount = jobExtension.article.photos.filter(
                                    (x) => x.photoCategoryName == photoCategory.categoryName
                                ).length;
                                if (jobPhotoCount < photoCategory.categoryMinAmount) {
                                    photosOk = false;
                                    break;
                                }
                            }
                        }
                    } else {
                        break;
                    }
                }
            } else if (
                currentServiceGroup.hasArticlesWithPhotos &&
                currentServiceGroup.settings.dataRecording.photoCategories.findIndex(
                    (pc) => pc.categoryRecording == DataRecordingSetting.Required
                ) == -1
            ) {
                // if the service group has articles with photos but no job extensions are available and no photo categories on global level, set photo status to false
                photosOk = false;
            }
        }

        if (mapStatusCalculationNeeded) {
            // handle building plan status
            if (
                currentServiceGroup.settings.dataRecording.locationRecording == DataRecordingSetting.Required &&
                (job.buildingPlanId == '' || job.marker.length < 1)
            ) {
                mapOk = false;
            }
        }

        if (codeStatusCalculationNeeded) {
            // handle code status
            if (
                currentServiceGroup.settings.dataRecording.codeRecording == DataRecordingSetting.Required &&
                (job.code == '' || job.code == undefined)
            ) {
                codeOk = false;
            }
        }

        let showCustomIconFields: Array<JobStatusIcon> = [];
        if (customIconCalculationNeeded) {
            // check if the current service group uses 'status' fields, then we have to calculate them
            if (currentServiceGroup.hasCustomIconInJobList) {
                // handle custom icons of job additional fields
                for (let field of job.fields) {
                    let customIcons = this.getCustomIconForFieldValue(field, null, currentServiceGroup, 'joblist-custom-icons');
                    for (let icon of customIcons) {
                        let customIconAlreadyExistsIndex = showCustomIconFields.findIndex((x) => x.relatedFieldName === field.name);
                        if (customIconAlreadyExistsIndex > -1) {
                            showCustomIconFields[customIconAlreadyExistsIndex] = icon; // replace icon if icon for this field already exists
                        } else {
                            showCustomIconFields.push(icon);
                        }
                    }
                }

                // handle custom icons of job articles
                for (let article of job.articles) {
                    for (let field of article.fields) {
                        let customIcons = this.getCustomIconForFieldValue(field, article._id, currentServiceGroup, 'joblist-custom-icons');
                        for (let icon of customIcons) {
                            let customIconAlreadyExistsIndex = showCustomIconFields.findIndex((x) => x.relatedFieldName === field.name);
                            if (customIconAlreadyExistsIndex > -1) {
                                showCustomIconFields[customIconAlreadyExistsIndex] = icon; // replace icon if icon for this field already exists
                            } else {
                                showCustomIconFields.push(icon);
                            }
                        }
                    }
                }

                // handle custom icons of job extensions
                if (jobExtensions != null && jobExtensions.length > 0) {
                    for (let jobExtension of jobExtensions) {
                        for (let field of jobExtension.article.fields) {
                            let customIcons = this.getCustomIconForFieldValue(
                                field,
                                jobExtension.article._id,
                                currentServiceGroup,
                                'joblist-custom-icons'
                            );
                            for (let icon of customIcons) {
                                let customIconAlreadyExistsIndex = showCustomIconFields.findIndex((x) => x.relatedFieldName === field.name);
                                if (customIconAlreadyExistsIndex > -1) {
                                    showCustomIconFields[customIconAlreadyExistsIndex] = icon; // replace icon if icon for this field already exists
                                } else {
                                    showCustomIconFields.push(icon);
                                }
                            }
                        }
                    }
                }
                try {
                    if (showCustomIconFields.length > 0 && this.latestCompanySettings?.combineCustomIconFields) {
                        // we want to combine custom icon fields with same icon and icon color
                        let combinedCustomIcons: Array<JobStatusIcon> = [];
                        for (let entry of showCustomIconFields) {
                            let found = combinedCustomIcons.find((x) => {
                                if (entry.icon != null) {
                                    return x.value === entry.value && x.icon.iconName === entry.icon.iconName && x.color === entry.color;
                                } else {
                                    return x.value === entry.value && x.color === entry.color;
                                }
                            });
                            if (found != null) {
                                // increase count of combined Icon
                                if (found.combinedCount != null) {
                                    found.combinedCount++;
                                }
                            } else {
                                entry.combinedCount = 1;
                                combinedCustomIcons.push(entry);
                            }
                        }
                        showCustomIconFields = combinedCustomIcons; // override
                    }
                } catch (combinedCustomIconErr) {
                    this.logger.error('[UTILS] could not combine joblist custom icons');
                }
            }
        }

        let showBuildingPlanCustomIconFields: Array<JobStatusIcon> = [];
        if (buildingPlanCustomIconCalculationNeeded) {
            // check if the current service group uses 'status' fields, then we have to calculate them
            if (currentServiceGroup.hasCustomIconOnBuildingPlan) {
                // handle custom icons of job additional fields
                for (let field of job.fields) {
                    let customIcons = this.getCustomIconForFieldValue(field, null, currentServiceGroup, 'building-plan-custom-icons');
                    for (let icon of customIcons) {
                        let customIconAlreadyExistsIndex = showBuildingPlanCustomIconFields.findIndex(
                            (x) => x.relatedFieldName === field.name
                        );
                        if (customIconAlreadyExistsIndex > -1) {
                            showBuildingPlanCustomIconFields[customIconAlreadyExistsIndex] = icon; // replace icon if icon for this field already exists
                        } else {
                            showBuildingPlanCustomIconFields.push(icon);
                        }
                    }
                }

                // handle custom icons of job articles
                for (let article of job.articles) {
                    for (let field of article.fields) {
                        let customIcons = this.getCustomIconForFieldValue(
                            field,
                            article._id,
                            currentServiceGroup,
                            'building-plan-custom-icons'
                        );
                        for (let icon of customIcons) {
                            let customIconAlreadyExistsIndex = showBuildingPlanCustomIconFields.findIndex(
                                (x) => x.relatedFieldName === field.name
                            );
                            if (customIconAlreadyExistsIndex > -1) {
                                showBuildingPlanCustomIconFields[customIconAlreadyExistsIndex] = icon; // replace icon if icon for this field already exists
                            } else {
                                showBuildingPlanCustomIconFields.push(icon);
                            }
                        }
                    }
                }

                // handle custom icons of job extensions
                if (jobExtensions != null && jobExtensions.length > 0) {
                    for (let jobExtension of jobExtensions) {
                        for (let field of jobExtension.article.fields) {
                            let customIcons = this.getCustomIconForFieldValue(
                                field,
                                jobExtension.article._id,
                                currentServiceGroup,
                                'building-plan-custom-icons'
                            );
                            for (let icon of customIcons) {
                                let customIconAlreadyExistsIndex = showBuildingPlanCustomIconFields.findIndex(
                                    (x) => x.relatedFieldName === field.name
                                );
                                if (customIconAlreadyExistsIndex > -1) {
                                    showBuildingPlanCustomIconFields[customIconAlreadyExistsIndex] = icon; // replace icon if icon for this field already exists
                                } else {
                                    showBuildingPlanCustomIconFields.push(icon);
                                }
                            }
                        }
                    }
                }
                try {
                    if (showBuildingPlanCustomIconFields.length > 0 && this.latestCompanySettings?.combineCustomIconFields) {
                        // we want to combine custom icon fields with same icon and icon color
                        let combinedBuildingPlanIcons: Array<JobStatusIcon> = [];
                        for (let entry of showBuildingPlanCustomIconFields) {
                            let found = combinedBuildingPlanIcons.find((x) => {
                                if (entry.icon != null) {
                                    return (
                                        x.value === entry.value &&
                                        x.icon.iconName === entry.icon.iconName &&
                                        x.color === entry.color &&
                                        x.hideMarker === entry.hideMarker
                                    );
                                } else {
                                    return x.value === entry.value && x.color === entry.color && x.hideMarker === entry.hideMarker;
                                }
                            });
                            if (found != null) {
                                // increase count of combined Icon
                                if (entry.combinedCount != null) {
                                    entry.combinedCount++;
                                }
                            } else {
                                entry.combinedCount = 1;
                                combinedBuildingPlanIcons.push(entry);
                            }
                        }
                        showBuildingPlanCustomIconFields = combinedBuildingPlanIcons; // override
                    }
                } catch (combinedCustomIconErr) {
                    this.logger.error('[UTILS] could not combine bp custom icons');
                }
            }
        }

        return {
            data: dataStatusCalculationNeeded ? dataOk : job.jobStatus.data,
            photos: photosStatusCalculationNeeded ? photosOk : job.jobStatus.photos,
            map: mapStatusCalculationNeeded ? mapOk : job.jobStatus.map,
            code: codeStatusCalculationNeeded ? codeOk : job.jobStatus.code,
            customIconFields: customIconCalculationNeeded ? showCustomIconFields : job.jobStatus.customIconFields,
            buildingPlanCustomIconFields: buildingPlanCustomIconCalculationNeeded
                ? showBuildingPlanCustomIconFields
                : job.jobStatus.buildingPlanCustomIconFields,
        };
    }

    /**
     * Checks if given field is filled out
     */
    checkFieldFilledStatus(field: Field, relatedFields: Array<Field>) {
        // first check if the field has a value, so it is not empty
        let fieldFilled = true;
        let fieldOk = true;

        if (field.type === FieldType.Group) {
            let groupFilled = false;
            let groupOk = true;
            for (let subField of field.subFields) {
                let subFieldStatus = this.checkFieldFilledStatus(subField, field.subFields);
                if (subFieldStatus.filled) {
                    groupFilled = true;
                }
                if (!subFieldStatus.ok) {
                    groupOk = false;
                }
            }
            fieldFilled = groupFilled;
            fieldOk = groupOk;

            // only set groupOk to false if the group field is actually visible
            if (!fieldOk) {
                let fieldIsVisible = this.checkFieldVisibilityCondition(field, relatedFields);
                if (!fieldIsVisible) {
                    fieldOk = true; // override field ok because the field is not visible
                }
            }
        } else {
            if (field.type === FieldType.Product) {
                if (field.selectedProducts.length === 0) {
                    fieldFilled = false;
                }
            } else {
                if (field.value == null || field.value === '' || this.isEmptyArray(field.value)) {
                    fieldFilled = false;
                }
            }
            if (field.required && !fieldFilled) {
                // check if the field is visible based on conditions
                let fieldIsVisible = this.checkFieldVisibilityCondition(field, relatedFields);
                if (fieldIsVisible) {
                    // field is visible, not filled but required -> so status is not ok
                    fieldOk = false;
                }
            }
        }
        return {
            ok: fieldOk,
            filled: fieldFilled,
        };
    }

    /**
     * This function takes job field (with value set) and serviceGroup (with newest settings) and returns the correct icon for the field value
     * It takes into account if servicegroup settings for a field have changed since the field was added to a job, so the job status can be calculated correctly
     * @param field
     * @param fieldArticleId if the field comes from a article the article id has to be given, if the field is an service-group-additional-field has to be null (used to check current fields in servicegroup for latest settings)
     * @param serviceGroup
     * @param checkType
     */
    getCustomIconForFieldValue(
        field: Field,
        fieldArticleId: string,
        serviceGroup: ServiceGroup,
        checkType: 'joblist-custom-icons' | 'building-plan-custom-icons'
    ) {
        let returnCustomIcons = [];

        if ((field.value == null || field.value == '') && field.type !== FieldType.Group) {
            return returnCustomIcons; // no checks needed
        }

        // get field of servicegroup, because maybe certain fieldsettings have changed since the job was created (showicon, colors, etc.)
        let fieldWithUpdatedSettingsFromServiceGroup: Field = null;
        if (fieldArticleId == null) {
            // check if field is found in service group fields
            fieldWithUpdatedSettingsFromServiceGroup = this.findFieldInFields(field._id, serviceGroup.fields);
        } else {
            // check if field is found in article of service group
            let articleFoundInSg = serviceGroup.articles.find((x) => x._id === fieldArticleId);
            if (articleFoundInSg != null) {
                fieldWithUpdatedSettingsFromServiceGroup = this.findFieldInFields(field._id, articleFoundInSg.fields);
            }
        }

        let showIcon = false;
        if (checkType === 'joblist-custom-icons') {
            showIcon = field.showIconInJobList;
            if (fieldWithUpdatedSettingsFromServiceGroup != null) {
                showIcon = fieldWithUpdatedSettingsFromServiceGroup.showIconInJobList;
            }
        } else if (checkType === 'building-plan-custom-icons') {
            showIcon = field.showIconOnBuildingPlan;
            if (fieldWithUpdatedSettingsFromServiceGroup != null) {
                showIcon = fieldWithUpdatedSettingsFromServiceGroup.showIconOnBuildingPlan;
            }
        }
        if (
            [FieldType.Select, FieldType.ExtendableSelect, FieldType.Checkbox, FieldType.Text, FieldType.Number, FieldType.Date].includes(
                field.type
            ) &&
            showIcon
        ) {
            // field wants to show icon in job list, get value to show
            if (Array.isArray(field.value)) {
                for (let item of field.value) {
                    let usedValue = field.possibleValues.find((x) => x.value == item);
                    returnCustomIcons.push({
                        value: item,
                        icon: {
                            iconName: usedValue?.icon?.iconName || null,
                            iconType: usedValue?.icon?.iconType || null,
                        },
                        color: usedValue?.color || null,
                        relatedFieldName: field.name,
                        hideMarker: usedValue?.hideMarker || null,
                    });
                }
            } else {
                let usedValue = field.possibleValues.find((x) => x.value == field.value);
                let newFieldValue = field.value;
                if (field.type === FieldType.Date) {
                    if (field.dateFieldFormat != null) {
                        let momentDateField = moment(newFieldValue);
                        if (field.dateFieldFormat === 'full-date') {
                            newFieldValue = momentDateField.format('DD.MM.YYYY');
                        } else if (field.dateFieldFormat === 'month-year') {
                            newFieldValue = momentDateField.format('MM/YYYY');
                        } else if (field.dateFieldFormat === 'only-year') {
                            newFieldValue = momentDateField.format('YYYY');
                        }
                    }
                }

                returnCustomIcons.push({
                    value: newFieldValue,
                    icon: {
                        iconName: usedValue?.icon?.iconName || null,
                        iconType: usedValue?.icon?.iconType || null,
                    },
                    color: usedValue?.color || null,
                    relatedFieldName: field.name,
                    hideMarker: usedValue?.hideMarker || null,
                });
            }
        } else if (field.type === FieldType.Group) {
            for (let subField of field.subFields) {
                let subFieldCustomIcons = this.getCustomIconForFieldValue(subField, fieldArticleId, serviceGroup, checkType);
                for (let ci of subFieldCustomIcons) {
                    ci.relatedFieldName = field.name + ' | ' + ci.relatedFieldName; // merge sub field names
                    returnCustomIcons.push(ci);
                }
            }
        }

        return returnCustomIcons;
    }

    /**
     * Takes a field from a job to check and an array of other related fields from this job (from the same article of the job) and checks the visibility condition based on field values
     * @param fieldToCheck the field model which we should check
     * @param relatedFields other fields
     */
    checkFieldVisibilityCondition(fieldToCheck: Field, relatedFields: Array<Field>) {
        let visible = true;
        if (fieldToCheck.condition == null) {
            visible = true; // default: if no conditions are set the field is visible
        } else {
            let allConditionsApplying = true;
            let oneConditionApplying = false;
            for (let conditionField of fieldToCheck.condition.conditionFields) {
                let conditionApplying = false;
                let jobFieldData = relatedFields.find((x) => x._id == conditionField._id);
                if (jobFieldData != null) {
                    let jobFieldDataValue = jobFieldData.value;
                    let conditionFieldType = jobFieldData.type;
                    switch (conditionField.conditionCompare) {
                        case 'equals': {
                            if (conditionFieldType === FieldType.Product) {
                                let valueFound = false;
                                for (let selectedProduct of jobFieldData.selectedProducts) {
                                    if (selectedProduct.name === conditionField.compareValue) {
                                        valueFound = true;
                                        break;
                                    }
                                }
                                conditionApplying = valueFound;
                            } else {
                                // normal field handling
                                if (Array.isArray(jobFieldDataValue)) {
                                    conditionApplying = jobFieldDataValue.includes(conditionField.compareValue);
                                } else {
                                    conditionApplying = jobFieldDataValue == conditionField.compareValue;
                                }
                            }
                            break;
                        }
                        case 'not-equals': {
                            if (conditionFieldType === FieldType.Product) {
                                let valueFound = false;
                                for (let selectedProduct of jobFieldData.selectedProducts) {
                                    if (selectedProduct.name === conditionField.compareValue) {
                                        valueFound = true;
                                        break;
                                    }
                                }
                                conditionApplying = !valueFound;
                            } else {
                                if (Array.isArray(jobFieldDataValue)) {
                                    conditionApplying = !jobFieldDataValue.includes(conditionField.compareValue);
                                } else {
                                    conditionApplying = jobFieldDataValue != conditionField.compareValue;
                                }
                            }
                            break;
                        }
                        case 'filled': {
                            if (conditionFieldType === FieldType.Product) {
                                conditionApplying = jobFieldData.selectedProducts.length > 0;
                            } else {
                                if (Array.isArray(jobFieldDataValue)) {
                                    conditionApplying = jobFieldDataValue.length > 0;
                                } else {
                                    conditionApplying = jobFieldDataValue != null && jobFieldDataValue != '';
                                }
                            }
                            break;
                        }
                        case 'not-filled': {
                            if (conditionFieldType === FieldType.Product) {
                                conditionApplying = jobFieldData.selectedProducts.length === 0;
                            } else {
                                if (Array.isArray(jobFieldDataValue)) {
                                    conditionApplying = jobFieldDataValue.length == 0;
                                } else {
                                    conditionApplying = jobFieldDataValue == null || jobFieldDataValue == '';
                                }
                            }
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                } else {
                    visible = true; // field is visible as the condition field does not exist
                }
                if (conditionApplying) {
                    oneConditionApplying = true;
                } else {
                    allConditionsApplying = false;
                }
            }
            switch (fieldToCheck.condition.combination) {
                case 'and': {
                    if (allConditionsApplying) {
                        visible = fieldToCheck.condition.visibility == 'visible';
                    } else {
                        // conditions are not applying
                        visible = fieldToCheck.condition.visibility != 'visible';
                    }
                    break;
                }
                case 'or': {
                    if (oneConditionApplying) {
                        visible = fieldToCheck.condition.visibility == 'visible';
                    } else {
                        // conditions are not applying
                        visible = fieldToCheck.condition.visibility != 'visible';
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }
        return visible;
    }

    /**
     * Helper fucntion which finds a field with given fieldId in an array of fields (used to also search through sub fields)
     * @param fieldIdToFind
     * @param fieldsHaystack
     */
    findFieldInFields(fieldIdToFind: string, fieldsHaystack: Field[]) {
        let found = null;
        for (let field of fieldsHaystack) {
            if (field.type === FieldType.Group) {
                let foundSubField = this.findFieldInFields(fieldIdToFind, field.subFields);
                if (foundSubField != null) {
                    found = foundSubField;
                    break;
                }
            } else {
                if (field._id === fieldIdToFind) {
                    found = field;
                    break;
                }
            }
        }
        return found;
    }

    /**
     * Resets given field value of formGroup to null based on field type
     * @param fieldFormGroup
     */
    resetFieldValueOfFieldFormGroup(fieldFormGroup: AbstractControl) {
        switch (fieldFormGroup.get('type').value) {
            case FieldType.Product: {
                if (fieldFormGroup.get('selectedProducts') instanceof UntypedFormArray) {
                    (<UntypedFormArray>fieldFormGroup.get('selectedProducts')).clear();
                }
                break;
            }
            case FieldType.Group: {
                // call recursively for subfields
                for (let subfield of (<FormArray>fieldFormGroup.get('subFields')).controls) {
                    this.resetFieldValueOfFieldFormGroup(subfield as AbstractControl);
                }
                break;
            }
            default: {
                if (fieldFormGroup.get('value') instanceof UntypedFormArray) {
                    (<UntypedFormArray>fieldFormGroup.get('value')).clear();
                } else {
                    if (fieldFormGroup.get('value').value != null) {
                        fieldFormGroup.get('value').setValue(null);
                    }
                }
                break;
            }
        }
    }

    /**
     * Takes given field and checks if calendar events shall be created based on field settings and filled out data
     * @param params (relatedFieldPrefix is used for group fields, at repeated group fields we would have a problem with same-ids so we prefix the id with the group number)
     */
    getCalendarEventsForField(params: {
        field: Field;
        relatedProject: Project;
        relatedJob?: Job;
        relatedJobExtension?: JobExtension;
        relatedFieldPrefix?: string;
        companyId: string;
        userName: string;
    }) {
        if (params.relatedFieldPrefix == null) {
            params.relatedFieldPrefix = '';
        }
        if (params.field == null) {
            return [];
        }
        // check if we need to create calendarEntries for date fields
        let calendarEntries: CalendarEntry[] = [];
        if (params.field.type === FieldType.Date && params.field.dateInCalendar && params.field.value != null) {
            let calendarEntry = new CalendarEntry();
            calendarEntry.startTimestamp = Number(params.field.value);
            calendarEntry.endTimestamp = calendarEntry.startTimestamp;
            calendarEntry.status = 'pending';
            calendarEntry.fullDay = true;

            if (params.companyId != null) {
                calendarEntry.creatorCompanyId = params.companyId;
            }
            if (params.userName != null) {
                calendarEntry.createdBy = params.userName;
            }
            if (params.relatedJob != null) {
                calendarEntry.relatedJob = params.relatedJob._id;
                calendarEntry.relatedJobName = params.relatedJob.name;
                calendarEntry.relatedJobNumber = params.relatedJob.jobNumber;
            }
            if (params.relatedJobExtension != null) {
                calendarEntry.relatedJobExtension = params.relatedJobExtension._id;
                calendarEntry.relatedJobExtensionName = params.relatedJobExtension.article.name;
            }
            if (params.relatedProject != null) {
                calendarEntry.relatedProject = params.relatedProject._id;
                calendarEntry.relatedProjectName = params.relatedProject.name;
            }
            if (params.relatedFieldPrefix != '') {
                calendarEntry.relatedField = params.relatedFieldPrefix + '|' + params.field._id; // handling for group fields
            } else {
                calendarEntry.relatedField = params.field._id;
            }
            calendarEntry.relatedFieldName = params.field.name;
            calendarEntry.color = params.field.dateInCalendarColor;

            // push new calendar entry
            calendarEntries.push(calendarEntry);
        } else if (params.field.type === FieldType.Group) {
            for (let subField of params.field.subFields) {
                let subFieldParams = this.clone(params);
                subFieldParams.field = subField;
                subFieldParams.relatedFieldPrefix = params.field._id;
                let subFieldEntries = this.getCalendarEventsForField(subFieldParams); // we use the id of the group-field as prefix (as group fields have the duplicationNumber appended at the id, so repeated group fields do not have the same id)
                if (subFieldEntries.length > 0) {
                    calendarEntries = [...calendarEntries, ...subFieldEntries];
                }
            }
        }
        return calendarEntries;
    }

    /**
     * Taken from RXJS which check if a given parameter is compatible as a number
     * @param val any object, string or value to check
     * @returns true if it is numeric otherwise false
     */
    isNumeric(val) {
        let isArray =
            Array.isArray ||
            function (x) {
                return x && typeof x.length === 'number';
            };
        return !isArray(val) && val - parseFloat(val) + 1 >= 0;
    }

    /**
     * Shows or hides the page blocking loader
     * Needed if the user must not interact with the app during some loading tasks (e.g logout)
     * @param display
     */
    displayPageBlockingLoader(display: boolean) {
        if (display) {
            document.getElementsByClassName('dp-page-blocking-loader')[0].classList.add('dp-loader-active');
        } else {
            document.getElementsByClassName('dp-page-blocking-loader')[0].classList.remove('dp-loader-active');
        }
    }

    /**
     * Helper function for providing a way to react to the mobile main menu's opening and closing events
     * @param state "open" or "close" but currently only triggers close events
     */
    triggerMobileMainMenuStatus(state: 'open' | 'close' = 'close') {
        if (state == 'open') {
            this.mobileMainMenuDidOpen.next();
        } else {
            this.mobileMainMenuDidClose.next();
        }
    }

    /**
     * Helper function which converts given report filter to a formgroup
     * @param reportFilter
     */
    reportFilterToFormGroup(reportFilter: ReportFilter) {
        return this.fb.group({
            filterType: this.fb.control(reportFilter.filterType),
            includedServiceGroups: this.fb.control(reportFilter.includedServiceGroups),
            includedCompanyUsers: this.fb.control(reportFilter.includedCompanyUsers),
            includedBuildingPlans: this.fb.control(reportFilter.includedBuildingPlans),
            includedCompletionStatus: this.fb.control(reportFilter.includedCompletionStatus),
            minDate: this.fb.control(reportFilter.minDate),
            maxDate: this.fb.control(reportFilter.maxDate),
            extensionDateInclusion: this.fb.control(reportFilter.extensionDateInclusion),
            field: this.fb.group({
                serviceGroupId: this.fb.control(reportFilter.field.serviceGroupId),
                articleId: this.fb.control(reportFilter.field.articleId),
                fieldId: this.fb.control(reportFilter.field.fieldId),
                fieldName: this.fb.control(reportFilter.field.fieldName),
                fieldType: this.fb.control(reportFilter.field.fieldType),
                restriction: this.fb.control(reportFilter.field.restriction),
                relativeDateNumber: this.fb.control(reportFilter.field.relativeDateNumber),
                relativeDateType: this.fb.control(reportFilter.field.relativeDateType),
                desiredFieldValue: this.fb.control(reportFilter.field.desiredFieldValue),
                possibleFieldValues: this.fb.control(reportFilter.field.possibleFieldValues),
                compare: this.fb.control(reportFilter.field.compare),
                inclusion: this.fb.control(reportFilter.field.inclusion),
                inclusionMultipleBehaviour: this.fb.control(reportFilter.field.inclusionMultipleBehaviour),
            }),
            article: this.fb.group({
                serviceGroupId: this.fb.control(reportFilter.article.serviceGroupId),
                articleId: this.fb.control(reportFilter.article.articleId),
                dateRestriction: this.fb.control(reportFilter.article.dateRestriction),
                relativeDateNumber: this.fb.control(reportFilter.article.relativeDateNumber),
                relativeDateType: this.fb.control(reportFilter.article.relativeDateType),
                inclusion: this.fb.control(reportFilter.article.inclusion),
                inclusionMultipleBehaviour: this.fb.control(reportFilter.article.inclusionMultipleBehaviour),
            }),
            jobNumber: this.fb.group({
                jobNumberFilterType: this.fb.control(reportFilter.jobNumber.jobNumberFilterType),
                jobNumberFromTo: this.fb.control(reportFilter.jobNumber.jobNumberFromTo),
                includedJobs: this.fb.control(reportFilter.jobNumber.includedJobs),
            }),
        });
    }

    /**
     * Unique array
     * @param array
     */
    arrayUnique(array) {
        let a = array.concat();
        for (let i = 0; i < a.length; ++i) {
            for (let j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j]) a.splice(j--, 1);
            }
        }
        return a;
    }

    /**
     * Helper to capture exceptions with Sentry manually
     * @param exception either Exception object or string. Be careful that strings don't have stack traces!
     * @param level optional Severity level if wanted to define manually
     */
    sentryCaptureException(exception, level?: SeverityLevel): void {
        withScope((scope) => {
            if (level) {
                scope.setLevel(level);
            }
            captureException(exception);
        });
    }

    /**
     * If not capturing an exception one can use messages to keep track of events
     * @param msg the message string
     */
    sentryCaptureMessage(msg: string, data?: any): void {
        withScope((scope: Scope) => {
            if (data) {
                addBreadcrumb({
                    type: 'debug',
                    data: data,
                });
            }
            captureMessage(msg);
        });
    }

    /**
     * Same function as keepAwake without the need to check for exceptions
     * @param extraMsg Extra logging data
     */
    public async keepAwake(extraMsg?: string) {
        try {
            let d = await KeepAwake.isSupported();
            let a = await KeepAwake.isKeptAwake();

            if (d.isSupported && !a.isKeptAwake) {
                this.logger.log('[UTILS] Keeping device awake', extraMsg);
                try {
                    await KeepAwake.keepAwake();
                } catch (kEx) {
                    this.logger.warn('[UTILS] Failed keeping device awake', kEx);
                }
            } else {
                this.logger.log('[UTILS] KeepAwake responds with not supported');
            }
        } catch (kaEx) {
            this.logger.warn('[UTILS] error on checking KeepAwake support', kaEx);
        }
    }

    /**
     * Same function as allowSleep without the need to check for exceptions
     * @param extraMsg Extra logging data
     */
    public async allowSleep(extraMsg?: string) {
        try {
            let d = await KeepAwake.isSupported();

            if (d.isSupported) {
                this.logger.log('[UTILS] Allowing device sleep again', extraMsg);
                try {
                    await KeepAwake.allowSleep();
                } catch (kEx) {
                    this.logger.warn('[UTILS] Failed unlocking device sleep', kEx);
                }
            } else {
                this.logger.log('[UTILS] KeepAwake responds with not supported while allowing sleep');
            }
        } catch (kaEx) {
            this.logger.warn('[UTILS] error on checking KeepAwake support on allowSleep', kaEx);
        }
    }

    /**
     * Returns one-line string of given address object
     * @param address
     * @returns string combined address parts or empty string if nothing to combine
     */
    buildProjectAddressString(address: Address) {
        if (address == null) {
            return '';
        }

        let addressString = '';

        if (address.street != null && address.street.trim() !== '') {
            addressString += address.street;
        }

        if (address.zip != null && address.zip.trim() !== '' && address.city != null && address.city.trim() !== '') {
            addressString += ', ' + address.zip + ' ' + address.city;
        }

        if (address.country != null && address.country.trim() !== '') {
            addressString += ', ' + address.country;
        }

        return addressString;
    }

    /**
     * Helper to determine if version b is newer than version a
     * @param a version to compare for being older
     * @param b version to compare for being newer
     * @returns boolean whether version b is newer or not
     * @private
     */
    compareVersionStrings(a: any, b: any): boolean {
        if (typeof a !== 'string') return false;
        if (typeof b !== 'string') return false;

        let v1 = a.split('.');
        let v2 = b.split('.');
        const k = Math.min(v1.length, v2.length);
        for (let i = 0; i < k; ++i) {
            v1[i] = parseInt(v1[i], 10).toString();
            v2[i] = parseInt(v2[i], 10).toString();
            if (v1[i] > v2[i]) {
                return false;
            }
            if (v1[i] < v2[i]) {
                return true;
            }
        }

        return v1.length === v2.length ? false : v1.length <= v2.length;
    }
}
