import { v4 as uuidv4 } from 'uuid';
import { Product } from '../../../../products/product.model';

export class Field {
    _id: string;
    name: string;
    type: FieldType;
    value: string | Array<string> | any;
    possibleValues: Array<PossibleFieldValue>;
    required: boolean;
    multiSelect: boolean; // only for select fields (allow multiple selected values)
    showIconInJobList: boolean; // only for select, checkbox, text, date and number fields (shows the icon of the selected value in the job list)
    showIconOnBuildingPlan: boolean; // only for select and checkbox fields (shows the icon of the selected value on the building plan)
    autoFillLastValue: boolean; // if true auto-fills the field with the last filled value
    dynamicWidth: number;
    condition: FieldCondition;
    selectedProducts: Array<Product>;
    dateFieldFormat: 'full-date' | 'month-year' | 'only-year';
    dateInCalendar: boolean;
    dateInCalendarColor: string;
    userFieldAllowedLicenseTypes: Array<string>;
    subFields: Array<Field>;
    repetition: boolean; // this property controls if a group field is a repetition field
    duplicationNumber: number; // this property is only used if the field is a duplicated field from a repetition group
    hideInReport: boolean; // this property controls if the field should be hidden in report

    constructor(data?: any) {
        this._id = uuidv4();
        this.name = '';
        this.type = FieldType.Text;
        this.value = null;
        this.possibleValues = [];
        this.required = true;
        this.multiSelect = false;
        this.showIconInJobList = false;
        this.showIconOnBuildingPlan = false;
        this.autoFillLastValue = false;
        this.dynamicWidth = null;
        this.condition = null;
        this.selectedProducts = [];
        this.dateFieldFormat = 'full-date';
        this.dateInCalendar = false;
        this.dateInCalendarColor = '#2092c9';
        this.userFieldAllowedLicenseTypes = [];
        this.subFields = [];
        this.repetition = false;
        this.duplicationNumber = null;
        this.hideInReport = false;

        if (data) {
            this._id = data._id || uuidv4;
            this.name = data.name || '';
            this.type = data.type || FieldType.Text;

            if (data.value != null) {
                this.value = data.value;
            }

            this.possibleValues = data.possibleValues.map((x) => new PossibleFieldValue(x)) || [];
            this.required = data.required === true;
            this.multiSelect = data.multiSelect === true;
            this.showIconInJobList = data.showIconInJobList === true;
            this.showIconOnBuildingPlan = data.showIconOnBuildingPlan === true;
            this.autoFillLastValue = data.autoFillLastValue === true;
            this.repetition = data.repetition === true;
            this.hideInReport = data.hideInReport === true;
            if (data.dynamicWidth != null) {
                this.dynamicWidth = data.dynamicWidth;
            }
            if (data.condition != null) {
                this.condition = new FieldCondition(data.condition);
            }
            if (data.selectedProducts != null) {
                this.selectedProducts = data.selectedProducts.map((x) => new Product(x, true));
            }
            if (data.dateFieldFormat != null) {
                this.dateFieldFormat = data.dateFieldFormat;
            }
            if (data.dateInCalendar != null) {
                this.dateInCalendar = data.dateInCalendar;
            }
            if (data.dateInCalendarColor != null) {
                this.dateInCalendarColor = data.dateInCalendarColor;
            }
            if (data.userFieldAllowedLicenseTypes != null) {
                this.userFieldAllowedLicenseTypes = data.userFieldAllowedLicenseTypes;
            }
            if (data.duplicationNumber != null) {
                this.duplicationNumber = data.duplicationNumber;
            }

            if (data.subFields != null) {
                this.subFields = data.subFields.map((x) => new Field(x));
            }
        }
    }
}

export class PossibleFieldValue {
    value: string;
    icon: {
        iconName: string;
        iconType: 'dp' | 'fa' | 'dpimg';
    };
    color: string;
    link: string;
    hideMarker: boolean;

    constructor(data?: any) {
        this.value = null;
        this.icon = {
            iconName: null,
            iconType: null,
        };
        this.color = null;
        this.link = null;
        this.hideMarker = true;

        if (data != null) {
            if (data.value != null) {
                this.value = data.value;
            }
            if (data.icon != null) {
                if (typeof data.icon === 'string') {
                    // map old deprecated data
                    let iconType = null;
                    if (data.icon.startsWith('assets/icon/colorable')) {
                        iconType = 'dp';
                    } else if (data.icon.startsWith('assets/icon')) {
                        iconType = 'dpimg';
                    } else {
                        iconType = 'fa';
                    }
                    this.icon = {
                        iconName: data.icon,
                        iconType: iconType,
                    };
                } else if (data.icon.icon != null) {
                    // map old deprecated data
                    let iconType = null;
                    if (data.icon.icon.startsWith('assets/icon/colorable')) {
                        iconType = 'dp';
                    } else if (data.icon.icon.startsWith('assets/icon')) {
                        iconType = 'dpimg';
                    } else {
                        iconType = 'fa';
                    }
                    this.icon = {
                        iconName: data.icon.icon,
                        iconType: iconType,
                    };
                } else if (data.icon.iconName == null) {
                    // bugfix for certain cases where iconType was set and iconName doesnt exist, reset to null
                    this.icon = {
                        iconName: null,
                        iconType: null,
                    };
                } else {
                    this.icon = data.icon;
                }
            }
            if (data.color != null) {
                this.color = data.color;
            }
            if (data.link != null) {
                this.link = data.link;
            }
            this.hideMarker = data.hideMarker === true;
        }
    }
}

export class FieldCondition {
    conditionFields: Array<ConditionField>; // array of condition fields that will be checked
    combination: 'and' | 'or'; // how to combine multiple fields
    visibility: 'visible' | 'not-visible'; // what shall happen if the condition is true

    constructor(data?: any) {
        this.conditionFields = [];
        this.combination = 'or';
        this.visibility = 'visible';

        if (data != null) {
            if (data.conditionFields != null) {
                this.conditionFields = data.conditionFields.map((x) => new ConditionField(x));
            }
            if (data.combination != null) {
                this.combination = data.combination;
            }
            if (data.visibility != null) {
                this.visibility = data.visibility;
            }
        }
    }
}

export class ConditionField {
    _id: string;
    conditionCompare: 'equals' | 'not-equals' | 'filled' | 'not-filled'; // compare method for the value
    compareValue: any; // value of the field-to-be-checked, so that the condition is true

    constructor(data?: any) {
        this._id = null;
        this.conditionCompare = 'filled';
        this.compareValue = null;

        if (data != null) {
            if (data._id != null) {
                this._id = data._id;
            }
            if (data.conditionCompare != null) {
                this.conditionCompare = data.conditionCompare;
            }
            if (data.compareValue != null) {
                this.compareValue = data.compareValue;
            }
        }
    }
}

export enum FieldType {
    Text = 'text',
    TextEditor = 'text-editor',
    Select = 'select',
    ExtendableSelect = 'ext-select',
    Number = 'number',
    Product = 'product',
    Date = 'date',
    Time = 'time',
    Code = 'code',
    Checkbox = 'checkbox',
    User = 'user',
    Signature = 'signature',
    Group = 'group',
    Calculator = 'calculator',
    Linking = 'linking',
}
