import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-field-icon-select',
    templateUrl: './field-icon-select.component.html',
    styleUrls: ['./field-icon-select.component.scss'],
})
export class FieldIconSelectComponent {
    @Input() iconForm: UntypedFormGroup;
    @Input() possibleIcons = [];
    @Output() selectedIcon = new EventEmitter();

    constructor() {}

    /**
     * Called when an user selects an icon
     * @param event the event value
     * @param iconControl the icon control
     */
    onSelectIcon(event, iconControl: AbstractControl) {
        if (iconControl != null) {
            if (event == null) {
                iconControl.get('iconName').setValue(null);
                iconControl.get('iconType').setValue(null);
            } else {
                iconControl.get('iconName').setValue(event);
                if (event.startsWith('assets/icon/colorable')) {
                    iconControl.get('iconType').setValue('dp');
                } else {
                    iconControl.get('iconType').setValue(event.startsWith('assets/') ? 'dpimg' : 'fa');
                }
            }
        }
        this.selectedIcon.emit(event);
    }
}
