<div class="file-select-action">
    <ng-container *ngIf="buttonStyle == 'button'">
        <ion-button (click)="onShowFileSelectDialog()">
            <fa-icon [icon]="['fal', btnIcon]" slot="start"></fa-icon>
            {{ buttonText }}
        </ion-button>
    </ng-container>
    <ng-container *ngIf="buttonStyle == 'fab'">
        <ng-container *ngIf="!fabInline; else inlineFabButton">
            <ion-grid class="dp-g-fab h-end" [ngClass]="utils.isPhone ? 'bottom' : 'top dp-edge'">
                <ion-row>
                    <ion-col>
                        <ng-container *ngTemplateOutlet="inlineFabButton"></ng-container>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ng-container>

        <ng-template #inlineFabButton>
            <ion-button
                class="dp-g-fab-btn"
                shape="round"
                [size]="btnSize"
                [color]="btnColor"
                (click)="onShowFileSelectDialog()"
                dpTooltip
                [tooltipContent]="buttonText"
                [tooltipPosition]="tooltipPosition"
            >
                <fa-icon [icon]="['fal', btnIcon]" slot="icon-only"></fa-icon>
            </ion-button>
        </ng-template>
    </ng-container>
</div>

<app-dialog
    dialogId="att-select-dialog-{{ uniqueId }}"
    [small]="true"
    #fileSelectDialog
    [dismissable]="!currentlyUploading"
    [hideCloseBtn]="true"
>
    <ng-container *ngIf="currentlyUploading; else defaultView">
        <div class="file-select-heading ion-text-center dp-mb-m">
            <h2>Upload läuft ...</h2>
        </div>
        <div class="progress-text ion-text-center">
            {{ (uploadedBytes / (1024 * 1024)).toFixed(2) }} MB von {{ (totalBytesToUpload / (1024 * 1024)).toFixed(2) }} MB hochgeladen
        </div>
        <ion-progress-bar color="primary" [value]="uploadedBytes / totalBytesToUpload"></ion-progress-bar>
    </ng-container>
    <ng-template #defaultView>
        <div class="file-select-heading ion-text-center">
            <h2>{{ buttonText }}</h2>
        </div>
        <div class="file-select-wrap">
            <div class="file-select-choices">
                <div class="file-select-choice library-select">
                    <div class="choice-icon">
                        <fa-icon icon="folders" size="2x"></fa-icon>
                    </div>
                    <div class="choice-title">Aus Bibliothek auswählen</div>
                    <input
                        type="file"
                        class="choose-file-input"
                        id="choose-file-input{{ uniqueId }}"
                        (change)="onFilesChosen($event.target.files)"
                        multiple="true"
                        #fileSelectInput
                    />
                    <label for="choose-file-input{{ uniqueId }}" class="choose-file-input-label"></label>
                </div>
                <div class="file-select-choice open-camera" (click)="onTakePhoto()">
                    <div class="choice-icon">
                        <fa-icon icon="camera" size="2x"></fa-icon>
                    </div>
                    <div class="choice-title">Kamera öffnen</div>
                </div>
            </div>
            <div
                class="file-drop-notice"
                [ngClass]="{ 'file-over': fileOverDropZone }"
                (dragenter)="onDragEnter($event)"
                (dragleave)="onDragLeave($event)"
                (dragover)="onDragOver($event)"
                (drop)="onDropFiles($event)"
                *ngIf="!utils.isPhone && !utils.isTablet"
            >
                <span>{{ fileOverDropZone ? 'Datei jetzt ablegen' : 'oder Datei hierher ziehen' }}</span>
            </div>
        </div>
    </ng-template>
</app-dialog>
