// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    debug: false,
    blockLiveUpdate: true,

    /* Deeplink split domain */
    DEEPLINK_DOMAIN: 'app.dokupit.com',
    /* Weblink config */
    WEBCODE_LINK: 'https://app.dokupit.com/code',

    /* Sentry config - keep in check with .sentryclirc! */
    SENTRY_DSN: 'https://53a7d6670e734727b263bce5e96d37ba@o360317.ingest.sentry.io/5777216',
    SENTRY_DEBUG: true,
    /* https://docs.sentry.io/platforms/javascript/guides/capacitor/performance/instrumentation/automatic-instrumentation#tracepropagationtargets */
    SENTRY_TRACE_TARGETS: ['localhost', 'app.dokupit.com', 'beta.dokupit.com', 'v2.dokupit.com'],
    SENTRY_TRACE_SAMPLE_RATE: 0.2,

    /* AWS CONFIG */
    AWS_REGION: 'eu-central-1',
    AWS_COGNITO_IDENTITY_POOL_ID: 'eu-central-1:31584c5d-94c7-4e3d-9012-4c7594dc9656',
    AWS_COGNITO_USER_POOL_ID: 'eu-central-1_jgQHhROsR',
    AWS_COGNITO_USER_POOL_APP_CLIENT_ID: '7d5gc9vtt7ak7e1nb96o95e5no',
    AWS_DYNAMO_DB_TABLE_NAME: 'dp_data',
    AWS_DYNAMO_DB_COMPANIES_TABLE_NAME: 'dp_companies',
    AWS_DYNAMO_DB_CALENDAR_TABLE_NAME: 'dp_calendar',
    AWS_S3_BUCKET_NAME: 'dokupit',
    AWS_S3_CUSTOMER_DATA_BASE_PATH: 'customer-data/',
    AWS_API_URL: 'https://1ib99xmas5.execute-api.eu-central-1.amazonaws.com/dev',
    AWS_DOWNSTREAM_SYNC_WS_API_URL: 'wss://pr7q7b7w3k.execute-api.eu-central-1.amazonaws.com/develop',
    AWS_API_ORDER_URL: 'https://tyxbyaa5wb.execute-api.eu-central-1.amazonaws.com/dev',
    AWS_API_OIA_URL: 'https://rih0iuzh65.execute-api.eu-central-1.amazonaws.com/dev',

    /* DEVICE CONFIG */
    DISK_FOLDER_ATTACHMENTS: 'dokupit/dp-attachments/',
    DISK_FOLDER_ATTACHMENTS_TO_UPLOAD: 'dokupit/dp-attachments-upload/',

    /* OPEN WEATHER MAP CONFIG */
    OWM_API_URL: 'https://api.openweathermap.org/data/3.0/onecall',
    OWM_API_KEY: '5853666a226679917ba07b74866c2e6f',

    /* GOOGLE CONFIG */
    GOOGLE_GEOCODING_KEY: 'AIzaSyA9zaJsKq93J8NrScnaLoXA0PvNDXT1p30',

    /* MICROSOFT / AZURE CONFIG */
    AZ_CLIENT_ID: 'ec21e716-33d5-4c7b-8bc6-f66c1899f393',
    AZ_AUTHORITY: 'https://login.microsoftonline.com/common/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
