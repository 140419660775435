import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { SettingsService } from '../../settings/settings.service';
import { ModalController } from '@ionic/angular';
import { DpCameraComponent } from './dp-camera/dp-camera.component';
import { CameraPreviewPictureOptions } from '@capacitor-community/camera-preview';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Media } from '@capacitor-community/media';
import { CameraOrientation } from './camera-orientation.enum';
import { UtilsService } from '../utils/utils.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CameraService {
    SupportedPhotoResolutions: Record<string, { width: number; height: number }> = {
        raw: null,
        FHD: { width: 1920, height: 1080 },
        QHD: { width: 2560, height: 1440 },
        '4K': { width: 3840, height: 2160 },
    };

    constructor(
        private utils: UtilsService,
        private settingsSrv: SettingsService,
        private modalCtrl: ModalController
    ) {}

    /**
     * Starts camera to take a photo. Returns the url of the taken photo
     * Returns an observable which resolves to the cameraPhoto response of the ionic camera system
     * @param scaleDown if true will scale the image down to 1920px width with 75% quality
     * @param lockCameraOrientation if the device should be orientation locked
     */
    takePhoto(scaleDown = true, lockCameraOrientation: CameraOrientation = CameraOrientation.NONE) {
        let getPhotoParams: ImageOptions = {
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            correctOrientation: true,
            saveToGallery: this.settingsSrv.getUserSettings().savePhotosInMediaLibrary,
            presentationStyle: 'fullscreen',
        };
        let targetScaling = this.SupportedPhotoResolutions.FHD;
        let settingsQuality = this.settingsSrv.getCompanySettings().photoScalingQuality;

        if (settingsQuality != 'raw') {
            targetScaling = this.SupportedPhotoResolutions[settingsQuality];
            getPhotoParams = {
                ...getPhotoParams,
                ...{ quality: scaleDown ? 75 : 100, width: targetScaling.width, height: targetScaling.height },
            };
        }
        console.log('[CAMERA-SVC] will use opts', [targetScaling, getPhotoParams]);

        if (lockCameraOrientation != CameraOrientation.NONE) {
            if (lockCameraOrientation == CameraOrientation.LANDSCAPE) {
                // the default camera is locked based on rotation AND preferred size so we need to flip those 2
                let tmpWidth = getPhotoParams.width;
                let tmpHeight = getPhotoParams.height;
                getPhotoParams.height = tmpWidth;
                getPhotoParams.width = tmpHeight;
            }
            ScreenOrientation.lock({ orientation: lockCameraOrientation.toString() as OrientationLockType }).catch((err) => {
                console.warn('[CAMERA-SVC] Could not lock orientation for default cam', err);
            });
        }

        return from(Camera.getPhoto(getPhotoParams)).pipe(
            catchError((err, caught) => {
                // tap() does not run all the time so for good measure we catch the errors as well
                console.warn('[CAMERA-SVC] got error', err);
                this.utils.applyBaseScreenlock();

                return throwError(() => err);
            }),
            tap((_) => {
                // Return cam lock if possible
                this.utils.applyBaseScreenlock();
            })
        );
    }

    /**
     * New inapp camera using custom component and CameraPreview
     * @param scaleDown defaults to true, scales images down to software setting and lower quality compression
     * @param lockCameraOrientation
     * @returns Observable with file URI of photo
     */
    takePhotoModal(scaleDown = true, lockCameraOrientation: CameraOrientation = CameraOrientation.NONE): Observable<string> {
        let saveToGallery = this.settingsSrv.getUserSettings().savePhotosInMediaLibrary;

        return new Observable((observer) => {
            let captureOpts: CameraPreviewPictureOptions = {
                quality: 100,
            };
            let targetScaling = this.SupportedPhotoResolutions.FHD;
            let settingsQuality = this.settingsSrv.getCompanySettings().photoScalingQuality;

            if (settingsQuality != 'raw') {
                targetScaling = this.SupportedPhotoResolutions[settingsQuality];
                captureOpts = {
                    ...captureOpts,
                    ...{ quality: scaleDown ? 85 : 100, width: targetScaling.width, height: targetScaling.height },
                };
            }

            console.log('[CAMERA-SVC] got opts', captureOpts);

            this.modalCtrl
                .create({
                    component: DpCameraComponent,
                    componentProps: {
                        captureOpts: captureOpts,
                        orientation: lockCameraOrientation, // ScreenOrientation lock is handled by our camera component but unlock is externally due to not using utils service
                    },
                    showBackdrop: false,
                    backdropDismiss: false,
                    keyboardClose: true,
                    cssClass: 'dp-camera-modal',
                })
                .then((cameraModal) => {
                    cameraModal
                        .present()
                        .then((_) => {
                            cameraModal.onWillDismiss().then(() => {
                                try {
                                    this.utils.applyBaseScreenlock();
                                } catch (lockException) {
                                    console.warn('[CAMERA-SVC] failed early unlocking orientation', lockException);
                                }
                            });

                            cameraModal
                                .onDidDismiss()
                                .then((res) => {
                                    if ((res as any).data) {
                                        if (saveToGallery) {
                                            this.ensureAlbum()
                                                .then((albumId) => {
                                                    Media.savePhoto({
                                                        path: res.data.uri,
                                                        album: albumId,
                                                        albumIdentifier: albumId, // just making sure in case some version combination uses this
                                                    } as any)
                                                        .catch((saveMediaErr) => {
                                                            observer.next(res.data.uri as string);
                                                            console.error('[CAMERA SERVICE] failed saving media', saveMediaErr);
                                                            observer.complete();
                                                            this.utils.applyBaseScreenlock();
                                                        })
                                                        .then((_) => {
                                                            observer.next(res.data.uri as string);
                                                            observer.complete();
                                                            this.utils.applyBaseScreenlock();
                                                        });
                                                })
                                                .catch((ensAlbEx) => {
                                                    observer.next(res.data.uri as string);
                                                    console.error('[CAMERA SERVICE] could not create album', ensAlbEx);
                                                    observer.complete();
                                                    this.utils.applyBaseScreenlock();
                                                });
                                        } else {
                                            observer.next(res.data.uri as string);
                                            observer.complete();
                                            this.utils.applyBaseScreenlock();
                                        }
                                    } else {
                                        // cancelled or other break
                                        observer.next('');
                                        observer.complete();
                                        this.utils.applyBaseScreenlock();
                                    }
                                })
                                .catch((onDismissEx) => {
                                    // TODO: catch error like: Failed to take photo Error: "Caches" can't be access when canceling the camera
                                    observer.error(onDismissEx);
                                    observer.complete();
                                    this.utils.applyBaseScreenlock();
                                });
                        })
                        .catch((camPresentEx) => {
                            observer.error(camPresentEx);
                            observer.complete();
                            this.utils.applyBaseScreenlock();
                        });
                })
                .catch((camModalCreateEx) => {
                    observer.error(camModalCreateEx);
                    observer.complete();

                    this.utils.applyBaseScreenlock();
                });
        });
    }

    /**
     * Checks and creates given album if not existing
     * @param albumName the album to create if needed
     * @returns album identifier or name
     */
    private async ensureAlbum(albumName = 'DokuPit Fotos'): Promise<string> {
        let mediaRes = await Media.getAlbums();
        let album = mediaRes.albums.find((a) => a.name.toLowerCase().indexOf(albumName.toLowerCase()) != -1);
        if (album) {
            return album.identifier || album.name;
        }

        // check for Sending plugin error: {"save":false,"callbackId":"93112834","pluginId":"Media","methodName":"createAlbum","success":false,"error":{"message":"Album already exists"}}

        await Media.createAlbum({
            name: albumName,
        });
        return this.ensureAlbum(albumName);
    }
}
