import { BaseItem } from '../../../core/db/base-item.model';
import { ReportFilter } from './report.model';
import { Address } from '../../../core/shared-models/address.model';
import { ContactInfo } from '../../../core/shared-models/contact-info.model';
import { Attachment } from '../../../core/attachment/attachment.model';

export class ReportType extends BaseItem {
    name: string;
    reportTitle: string;
    includeJobFieldData: boolean;
    includeLinkedJobData: boolean;
    includePhotos: boolean;
    includeBuildingPlans: boolean;
    includeServiceGroupDocuments: boolean;
    includeArticleDocuments: boolean;
    includeProjectDocuments: boolean;
    showWeather: boolean;
    showSignatures: boolean;
    showDates: ReportDateSetting;
    showDatesForArticlesAndPhotos: ReportDateSetting;
    photoMaxWidth: number;
    photoMaxHeight: number;
    displayPhotoNames: boolean;
    photoCategoryLayout: ReportPhotoCategorySetting;
    showCompanyLogo: boolean;
    titlePageLogoMaxHeight: number;
    showProjectImage: boolean;
    titlePage2Columns: boolean;
    headingBackgroundColor: string;
    headingFontColor: string;
    pageBreakAfterJob: boolean;
    sortJobs: ReportSortJobsSetting;
    sortJobsByDateOrder: ReportSortJobsDateOrderSetting;
    buildingPlanMarkerScaleFactor: number;
    buildingPlanMarkerColor: string;
    buildingPlanMarkerChunkSize: number;
    buildingPlanPrintEmptyPlans: boolean;
    documentedFieldTableWidth: ReportTableWidthSetting;
    identificationCodeMode: ReportIdentificationCodeModeSetting;
    photoDisplayPosition: ReportPhotoDisplayPositionSetting;
    jobNumberPosition: ReportJobNumberPositionSetting;
    filters: Array<ReportFilter>;
    emailReceiversAfterGeneration: Array<{
        email: string;
        removableByNonAdmins: boolean;
    }>;
    allowedLicenseTypes: Array<string>;
    overrideLetterheadCompanyInfo: boolean;
    overrideCompanyDisplayName: string;
    overrideCompanyAddress: Address;
    overrideCompanyContactInfo: ContactInfo;
    overrideCompanyLogo: Attachment;
    reportBuilder: boolean;
    showTitlePage: boolean;
    titlePageHtml: string;
    titlePageCss: string;
    showJobPage: boolean;
    jobPageHtml: string;
    jobPageCss: string;
    showAdditionalPage: boolean;
    additionalPageHtml: string;
    additionalPageCss: string;
    additionalPageMarginLeft: number;
    additionalPageMarginRight: number;
    additionalPageMarginTop: number;
    additionalPageMarginBottom: number;
    additionalPageFooterSettings: ReportTypeFooterSettings;
    additionalPageIncludedAccountingSettings: Array<string>;
    additionalPageOrientation: PageOrientationSetting;
    titlePageMarginLeft: number;
    titlePageMarginRight: number;
    titlePageMarginTop: number;
    titlePageMarginBottom: number;
    titlePageFooterSettings: ReportTypeFooterSettings;
    titlePageIncludedAccountingSettings: Array<string>;
    titlePageOrientation: PageOrientationSetting;
    jobPageMarginLeft: number;
    jobPageMarginRight: number;
    jobPageMarginTop: number;
    jobPageMarginBottom: number;
    jobPageFooterSettings: ReportTypeFooterSettings;
    jobPageIncludedAccountingSettings: Array<string>;
    jobPageOrientation: PageOrientationSetting;
    generationChunkSize: number;
    positionOfLastPagesInReport: PositionLastPagesSetting;
    showLinkIconForFields: boolean;
    colorIconLinkForFields: string;
    additionalJobOverviewFields: Array<string>;
    sendToEasyArchive: boolean;

    constructor(data?: any) {
        super(data, false);

        this.type = 'report-type';
        this.name = '';
        this.reportTitle = '';
        this.includeJobFieldData = true;
        this.includeLinkedJobData = true;
        this.includePhotos = true;
        this.includeBuildingPlans = true;
        this.includeServiceGroupDocuments = true;
        this.includeArticleDocuments = true;
        this.includeProjectDocuments = true;
        this.showWeather = false;
        this.showSignatures = false;
        this.showDates = ReportDateSetting.DateAndTime;
        this.showDatesForArticlesAndPhotos = ReportDateSetting.DateAndTime;
        this.photoMaxWidth = 87;
        this.photoMaxHeight = 48;
        this.displayPhotoNames = true;
        this.photoCategoryLayout = ReportPhotoCategorySetting.Beside;
        this.showCompanyLogo = true;
        this.titlePageLogoMaxHeight = 35;
        this.showProjectImage = false;
        this.titlePage2Columns = false;
        this.headingBackgroundColor = '#2092c9';
        this.headingFontColor = '#000000';
        this.pageBreakAfterJob = true;
        this.sortJobs = ReportSortJobsSetting.ByJobs;
        this.sortJobsByDateOrder = ReportSortJobsDateOrderSetting.Desc;
        this.buildingPlanMarkerScaleFactor = 1;
        this.buildingPlanMarkerColor = '#FF0000';
        this.buildingPlanMarkerChunkSize = 200;
        this.buildingPlanPrintEmptyPlans = false;
        this.documentedFieldTableWidth = ReportTableWidthSetting.FullWidth;
        this.identificationCodeMode = ReportIdentificationCodeModeSetting.Number;
        this.photoDisplayPosition = ReportPhotoDisplayPositionSetting.Below;
        this.jobNumberPosition = ReportJobNumberPositionSetting.Before;
        this.filters = [];
        this.emailReceiversAfterGeneration = [];
        this.allowedLicenseTypes = [];
        this.overrideLetterheadCompanyInfo = false;
        this.overrideCompanyDisplayName = '';
        this.overrideCompanyAddress = new Address();
        this.overrideCompanyContactInfo = new ContactInfo();
        this.overrideCompanyLogo = null;
        this.reportBuilder = false;
        this.showTitlePage = true;
        this.titlePageHtml = null;
        this.titlePageCss = null;
        this.showJobPage = true;
        this.jobPageHtml = null;
        this.jobPageCss = null;
        this.titlePageMarginLeft = 15;
        this.titlePageMarginRight = 15;
        this.titlePageMarginTop = 30;
        this.titlePageMarginBottom = 25;
        this.titlePageFooterSettings = new ReportTypeFooterSettings({ showPageNumber: false });
        this.titlePageOrientation = PageOrientationSetting.Portrait;
        this.jobPageMarginLeft = 15;
        this.jobPageMarginRight = 15;
        this.jobPageMarginTop = 30;
        this.jobPageMarginBottom = 25;
        this.jobPageFooterSettings = new ReportTypeFooterSettings();
        this.jobPageOrientation = PageOrientationSetting.Portrait;
        this.generationChunkSize = null;
        this.titlePageIncludedAccountingSettings = [];
        this.jobPageIncludedAccountingSettings = [];
        this.showAdditionalPage = false;
        this.additionalPageHtml = null;
        this.additionalPageCss = null;
        this.additionalPageMarginLeft = 15;
        this.additionalPageMarginRight = 15;
        this.additionalPageMarginTop = 30;
        this.additionalPageMarginBottom = 25;
        this.additionalPageFooterSettings = new ReportTypeFooterSettings();
        this.additionalPageIncludedAccountingSettings = [];
        this.additionalPageOrientation = PageOrientationSetting.Portrait;
        this.positionOfLastPagesInReport = PositionLastPagesSetting.AfterDocuments;
        this.showLinkIconForFields = true;
        this.colorIconLinkForFields = '#2092c9';
        this.additionalJobOverviewFields = [];
        this.sendToEasyArchive = false;

        if (data != null) {
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.reportTitle != null) {
                this.reportTitle = data.reportTitle;
            }
            if (data.includeJobFieldData != null) {
                this.includeJobFieldData = data.includeJobFieldData;
            }
            if (data.includeLinkedJobData != null) {
                this.includeLinkedJobData = data.includeLinkedJobData;
            }
            if (data.includePhotos != null) {
                this.includePhotos = data.includePhotos;
            }
            if (data.includeBuildingPlans != null) {
                this.includeBuildingPlans = data.includeBuildingPlans;
            }
            if (data.includeServiceGroupDocuments != null) {
                this.includeServiceGroupDocuments = data.includeServiceGroupDocuments;
            }
            if (data.includeArticleDocuments != null) {
                this.includeArticleDocuments = data.includeArticleDocuments;
            }
            if (data.includeProjectDocuments != null) {
                this.includeProjectDocuments = data.includeProjectDocuments;
            }
            if (data.showWeather != null) {
                this.showWeather = data.showWeather;
            }
            if (data.showSignatures != null) {
                this.showSignatures = data.showSignatures;
            }
            if (data.showDates != null) {
                this.showDates = data.showDates;
                this.showDatesForArticlesAndPhotos = data.showDates; // init with value of showDates (as this value came with an update lateron)
            }
            if (data.showDatesForArticlesAndPhotos != null) {
                this.showDatesForArticlesAndPhotos = data.showDatesForArticlesAndPhotos;
            }
            if (data.photoMaxWidth != null) {
                this.photoMaxWidth = data.photoMaxWidth;
                this.photoMaxHeight = Math.floor(data.photoMaxWidth * 0.56);
            }
            if (data.photoMaxHeight != null) {
                this.photoMaxHeight = data.photoMaxHeight;
            }
            if (data.displayPhotoNames != null) {
                this.displayPhotoNames = data.displayPhotoNames;
            }
            if (data.photoCategoryLayout != null) {
                this.photoCategoryLayout = data.photoCategoryLayout;
            }
            if (data.showCompanyLogo != null) {
                this.showCompanyLogo = data.showCompanyLogo;
            }
            if (data.titlePageLogoMaxHeight != null) {
                this.titlePageLogoMaxHeight = data.titlePageLogoMaxHeight;
            }
            if (data.showProjectImage != null) {
                this.showProjectImage = data.showProjectImage;
            }
            if (data.titlePage2Columns != null) {
                this.titlePage2Columns = data.titlePage2Columns;
            }
            if (data.headingBackgroundColor != null) {
                this.headingBackgroundColor = data.headingBackgroundColor;
            }
            if (data.headingFontColor != null) {
                this.headingFontColor = data.headingFontColor;
            }
            if (data.pageBreakAfterJob != null) {
                this.pageBreakAfterJob = data.pageBreakAfterJob;
            }
            if (data.sortJobs != null) {
                this.sortJobs = data.sortJobs;
            }
            if (data.sortJobsByDateOrder != null) {
                this.sortJobsByDateOrder = data.sortJobsByDateOrder;
            }
            if (data.buildingPlanMarkerScaleFactor != null) {
                this.buildingPlanMarkerScaleFactor = data.buildingPlanMarkerScaleFactor;
            }
            if (data.buildingPlanMarkerColor != null) {
                this.buildingPlanMarkerColor = data.buildingPlanMarkerColor;
            }
            if (data.buildingPlanMarkerChunkSize != null) {
                this.buildingPlanMarkerChunkSize = data.buildingPlanMarkerChunkSize;
            }
            if (data.buildingPlanPrintEmptyPlans != null) {
                this.buildingPlanPrintEmptyPlans = data.buildingPlanPrintEmptyPlans;
            }
            if (data.documentedFieldTableWidth != null) {
                this.documentedFieldTableWidth = data.documentedFieldTableWidth;
            }
            if (data.identificationCodeMode != null) {
                this.identificationCodeMode = data.identificationCodeMode;
            }
            if (data.photoDisplayPosition != null) {
                this.photoDisplayPosition = data.photoDisplayPosition;
            }
            if (data.jobNumberPosition != null) {
                this.jobNumberPosition = data.jobNumberPosition;
            }
            if (data.filters != null) {
                this.filters = data.filters.map((x) => new ReportFilter(x));
            }
            if (data.emailReceiversAfterGeneration != null) {
                this.emailReceiversAfterGeneration = data.emailReceiversAfterGeneration;
            }
            if (data.allowedLicenseTypes != null) {
                this.allowedLicenseTypes = data.allowedLicenseTypes;
            }
            if (data.overrideLetterheadCompanyInfo != null) {
                this.overrideLetterheadCompanyInfo = data.overrideLetterheadCompanyInfo;
            }
            if (data.overrideCompanyDisplayName != null) {
                this.overrideCompanyDisplayName = data.overrideCompanyDisplayName;
            }
            if (data.overrideCompanyAddress != null) {
                this.overrideCompanyAddress = new Address(data.overrideCompanyAddress);
            }
            if (data.overrideCompanyContactInfo != null) {
                this.overrideCompanyContactInfo = new ContactInfo(data.overrideCompanyContactInfo);
            }
            if (data.overrideCompanyLogo != null) {
                this.overrideCompanyLogo = new Attachment(data.overrideCompanyLogo);
            }
            if (data.reportBuilder != null) {
                this.reportBuilder = data.reportBuilder;
            }
            if (data.showTitlePage != null) {
                this.showTitlePage = data.showTitlePage;
            }
            if (data.titlePageHtml != null) {
                this.titlePageHtml = data.titlePageHtml;
            }
            if (data.titlePageCss != null) {
                this.titlePageCss = data.titlePageCss;
            }
            if (data.showJobPage != null) {
                this.showJobPage = data.showJobPage;
            }
            if (data.jobPageHtml != null) {
                this.jobPageHtml = data.jobPageHtml;
            }
            if (data.jobPageCss != null) {
                this.jobPageCss = data.jobPageCss;
            }
            if (data.titlePageMarginLeft != null) {
                this.titlePageMarginLeft = data.titlePageMarginLeft;
            }
            if (data.titlePageMarginRight != null) {
                this.titlePageMarginRight = data.titlePageMarginRight;
            }
            if (data.titlePageMarginTop != null) {
                this.titlePageMarginTop = data.titlePageMarginTop;
            }
            if (data.titlePageMarginBottom != null) {
                this.titlePageMarginBottom = data.titlePageMarginBottom;
            }
            if (data.titlePageFooterSettings != null) {
                this.titlePageFooterSettings = data.titlePageFooterSettings;
            }
            if (data.titlePageOrientation != null) {
                this.titlePageOrientation = data.titlePageOrientation;
            }
            if (data.jobPageMarginLeft != null) {
                this.jobPageMarginLeft = data.jobPageMarginLeft;
            }
            if (data.jobPageMarginRight != null) {
                this.jobPageMarginRight = data.jobPageMarginRight;
            }
            if (data.jobPageMarginTop != null) {
                this.jobPageMarginTop = data.jobPageMarginTop;
            }
            if (data.jobPageMarginBottom != null) {
                this.jobPageMarginBottom = data.jobPageMarginBottom;
            }
            if (data.jobPageFooterSettings != null) {
                this.jobPageFooterSettings = data.jobPageFooterSettings;
            }
            if (data.jobPageOrientation != null) {
                this.jobPageOrientation = data.jobPageOrientation;
            }
            if (data.generationChunkSize != null) {
                this.generationChunkSize = data.generationChunkSize;
            }
            if (data.titlePageIncludedAccountingSettings != null) {
                this.titlePageIncludedAccountingSettings = data.titlePageIncludedAccountingSettings;
            }
            if (data.jobPageIncludedAccountingSettings != null) {
                this.jobPageIncludedAccountingSettings = data.jobPageIncludedAccountingSettings;
            }
            if (data.showAdditionalPage != null) {
                this.showAdditionalPage = data.showAdditionalPage;
            }
            if (data.additionalPageHtml != null) {
                this.additionalPageHtml = data.additionalPageHtml;
            }
            if (data.additionalPageCss != null) {
                this.additionalPageCss = data.additionalPageCss;
            }
            if (data.additionalPageMarginLeft != null) {
                this.additionalPageMarginLeft = data.additionalPageMarginLeft;
            }
            if (data.additionalPageMarginRight != null) {
                this.additionalPageMarginRight = data.additionalPageMarginRight;
            }
            if (data.additionalPageMarginTop != null) {
                this.additionalPageMarginTop = data.additionalPageMarginTop;
            }
            if (data.additionalPageMarginBottom != null) {
                this.additionalPageMarginBottom = data.additionalPageMarginBottom;
            }
            if (data.additionalPageFooterSettings != null) {
                this.additionalPageFooterSettings = data.additionalPageFooterSettings;
            }
            if (data.additionalPageIncludedAccountingSettings != null) {
                this.additionalPageIncludedAccountingSettings = data.additionalPageIncludedAccountingSettings;
            }
            if (data.additionalPageOrientation != null) {
                this.additionalPageOrientation = data.additionalPageOrientation;
            }
            if (data.positionOfLastPagesInReport != null) {
                this.positionOfLastPagesInReport = data.positionOfLastPagesInReport;
            }
            if (data.showLinkIconForFields != null) {
                this.showLinkIconForFields = data.showLinkIconForFields;
            }
            if (data.colorIconLinkForFields != null) {
                this.colorIconLinkForFields = data.colorIconLinkForFields;
            }
            if (data.additionalJobOverviewFields != null) {
                this.additionalJobOverviewFields = data.additionalJobOverviewFields;
            }
            if (data.sendToEasyArchive != null) {
                this.sendToEasyArchive = data.sendToEasyArchive;
            }
        }

        // add report-type prefix to pk
        if (this.pk != null && !this.pk.startsWith('report-type#')) {
            this.pk = 'report-type#' + this._id;
            this.sk = 'report-type#' + this._id;
        }
    }
}

export enum ReportDateSetting {
    DateAndTime = 'date-and-time',
    DateOnly = 'date-only',
    Hide = 'hide',
}

export enum ReportPhotoCategorySetting {
    Beside = 'beside',
    Below = 'below',
}

export enum ReportSortJobsSetting {
    ByJobs = 'by-jobs',
    ByDate = 'by-date',
}

export enum ReportTableWidthSetting {
    HalfWidth = 'half-width',
    FullWidth = 'full-width',
}

export enum ReportSortJobsDateOrderSetting {
    Asc = 'asc',
    Desc = 'desc',
}

export enum ReportIdentificationCodeModeSetting {
    Number = 'number',
    Code = 'code',
}

export enum ReportPhotoDisplayPositionSetting {
    Beside = 'beside',
    Below = 'below',
}

export enum ReportJobNumberPositionSetting {
    Before = 'before',
    After = 'after',
}

export class ReportTypeFooterSettings {
    showPageNumber: boolean;
    pageNumberPosition: PageNumberPositionSetting;
    pageNumberPositionX: number;
    pageNumberPositionY: number;

    constructor(data?: any) {
        this.showPageNumber = true;
        this.pageNumberPosition = PageNumberPositionSetting.Right;
        this.pageNumberPositionX = 42;
        this.pageNumberPositionY = 30;

        if (data != null) {
            if (data.showPageNumber != null) {
                this.showPageNumber = data.showPageNumber;
            }
            if (data.pageNumberPosition != null) {
                this.pageNumberPosition = data.pageNumberPosition;
            }
            if (data.pageNumberPositionX != null) {
                this.pageNumberPositionX = data.pageNumberPositionX;
            }
            if (data.pageNumberPositionY != null) {
                this.pageNumberPositionY = data.pageNumberPositionY;
            }
        }
    }
}

export enum PageNumberPositionSetting {
    Left = 'left',
    Center = 'center',
    Right = 'right',
    Own = 'own',
}

export enum PageOrientationSetting {
    Portrait = 'portrait',
    Landscape = 'landscape',
}

export enum PositionLastPagesSetting {
    AfterDocumentation = 'after-documentation',
    AfterBuildingPlans = 'after-building-plans',
    AfterDocuments = 'after-documents',
}
