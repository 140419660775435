import { v4 as uuidv4 } from 'uuid';

export class CalendarEntry {
    _id: string;
    name: string;
    description: string;
    startTimestamp: number;
    endTimestamp: number;
    status: 'pending' | 'done' | string;
    createdAt: number;
    doneAt?: number;
    deletedAt?: number;
    ttl?: number;
    createdBy: string;
    doneBy?: string;
    deletedBy?: string;
    fullDay: boolean;
    creatorCompanyId: string;
    relatedJob?: string;
    relatedJobName?: string;
    relatedJobNumber?: string;
    relatedJobExtension?: string;
    relatedJobExtensionName?: string;
    relatedProject?: string;
    relatedProjectName?: string;
    relatedField?: string;
    relatedFieldName?: string;
    color: string;

    constructor(data?: any) {
        this._id = uuidv4();
        this.name = null;
        this.description = null;
        this.startTimestamp = null;
        this.endTimestamp = null;
        this.status = null;
        this.createdAt = Date.now();
        this.createdBy = null;
        this.fullDay = true;
        this.creatorCompanyId = null;
        this.color = '#2092c9';

        if (data != null) {
            if (data._id != null) {
                this._id = data._id;
            }
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.description != null) {
                this.description = data.description;
            }
            if (data.startTimestamp != null) {
                this.startTimestamp = data.startTimestamp;
            }
            if (data.endTimestamp != null) {
                this.endTimestamp = data.endTimestamp;
            }
            if (data.status != null) {
                this.status = data.status;
            }
            if (data.createdAt != null) {
                this.createdAt = data.createdAt;
            }
            if (data.doneAt != null) {
                this.doneAt = data.doneAt;
            }
            if (data.deletedAt != null) {
                this.deletedAt = data.deletedAt;
            }
            if (data.ttl != null) {
                this.ttl = data.ttl;
            }
            if (data.createdBy != null) {
                this.createdBy = data.createdBy;
            }
            if (data.doneBy != null) {
                this.doneBy = data.doneBy;
            }
            if (data.deletedBy != null) {
                this.deletedBy = data.deletedBy;
            }
            if (data.fullDay != null) {
                this.fullDay = data.fullDay;
            }
            if (data.creatorCompanyId != null) {
                this.creatorCompanyId = data.creatorCompanyId;
            }
            if (data.relatedJob != null) {
                this.relatedJob = data.relatedJob;
            }
            if (data.relatedJobName != null) {
                this.relatedJobName = data.relatedJobName;
            }
            if (data.relatedJobNumber != null) {
                this.relatedJobNumber = data.relatedJobNumber;
            }
            if (data.relatedJobExtension != null) {
                this.relatedJobExtension = data.relatedJobExtension;
            }
            if (data.relatedJobExtensionName != null) {
                this.relatedJobExtensionName = data.relatedJobExtensionName;
            }
            if (data.relatedProject != null) {
                this.relatedProject = data.relatedProject;
            }
            if (data.relatedProjectName != null) {
                this.relatedProjectName = data.relatedProjectName;
            }
            if (data.relatedField != null) {
                this.relatedField = data.relatedField;
            }
            if (data.relatedFieldName != null) {
                this.relatedFieldName = data.relatedFieldName;
            }
            if (data.color != null) {
                this.color = data.color;
            }
        }
    }
}
