export class ContactInfo {
	phone: string;
	email: string;
	web: string;

	constructor(data?: any) {
		this.phone = "";
		this.email = "";
		this.web = "";

		if (data != null) {
			if (data.phone != null) {
				this.phone = data.phone;
			}
			if (data.email != null) {
				this.email = data.email;
			}
			if (data.web != null) {
				this.web = data.web;
			}
		}
	}
}
