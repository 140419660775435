<ion-modal
    #selectDrawerModal
    class="dp-drawer"
    [attr.initial-breakpoint]="utils.isPhone || utils.isTablet ? 0.4 : undefined"
    [breakpoints]="[0, 0.4, 1]"
>
    <ng-template>
        <ion-header>
            <ion-toolbar class="title-bar">
                <ion-buttons slot="start">
                    <ion-button (click)="toggleDrawer()">
                        <ion-icon name="close" slot="icon-only"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title>Auswählen</ion-title>
                <ion-buttons slot="end">
                    <ion-button
                        *ngIf="!multiple && allowEmpty"
                        expand="block"
                        fill="solid"
                        color="primary"
                        [disabled]="!value || $any(value).length == 0 || $any(value)?._id == -1"
                        (click)="onClearSelection()"
                    >
                        <ion-label>Löschen</ion-label>
                    </ion-button>
                    <ion-button
                        *ngIf="multiple"
                        expand="block"
                        fill="solid"
                        color="primary"
                        [disabled]="!value || ($any(value).length == 0 && !allowEmpty)"
                        (click)="onConfirmSelection()"
                    >
                        <ion-label>Bestätigen</ion-label>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-toolbar class="search-toolbar">
                <ion-searchbar
                    placeholder="Suche"
                    debounce="400"
                    (ionInput)="filterData($event.detail.value)"
                    (ionCancel)="resetFilter()"
                    (ionClear)="resetFilter()"
                    (click)="searchbarClicked()"
                    #drawerSearch
                ></ion-searchbar>
            </ion-toolbar>
        </ion-header>

        <ion-content class="drawer-content dp-adaptive-content-wrap">
            <ion-list *ngIf="items?.length > 0; else emptyList" class="dp-item-list">
                <ng-container *ngIf="!multiple; else multiSelectItems">
                    <ion-item
                        *ngFor="let item of displayedData"
                        button
                        detail="false"
                        class="drawer-item"
                        [color]="$any(value)?._id == item._id ? 'primary' : 'none'"
                        (click)="onItemSelected(item)"
                    >
                        <app-field-icon-view
                            [icon]="item.icon"
                            [color]="![null, 'transparent'].includes(item.iconColor) ? item.iconColor : 'inherit'"
                            [lazyLoad]="false"
                            [limitSize]="'26px'"
                            slot="start"
                        ></app-field-icon-view>
                        <ion-label>{{ item.name }}</ion-label></ion-item
                    >

                    <ng-container *ngIf="canAddItems">
                        <ng-container *ngTemplateOutlet="customItemAdding"></ng-container>
                    </ng-container>
                </ng-container>
                <ng-template #multiSelectItems>
                    <ion-item *ngFor="let item of displayedData" class="drawer-item">
                        <ion-checkbox
                            mode="ios"
                            slot="start"
                            [checked]="isItemChecked(item)"
                            (ionChange)="onItemChecked($event.detail.checked, item)"
                        ></ion-checkbox>
                        <app-field-icon-view
                            [icon]="item.icon"
                            [color]="![null, 'transparent'].includes(item.iconColor) ? item.iconColor : 'inherit'"
                            [lazyLoad]="false"
                            [limitSize]="'26px'"
                            slot="start"
                        ></app-field-icon-view>
                        <ion-label>{{ item.name }}</ion-label>
                    </ion-item>

                    <ng-container *ngIf="canAddItems">
                        <ng-container *ngTemplateOutlet="customItemAdding"></ng-container>
                    </ng-container>
                </ng-template>
            </ion-list>
        </ion-content>
    </ng-template>
</ion-modal>

<ng-template #emptyList>
    <div class="ion-text-center empty-message">
        <ion-label>Keine Daten verfügbar</ion-label>
    </div>
</ng-template>

<ng-template #customItemAdding>
    <ion-item button detail="false" class="drawer-item custom-add-item" (click)="onAddCustomItem()">
        <ng-container *ngIf="!addingCustomItem">
            <fa-icon [icon]="['fal', 'layer-plus']" slot="start"></fa-icon>
            <ion-label>Eigener Wert</ion-label>
        </ng-container>
        <ion-input
            *ngIf="addingCustomItem"
            placeholder="Wert eingeben"
            (keyup.enter)="onConfirmCustomItem($event)"
            #customItemInput
        ></ion-input>
        <ion-button
            *ngIf="addingCustomItem"
            slot="end"
            (click)="onConfirmCustomItem($event)"
            [disabled]="!allowEmpty && (!customItemInput || !customItemInput.value || customItemInput.value == '')"
            >OK</ion-button
        >
    </ion-item>
</ng-template>
