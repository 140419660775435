import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    ChangeDetectionStrategy,
    OnInit,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent implements OnInit, OnChanges {
    @Input() colorFormControl: AbstractControl;
    @Input() color = 'transparent';
    @Input() default;
    @Output() changed = new EventEmitter();
    pickerVal: string;

    constructor(private cdRef: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.colorFormControl) {
            this.color = this.colorFormControl.value || 'transparent';
            this.updatePickerValue();
        } else if (this.color) {
            this.updatePickerValue();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.colorFormControl != null && this.colorFormControl != undefined && !changes.colorFormControl.isFirstChange()) {
            this.color = this.colorFormControl.value; // refresh displayed color if form control changes
            this.updatePickerValue();
            this.cdRef.detectChanges();
        }
    }

    /**
     * Triggered when the user changes the color
     * @param change
     */
    onColorChanged(hexColor) {
        if (this.color == hexColor) {
            return;
        }

        console.log('[COLOR PICKER] color changed', hexColor);
        this.color = hexColor;
        this.updatePickerValue();

        if (this.colorFormControl != null && this.colorFormControl != undefined) {
            this.colorFormControl.setValue(hexColor);
        }

        this.changed.emit(hexColor);
    }

    /**
     * Triggered when the user wants to delete the current color
     */
    onDeleteColor(event: any) {
        event.stopPropagation();
        event.preventDefault();

        this.color = this.default ? '' + this.default : 'transparent';
        this.updatePickerValue();

        if (this.colorFormControl != null && this.colorFormControl != undefined) {
            this.colorFormControl.setValue(this.default);
        }

        // either the default color or undefined when none is supplied
        this.changed.emit(this.default);
    }

    private updatePickerValue() {
        if (this.color != this.pickerVal) {
            this.pickerVal = this.color == 'transparent' || !this.color ? '#FFFFFF00' : this.color;
            this.cdRef.detectChanges();
        }
    }
}
