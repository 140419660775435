import { v4 as uuidv4 } from 'uuid';

export class Attachment {
    _id: string; // attachment id
    displayName: string; // attachment display name
    fileName: string; // file name with extension
    filePath: string; // file folder with fileName
    fileMimeType: string; // file mime type
    createdAt: number; // creation timestamp
    updatedAt: number; // update timestamp
    notice: string; // attachment notice

    constructor(data?: any) {
        this._id = uuidv4();
        this.displayName = '';
        this.fileName = '';
        this.filePath = '';
        this.fileMimeType = '';
        this.createdAt = Date.now();
        this.updatedAt = Date.now();
        this.notice = '';

        if (data != null) {
            if (data._id != null) {
                this._id = data._id;
            }
            if (data.displayName != null) {
                this.displayName = data.displayName;
            }
            if (data.fileName != null) {
                this.fileName = data.fileName;
            }
            if (data.filePath != null) {
                this.filePath = data.filePath;
            }
            if (data.fileMimeType != null) {
                this.fileMimeType = data.fileMimeType;
            }
            if (data.createdAt != null) {
                this.createdAt = data.createdAt;
            }
            if (data.notice != null) {
                this.notice = data.notice;
            }
            if (data.updatedAt != null) {
                this.updatedAt = data.updatedAt;
            }
        }
    }

    /**
     * Returns true if the attachment is an image
     */
    isImage() {
        return ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp'].includes(this.fileMimeType);
    }
}
