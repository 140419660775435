import { BaseItem } from '../../../core/db/base-item.model';
import { Article } from '../../../service-groups/articles/article.model';
import { Field } from '../../../service-groups/articles/article-details/field/field.model';
import { Attachment } from '../../../core/attachment/attachment.model';
import { DpNotification } from '../../../core/shared-models/notification.model';
import { v4 as uuidv4 } from 'uuid';
import { CalendarEntry } from '../../../calendar/calendar-entry.model';

export class Job extends BaseItem {
    name: string;
    date: number; // user changable "createdAt"
    lastAutomaticallyAdjustedName: string; // set if dokupit has automatically adjusted the name based on documented articles
    jobNumber: string;
    code: string;
    worker: string;
    externalJobId: string;
    articles: Array<Article>;
    jobExtensions: Array<JobExtensionInfo>;
    fields: Array<Field>;
    photos: Array<JobPhoto>;
    signatures: Array<Attachment>;
    buildingPlanId: string;
    marker: Array<JobMarker>;
    linkedJobIds: Array<string>;
    projectId: string;
    serviceGroupId: string;
    notificationsToCreate: Array<DpNotification>; // list of notifications which will be created in online db on next sync
    calendarEntriesToCreate: Array<CalendarEntry>; // calendar entries which will be created in online db on next sync
    weather: JobWeatherInfo;
    jobStatus: JobStatus;
    legacy: boolean; // true if the job was migrated from v1

    constructor(data?: any) {
        super(data, false);

        this.type = 'job';
        this.name = '';
        this.date = Date.now();
        this.lastAutomaticallyAdjustedName = '';
        this.jobNumber = '';
        this.code = '';
        this.worker = '';
        this.externalJobId = '';
        this.articles = [];
        this.jobExtensions = [];
        this.fields = [];
        this.photos = [];
        this.signatures = [];
        this.buildingPlanId = '';
        this.linkedJobIds = [];
        this.projectId = '';
        this.serviceGroupId = '';
        this.notificationsToCreate = [];
        this.calendarEntriesToCreate = [];
        this.weather = new JobWeatherInfo();
        this.jobStatus = null;
        this.legacy = false;
        this.marker = [];

        if (data != null) {
            if (data.type != null) {
                this.type = data.type;
            }
            if (data.name != null) {
                this.name = data.name;
            }
            if (data.date != null) {
                this.date = data.date;
            } else {
                this.date = this.createdAt;
            }
            if (data.lastAutomaticallyAdjustedName != null) {
                this.lastAutomaticallyAdjustedName = data.lastAutomaticallyAdjustedName;
            }
            if (data.jobNumber != null) {
                this.jobNumber = data.jobNumber;
            }
            if (data.code != null) {
                this.code = data.code;
            }
            if (data.externalJobId != null) {
                this.externalJobId = data.externalJobId;
            }
            if (data.worker != null) {
                this.worker = data.worker;
            }
            if (data.articles != null) {
                this.articles = data.articles.map((x) => new Article(x, true));
            }
            if (data.jobExtensions != null) {
                this.jobExtensions = data.jobExtensions as Array<JobExtensionInfo>;
            }
            if (data.fields != null) {
                this.fields = data.fields.map((x) => new Field(x));
            }
            if (data.photos != null) {
                this.photos = data.photos.map((x) => new JobPhoto(x));
            }
            if (data.signatures != null) {
                this.signatures = data.signatures.map((x) => new Attachment(x));
            }
            if (data.buildingPlanId != null) {
                this.buildingPlanId = data.buildingPlanId;
            }
            if (data.marker != null) {
                if (Array.isArray(data.marker)) {
                    this.marker = data.marker.map((x) => new JobMarker(x));
                } else {
                    if (Object.keys(data.marker).length > 0) {
                        this.marker = [new JobMarker(data.marker)];
                    } else {
                        this.marker = [];
                    }
                }
            }
            if (data.linkedJobIds != null) {
                this.linkedJobIds = data.linkedJobIds;
            }
            if (data.projectId != null) {
                this.projectId = data.projectId;
            }
            if (data.serviceGroupId != null) {
                this.serviceGroupId = data.serviceGroupId;
            }
            if (data.notificationsToCreate != null) {
                this.notificationsToCreate = data.notificationsToCreate.map((x) => new DpNotification(x));
            }
            if (data.calendarEntriesToCreate != null) {
                this.calendarEntriesToCreate = data.calendarEntriesToCreate.map((x) => new CalendarEntry(x));
            }
            if (data.weather != null) {
                this.weather = new JobWeatherInfo(data.weather);
            }
            if (data.jobStatus != null) {
                this.jobStatus = data.jobStatus;
            }
            if (data.legacy != null) {
                this.legacy = data.legacy;
            }
        }

        // add job prefix to pk
        if (this.pk != null && !this.pk.startsWith('job#')) {
            this.pk = 'job#' + this._id;
            this.sk = 'job#' + this._id;
        }
    }
}

export class JobPhoto extends Attachment {
    photoCategoryName: string;

    constructor(data?: any) {
        super(data);

        this.photoCategoryName = '';

        if (data != null) {
            if (data.photoCategoryName != null) {
                this.photoCategoryName = data.photoCategoryName;
            }
        }
    }
}

export class JobWeatherInfo {
    date: number;
    lat: number;
    lng: number;
    weatherInfo: any;

    constructor(data?: any) {
        this.date = null;
        this.lat = null;
        this.lng = null;
        this.weatherInfo = null;

        if (data) {
            if (data.date != null) {
                this.date = data.date;
            }
            if (data.lat != null) {
                this.lat = data.lat;
            }
            if (data.lng != null) {
                this.lng = data.lng;
            }
            if (data.weatherInfo != null) {
                this.weatherInfo = data.weatherInfo;
            }
        }
    }
}

export class JobMarker {
    _id: string; // item id in db
    markerName: string;
    lat: number;
    lng: number;
    icon?: string;
    color?: string;

    constructor(data?: any) {
        this._id = uuidv4();
        this.markerName = null;
        this.lat = undefined;
        this.lng = undefined;
        this.icon = undefined;
        this.color = undefined;

        if (data != null) {
            if (data._id != null) {
                this._id = data._id;
            }
            if (data.markerName != null) {
                this.markerName = data.markerName;
            }
            if (data.lat != null) {
                this.lat = data.lat;
            }
            if (data.lng != null) {
                this.lng = data.lng;
            }
            if (data.icon != null) {
                this.icon = data.icon;
            }
            if (data.color != null) {
                this.color = data.color;
            }
        }
    }
}

export interface JobStatus {
    data: boolean;
    photos: boolean;
    map: boolean;
    code: boolean;
    customIconFields?: Array<JobStatusIcon>;
    buildingPlanCustomIconFields?: Array<JobStatusIcon>;
}

export interface JobStatusIcon {
    value: string;
    icon: {
        iconName: string;
        iconType: string;
    };
    color: string;
    relatedFieldName: string;
    hideMarker: boolean;
    combinedCount: number;
}

export interface JobExtensionInfo {
    _id: string;
    version: number;
}
