import { EventEmitter, Injectable } from '@angular/core';
import { defer, Observable, of } from 'rxjs';
import { BaseItem } from './base-item.model';
import { LoggerService } from '../logger/logger.service';
import { UtilsService } from '../utils/utils.service';
import { ItemChangeInfo } from './item-change-info.interface';
import { Storage } from '@ionic/storage-angular';
import { catchError, map, retry, retryWhen, switchMap } from 'rxjs/operators';
import * as AWS from 'aws-sdk';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';
import { AttachmentService } from '../attachment/attachment.service';
import { NotificationService } from '../notification/notification.service';
import { Platform } from '@ionic/angular';
import { addRxPlugin, createRxDatabase, isRxDatabase, MangoQuery, RxCollection, RxDatabase } from 'rxdb';
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb';
import { getRxStorageSQLite, getSQLiteBasicsCapacitor } from 'rxdb-premium/plugins/storage-sqlite';
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite';
import { Capacitor } from '@capacitor/core';
import dpitemSchema from './rx-dpitem-schema.json';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { environment } from 'src/environments/environment';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';

declare function emit(key: any, value: any); // hack to trick the ts compiler to think the variable is declared (pouchdb injects it when calling the view map function)

@Injectable({
    providedIn: 'root',
})
export class DbService {
    public dynamoDb: DocumentClient;
    public sqlite: SQLiteConnection;
    public rxdb: RxDatabase;
    public dbItems: RxCollection;

    // change event which is submitted when an item changes
    public itemChanged$ = new EventEmitter<ItemChangeInfo>();
    public itemsChanged$ = new EventEmitter<ItemChangeInfo[]>();

    // event emitter for sync service, used to start upstream sync
    public upstreamSyncRequired = new EventEmitter();

    public numberOfItemsToUpstreamSyncAtOnce = 10; // how many items can be upstream synced at one

    private currentUserName = null; // used to save user name to items
    private currentCompanyId = null; // used to save company id to items

    constructor(
        private platform: Platform,
        private storage: Storage,
        private logger: LoggerService,
        private utils: UtilsService,
        private attSrv: AttachmentService,
        private notifySrv: NotificationService
    ) {
        this.initStorage();
    }

    async initStorage() {
        // If using a custom driver:
        // await this.storage.defineDriver(MyCustomDriver)
        await this.storage.create();
    }

    /**
     * Initializes the db service
     */
    public async init(currentUserName: string, currentCompanyId: string) {
        this.currentUserName = currentUserName;
        this.currentCompanyId = currentCompanyId;

        // just making sure we really can access native functionality or at least check for it
        await this.platform.ready();

        // init rxdb
        if (this.rxdb != null && this.dbItems != null) {
            // they should be null on the first init - if they are not the init should not run again (probably some auth error occured which triggered this init)
            this.logger.warn('[DB] db init triggered - will not open rxdb again, as db was already initialized');
        } else {
            this.logger.log('[DB] db init - will now open rxdb');
            await this.openRxDb();
        }

        let lastInitUserName = await this.storage.get('dp_db_last_init_user_name');

        if (lastInitUserName == null) {
            //if there is no last init user name (fresh install or storage cleared on logout) check if the local database is really empty, otherwise we need to reset it

            // check if rxdb is empty
            let rxDbItemsExist = await this.dbItems
                .findOne({
                    selector: {
                        type: {
                            $exists: true,
                        },
                    },
                })
                .exec();

            let rxDbEmpty = !(rxDbItemsExist != null || rxDbItemsExist != undefined);
            if (!rxDbEmpty) {
                // error: the storage tells us that there was no previous logged in user, but the rxdb is not empty -> reset
                this.logger.error(`[DB] last saved init user name is null, but local rxdb is not empty. database reset needed`);
                throw 'reset needed';
            }

            this.logger.log(
                `[DB] looks like a fresh install, last init user name ${lastInitUserName} is ok - new init user name is ${currentUserName}`
            );
            // save for check on next login
            await this.storage.set('dp_db_last_init_user_name', currentUserName);
            await this.storage.set('dp_db_last_init_company_id', currentCompanyId);
        } else if (lastInitUserName == currentUserName) {
            // user ok, check if company also has not changed (if user was moved between companies)
            let lastInitCompanyId = await this.storage.get('dp_db_last_init_company_id');
            if (lastInitCompanyId == null || lastInitCompanyId == currentCompanyId) {
                // ok, handle initialization
                this.logger.log(
                    `[DB] last init user name ${lastInitUserName} is ok - new init user name is ${currentUserName}, company is also ok`
                );
                // save for check on next login
                await this.storage.set('dp_db_last_init_user_name', currentUserName);
                await this.storage.set('dp_db_last_init_company_id', currentCompanyId);

                // init dynamoDB
                this.reInitDynamoDb();
            } else {
                this.logger.error(
                    `[DB] last init user name ${lastInitUserName} is ok, but last init company id ${lastInitCompanyId} is not the same as current init company id ${currentCompanyId}`
                );
                throw 'reset needed';
            }
        } else {
            // the last logged in user name is not the same as the current one, we have to reset the database, before the user can log in
            this.logger.error(
                `[DB] last saved init user name ${lastInitUserName} does not match new logged in user name ${currentUserName}. database reset needed`
            );
            throw 'reset needed';
        }
    }

    /**
     * Opens database connection with rxdb
     * @private
     */
    private async openRxDb() {
        // FIXME: Our schema collides with RxDB's internal properties. When using DevMode plugin it will fail cause we use synced as property, we'd need to refactor a lot of code to replace this property so we can't keep it here for the time being
        // if (environment.debug) {
        //     await import('rxdb/plugins/dev-mode')
        //         .then((module) => addRxPlugin(module.RxDBDevModePlugin))
        //         .catch((rej) => this.logger.error('[DB] DevMode plugin rejected', rej));
        // }

        // create a database
        if (this.utils.isNative) {
            // use sqlite as db-adapter on native devices
            CapacitorSQLite.checkConnectionsConsistency({
                dbNames: [],
                openModes: [],
            }).catch((e) => console.error(e));
            this.sqlite = new SQLiteConnection(CapacitorSQLite);
            this.rxdb = await createRxDatabase({
                name: 'dp-db', // the name of the database
                storage: getRxStorageSQLite({
                    sqliteBasics: getSQLiteBasicsCapacitor(this.sqlite, Capacitor),
                    log: environment.debug ? console.log.bind(console) : null, // This can produce MASSIVE logs (4MB per query) which can be a problem for weak devices therefore only when we do local testing builds
                }),
            });
        } else {
            // use dexie (based on indexdb) as db-adapter for browser
            this.rxdb = await createRxDatabase({
                name: 'dp-db', // the name of the database
                storage: getRxStorageIndexedDB({
                    batchSize: 300,
                }),
            });
        }

        addRxPlugin(RxDBQueryBuilderPlugin);
        addRxPlugin(RxDBMigrationPlugin);

        // add collections
        await this.rxdb.addCollections({
            dpitems: {
                schema: dpitemSchema,
                /*migrationStrategies: {
                    // 1 means, this transforms data from version 0 to version 1
                    1: function (oldDoc) {
                        /*if (oldDoc.sortOrder != null && typeof oldDoc.sortOrder != 'string') {
                            oldDoc.sortOrder = '' + oldDoc.sortOrder;
                        }
                        return oldDoc;
                    },
                }*/
            },
        });

        this.dbItems = this.rxdb.dpitems; // reference to the collection where all db items are stored

        // define preSave/insert hooks (e.g. used for job sorting algorithm)
        this.dbItems.preInsert(this.setupRxDbSortHook, false); // for new documents
        this.dbItems.preSave(this.setupRxDbSortHook, false); // for updated documents
    }

    /**
     * Re-initializes the dynamodb client
     * Needed after the aws credentials are refreshed, so that we use the latest credentials from the aws config
     */
    public reInitDynamoDb() {
        // init dynamoDB
        this.dynamoDb = new AWS.DynamoDB.DocumentClient({
            apiVersion: '2012-08-10',
            convertEmptyValues: true,
            maxRetries: 5,
            correctClockSkew: true,
        });
    }

    /**
     * Sets up sort hooks (called as middleware hooks during dbInit)
     * @param plainData
     * @param rxDocument
     */
    setupRxDbSortHook(plainData, rxDocument) {
        switch (plainData.type) {
            case 'job': {
                let sortNumber = plainData.jobNumber;
                if (isNaN(sortNumber)) {
                    // num array where index 0 is a  digit matchgroup of regex, index 1 a matched nondigit group. see below for why this is done
                    let n = [];
                    // following code splits the number into 2 matches - first match for number digits, second match for non-digits
                    // the replacer function is "abused" to inline fill our number array
                    plainData.jobNumber.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
                        n.push([$1 || undefined, $2 || '']); // the "" for $2 part is IMPORTANT
                        return '';
                    });

                    // after splitting the items we walk all our result groups and build our sort number.
                    // due to normally containing only integer numbers in our indexes as sortNumbers the codes which are full strings or
                    // a mix of numbers and strings we need to somewhat place them "between" normal integers due to the
                    // possibility of a project using mixed and normal numbers.
                    // Therefore we walk our matches and set the starting digit 0 to support strings starting with numbers first.
                    // All parts after that are then converted to ther unicode char value in an case-insensitive manner and concatenated as the digits after the comma or padded to same length in case of number values
                    // This results in a number which is directly aligned with the sorting order of the number size. For this to work we need to pad our nunmbers to the 5 digits of 65535 which is the max value of the charCodeAt function
                    // example: 1-C = 1.0004500067
                    let newNum = undefined;
                    while (n.length) {
                        let part = n.shift();

                        if (newNum == null) {
                            // Start without value before comma for correct sorting of items starting with a number
                            newNum = '0.';
                        }

                        // then either add remaining number parts or convert from unicode value with padding to 5 digits
                        if (part[0]) {
                            // pad the numbers as well for correct sorting otherwise 99 is always "higher" than 100 due to comparison of single digits
                            newNum += String(part[0]).padStart(5, '0');
                        }
                        for (let i = 0; i < part[1].length; i++) {
                            newNum += String(part[1].charAt(i).toLowerCase().charCodeAt(0)).padStart(5, '0');
                        }
                    }
                    /*if (typeof newNum === 'string') {
                        sortNumber = parseFloat(newNum);
                    } else {*/
                    sortNumber = newNum;
                    //}

                    if (isNaN(sortNumber)) {
                        // the job number is still not a number, so just set to fixed value
                        sortNumber = 1;
                    }
                } else {
                    sortNumber = parseFloat(sortNumber);
                }
                plainData.sortOrder = sortNumber; // set sortOrder property on db item
                break;
            }
            default: {
                break;
            }
        }
    }

    public getItemById(_id: string, includeOpenRevs = false) {
        return new Observable<BaseItem>((observer) => {
            this.dbItems
                .findOne(_id)
                .exec()
                .then((res) => {
                    if (res === null || res === undefined) {
                        observer.error('not_found');
                        observer.complete();
                    } else if (res.deleted === true) {
                        // somehow rxdb returns deleted documents until the next pagereload, so we have to check manually
                        observer.error('deleted');
                        observer.complete();
                    } else {
                        observer.next(res.toJSON());
                        observer.complete();
                    }
                })
                .catch((err) => {
                    observer.error(err);
                    observer.complete();
                });
        }).pipe(
            //catchError((e) => this.onIDBStateError(e)),
            retry(1)
        );
    }

    /**
     * Get items by type
     * @param type
     */
    public getItemsByType(type: string) {
        return new Observable((observer) => {
            this.dbItems
                .find({
                    selector: {
                        type: {
                            $eq: type,
                        },
                    },
                })
                .exec()
                .then((res) => {
                    observer.next(res.map((x) => x.toJSON()));
                    observer.complete();
                })
                .catch((err) => {
                    observer.error(err);
                    observer.complete();
                });
        }).pipe(
            //catchError((e) => this.onIDBStateError(e)),
            retry(1)
        );
    }

    /**
     * Get items by relation
     * @param relation
     * @param limit
     */
    public getItemsByRelation(relation: string, limit: number = null) {
        return new Observable((observer) => {
            let query: MangoQuery = {};
            query.selector = {
                relation: {
                    $eq: relation,
                },
            };
            if (limit != null) {
                query.limit = limit;
            }
            this.dbItems
                .find(query)
                .exec()
                .then((res) => {
                    observer.next(res.map((x) => x.toJSON()));
                    observer.complete();
                })
                .catch((err) => {
                    observer.error(err);
                    observer.complete();
                });
        }).pipe(
            //catchError((e) => this.onIDBStateError(e)),
            retry(1)
        );
    }

    /**
     * Get items by relation and type
     * @param relation
     * @param type
     */
    public getItemsByRelationAndType(relation: string, type: string) {
        return new Observable((observer) => {
            this.dbItems
                .find({
                    selector: {
                        $and: [
                            {
                                relation: {
                                    $eq: relation,
                                },
                            },
                            {
                                type: {
                                    $eq: type,
                                },
                            },
                        ],
                    },
                })
                .exec()
                .then((res) => {
                    observer.next(res.map((x) => x.toJSON()));
                    observer.complete();
                })
                .catch((err) => {
                    observer.error(err);
                    observer.complete();
                });
        }).pipe(
            //catchError((e) => this.onIDBStateError(e)),
            retry(1)
        );
    }

    /**
     * Gets items by sync status
     * @param syncStatus
     */
    public getItemsBySyncStatus(syncStatus: boolean) {
        return new Observable((observer) => {
            this.dbItems
                .find({
                    selector: {
                        synced: {
                            $eq: syncStatus,
                        },
                    },
                    limit: this.numberOfItemsToUpstreamSyncAtOnce,
                })
                .exec()
                .then((res) => {
                    observer.next(res.map((x) => x.toJSON()));
                    observer.complete();
                })
                .catch((err) => {
                    observer.error(err);
                    observer.complete();
                });
        }).pipe(
            //catchError((e) => this.onIDBStateError(e)),
            retry(1)
        );
    }

    /**
     * Find items by given FindRequest
     */
    public find(query: MangoQuery) {
        return new Observable((observer) => {
            this.dbItems
                .find(query)
                .exec()
                .then((res) => {
                    observer.next(res.map((x) => x.toJSON()));
                    observer.complete();
                })
                .catch((err) => {
                    observer.error();
                    observer.complete();
                });
        }).pipe(
            //catchError((e) => this.onIDBStateError(e)),
            retry(1)
        );
    }

    /**
     * Puts given item(s) into db
     * @param items
     * @param sync the item will only be synced when the sync property is true, otherwise the item will only exist on the local device
     * @param override always = the item will always be written. if-newer = item will only be written if the version we want to put is higher than the existing item version. if-same-or-newer = item will only be written if the version we want to put is higher or the same as the existing item version
     */
    public put(items: BaseItem | BaseItem[], sync = true, override: 'always' | 'if-same-or-newer' | 'if-newer' = 'always') {
        return defer(async () => {
            if (!Array.isArray(items)) {
                items = [items];
            }

            items = items.map((x) => {
                if (x.createdAt == null) {
                    x.createdAt = Date.now(); // set creation time
                }
                if (x.createdBy == null) {
                    x.createdBy = this.currentUserName;
                }
                if (x.creatorCompanyId == null) {
                    x.creatorCompanyId = this.currentCompanyId; // set creator company id
                }
                x.updatedAt = Date.now(); // set update time
                x.updatedBy = this.currentUserName; // set update username

                if (sync) {
                    if (x.version === -1) {
                        x.version = 1; // if the version is -1 this is the first time the item is put to db, in this case we set the version to 1 which is the first version which will be synced to online db
                    } else {
                        x.version++; // increase the current version number by 1
                    }
                }
                x.synced = !sync; // set synced status to false if the item will be synced
                return x;
            });

            if (override !== 'always') {
                // when override is true we always write the items to the storage, regardless of its version number because we always want to save user input
                // when override is not true we are propably downloading items from online db (downstream) - therefore we need to watch out to not override items in our local db which exist in a higher version, to check this we need to first get the items from our db to compare version numbers
                // this is a performance degrade, but needed to prevent overrides if a downstream sync occurs which would put an item we changed locally but did not upload yet
                try {
                    let itemIds = [];
                    for (let item of items) {
                        if (item._id != null) {
                            itemIds.push(item._id);
                        }
                    }
                    let existingItemsQuery = await this.dbItems.findByIds(itemIds).exec();
                    let existingItems = Array.from(existingItemsQuery.entries()).map((x) => x[1]);

                    items = items.filter((x) => {
                        // now we filter out all items which already exist in db with a higher version number than we want to put
                        let putItemVersion = x.version;
                        let existingItemVersion = 0;
                        let existingItem = existingItems.find((y) => y.pk === x.pk);
                        if (existingItem != null) {
                            existingItemVersion = existingItem.version;
                        }

                        if (override === 'if-newer') {
                            return putItemVersion > existingItemVersion; // we dont want to put items where the version is already in db
                        } else if (override === 'if-same-or-newer') {
                            return putItemVersion >= existingItemVersion; // we dont want to put items where the version is already in db
                        }
                    });
                } catch (syncOverrideCheckError) {
                    this.logger.warn('[DB] error occured while checking existing item version before db put', syncOverrideCheckError);
                    this.utils.sentryCaptureException(syncOverrideCheckError);
                }
            }

            try {
                if (items.length === 0) {
                    return true;
                }
                let putRes = null;
                // when sync is true we always write the items to the storage, regardless of its version number because we always want to save user input
                putRes = await this.dbItems.bulkUpsert(items);
                putRes = putRes.map((x) => x.toJSON());

                this.itemsChanged$.emit(
                    putRes.map((x) => {
                        return {
                            item: x,
                            operation: 'PUT',
                        };
                    })
                );

                if (sync) {
                    this.logger.log('[DB] put ' + putRes.length + ' items (syncable)');
                    this.upstreamSyncRequired.emit();
                } else {
                    this.logger.log('[DB] put ' + putRes.length + ' items (local only)');
                }

                if (putRes.length === 1) {
                    return putRes[0]; // dont return as array if only 1 item was pushed
                } else {
                    return putRes;
                }
            } catch (putErr) {
                this.logger.error('[DB] error occured in db put call', putErr);
                this.utils.sentryCaptureException(putErr);
                throw putErr;
            }
        });
    }

    /**
     * Deletes items with given primaryKeys from db
     * ATTENTION: DONT CALL MULTIPLE TIMES IN PARALLEL (some calls could be overriden because the storage would be written multiple times at the same time)
     * @param itemIds the itemid(s) which will be deleted
     * @param sync the deletion will only be synced when the sync property is true, otherwise the items will only be deleted on the local device
     */
    public delete(itemIds: string | string[], sync = true) {
        return defer(async () => {
            // TODO: fix this try catch if needed
            //try {
            if (!Array.isArray(itemIds)) {
                itemIds = [itemIds];
            }

            // we have to find the items we want to delete in our db first, to filter out items which do not exist in our local db (otherwise the bulkRemove function would fail)
            let findResults = await this.dbItems.findByIds(itemIds).exec();
            let deleteResults = null;

            if (findResults.size > 0) {
                deleteResults = await this.dbItems.bulkRemove(Array.from(findResults.entries()).map((x) => x[0]));
            } else {
                return true;
            }

            if (deleteResults != null && Array.isArray(deleteResults.success)) {
                if (sync) {
                    // get docs to delete from local storage
                    let docsToDelete = await this.storage.get('dp_docs_to_delete');
                    if (docsToDelete == null) {
                        docsToDelete = [];
                    }

                    for (let item of deleteResults.success) {
                        docsToDelete.push(item.pk + '|' + item.sk); // add key of item to our deleted items list so it will be synced
                    }

                    // update docs to delete in local storage
                    await this.storage.set('dp_docs_to_delete', docsToDelete);

                    this.upstreamSyncRequired.emit();
                }

                this.itemsChanged$.emit(
                    deleteResults.success.map((x) => {
                        return {
                            item: x.toJSON(),
                            operation: 'DELETE',
                        };
                    })
                );
            }

            return deleteResults;
            // } catch (delErr) {
            //     throw delErr;
            // }
        });
    }

    /**
     * Clears and destroys the local database
     */
    public async deleteDatabase() {
        try {
            if (this.utils.isNative) {
                this.logger.log('[DB] Directly deleting SQLite db on native device');

                // check if connection is still open
                let capSqliteCon = await CapacitorSQLite.checkConnectionsConsistency({
                    dbNames: ['dp-db'],
                    openModes: ['rw'],
                });
                this.logger.log('[DB] sqlite consistency', capSqliteCon);
                if (!capSqliteCon.result) {
                    // no connection established - create a new one and remove the database with the sqlite plugin directly due to native RxDB crashing the routine without deleting any database
                    this.logger.log('[DB] Connection not available, trying new sqlite connection');
                    await CapacitorSQLite.createConnection({ database: 'dp-db' });
                }

                // connection open, deleting directly
                await CapacitorSQLite.deleteDatabase({ database: 'dp-db' });
                // NOTICE: normally we should still have to call some sort of rxdb destroy or remove but for one it still has the crashing behaviour at this point and second all data is gone by removing the db itself

                // the database is gone by now but to make sure we remove the database folder with things like the j
                return await this.attSrv.cleanFolder('CapacitorDatabase');
            } else {
                // when not being native we don't have to watch for SQLite and can directly interact with RxDB
                this.logger.log('[DB] deleting on non native device');
                return await this.rxdb.remove();
            }
        } catch (delErr) {
            // TODO: prevent complete app lockup at this point and let the user run into a "needs db cleanup" loop instead.
            this.logger.error('[DB] could not remove db', delErr);
            return undefined;
        }
    }
}
