import { Component, HostBinding, Input, NgZone, OnInit } from '@angular/core';
import { DokuPitIcon } from '../../../../assets/icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'app-field-icon-view',
    templateUrl: './field-icon-view.component.html',
    styleUrls: ['./field-icon-view.component.scss'],
})
export class FieldIconViewComponent {
    @Input() icon: DokuPitIcon;
    @Input() lazyLoad = true;
    @Input() rounded = false;
    @Input() color = '#00000';
    @Input() faIconSize: SizeProp = null;

    @HostBinding('style.width') @Input() limitSize: string;

    constructor(private zone: NgZone) {}

    /**
     * Inject svg
     * @param ionImgLoadEvent
     * @param isIonImage
     */
    onImgLoad(ionImgLoadEvent, isIonImage = true) {
        if (isIonImage) {
            let imgShadowRoot = ionImgLoadEvent.target?.shadowRoot;

            if (imgShadowRoot.childElementCount > 0) {
                this.zone.runOutsideAngular(() => {
                    (window as any).SVGInject(imgShadowRoot.children[imgShadowRoot.children.length - 1], {
                        afterInject: (img, svg) => {
                            svg.style.height = '100%';
                            svg.style.color = this.color;
                            svg.style.fill = this.color;
                        },
                    });
                });
            }
        } else {
            // console.warn("shad", ionImgLoadEvent);
            this.zone.runOutsideAngular(() => {
                (window as any).SVGInject(ionImgLoadEvent.target, {
                    afterInject: (img, svg) => {
                        //svg.style.height = "100%";
                        svg.style.color = this.color;
                        svg.style.fill = this.color;
                    },
                });
            });
        }
    }
}
