import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import packageJson from './../package.json';
import { Capacitor } from '@capacitor/core';

// load icon pwa elements before the bootstrap call
defineCustomElements(window);

if (environment.production) {
    enableProdMode();
}

Sentry.init(
    {
        dsn: environment.SENTRY_DSN,
        release: 'app@' + packageJson.version,
        environment: packageJson.isBeta ? 'beta' : environment.production ? 'production' : 'develop',
        debug: environment.SENTRY_DEBUG,
        // this adds web, ios or android to the release identification
        dist: Capacitor.getPlatform(),
        tracePropagationTargets: environment.SENTRY_TRACE_TARGETS,
        integrations: [
            SentryAngular.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        enableWatchdogTerminationTracking: true,
        // replaysSessionSampleRate: 1.0,
        // replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 0.0,
        transport: SentryAngular.makeBrowserOfflineTransport(SentryAngular.makeFetchTransport), // this uses IDB
        // transportOptions: {
        //     maxQueueSize: 30,
        // },
        beforeBreadcrumb(breadcrumb) {
            if (environment.debug === true && breadcrumb.category === 'console') {
                return null;
            }

            return breadcrumb;
            // return null;
        },
        tracesSampleRate: environment.SENTRY_TRACE_SAMPLE_RATE,
        ignoreErrors: [
            // Ignore errors while trying to refetch fallback tiles or requests in tandem with it
            "Cannot read property 'x' of undefined",
            // triggered outside of our code and cant be catched
            'BarcodeScanner does not have web implementation.',
            // always triggered when using browser implementation which does not provide this API and no catch works
            "undefined is not an object (evaluating 'window.screen.orientation.lock')",
            'screen.orientation.lock() is not available on this device.',
            // skip our transparent color value on color picker,
            "Cannot read properties of null (reading 'h')",
            // Leaflet internally while refreshing layers
            'Attempted to load an infinite number of tiles',
            '_leaflet_pos',
            // leaflet: wrong position read on some page loads in mobile
            /not an object.\(.*_leaflet_pos.*\)/i,
            // hard to reproduce but only problems that arise would be not showing markers which is handled elsewhere
            /Invalid LatLng object: \(NaN, NaN\)/i,
            // Ignore AWS stupid config file error
            /.*Missing credentials in config.*if using AWS_CONFIG_FILE.*/i,
            /Could not load credentials from constructor/i,
            // Ignore offline error which is tracked just because
            /Request timed out.*The device maybe offline./i,
        ],
    },
    SentryAngular.init
);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
